import dayjs from "dayjs";

import { TableCell, TableRow } from "@/components/table";
import { TextBad } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import type { Notification } from "@/services/openapi";

export const NotificationTableRow = ({ notification }: { notification: Notification }) => {
  const { t } = useTranslation();

  const { date, description } = notification;

  const dateFormatted = dayjs(date).calendar(undefined, {
    sameDay: `[${t("common.today")}], DD MMMM HH:mm`,
    lastDay: `[${t("common.yesterday")}], DD MMMM HH:mm`,
    lastWeek: "dddd, DD MMMM HH:mm",
    sameElse: "dddd, DD MMMM HH:mm",
  });

  return (
    <>
      <TableRow>
        <TableCell>
          <TextBad className="mb-4 text-[18px] md:text-[22px]" weight="bold">
            {dateFormatted}
          </TextBad>
          <TextBad className="whitespace-pre-wrap text-[14px] md:text-[16px]" family="roboto" lineHeight="3">
            {description}
          </TextBad>
        </TableCell>
      </TableRow>
    </>
  );
};
