import Big from "big.js";
import { produce } from "immer";
import type { FC, ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { logError } from "@/app/libs/sentry";
import { TerminalDealType, type TradingAccount, TradingServerSymbolType } from "@/services/openapi";
import { accountsQueryKeys } from "@/state/server/accounts";

import { useExtendedOrdersContext } from "../contexts/extended-orders.context";
import { useOrdersContext } from "../contexts/orders.context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { calculateMarginWithRate } from "../helpers/formulas";

const calculateSymbolMargin = ({
  orders,
  contractSize,
  leverage,
  instrumentType,
  marginBuyRate,
  marginSellRate,
}: MarginInfoType) => {
  let buyVolume = new Big(0);
  let sellVolume = new Big(0);
  let volumeTotal = new Big(0);

  let averagePriceInfo = { overall: new Big(0), buy: new Big(0), sell: new Big(0) };

  let averageMarginRateInfo = { overall: new Big(0), buy: new Big(0), sell: new Big(0) };

  orders.forEach(({ type, volume, price, marginRate }) => {
    let buyPriceInfo = averagePriceInfo.buy;
    let sellPriceInfo = averagePriceInfo.sell;

    let buyMarginRate = averageMarginRateInfo.buy;
    let sellMarginRate = averageMarginRateInfo.sell;

    if (type === TerminalDealType.Buy) {
      buyVolume = buyVolume.plus(volume);
      buyPriceInfo = buyPriceInfo.plus(new Big(volume).mul(price));
      buyMarginRate = buyMarginRate.plus(new Big(volume).mul(marginRate));
    } else {
      sellVolume = sellVolume.plus(volume);
      sellPriceInfo = sellPriceInfo.plus(new Big(volume).mul(price));
      sellMarginRate = sellMarginRate.plus(new Big(volume).mul(marginRate));
    }
    volumeTotal = volumeTotal.plus(volume);

    averagePriceInfo = {
      overall: averagePriceInfo.overall.plus(new Big(volume).mul(price)),
      buy: buyPriceInfo,
      sell: sellPriceInfo,
    };

    averageMarginRateInfo = {
      overall: averageMarginRateInfo.overall.plus(new Big(volume).mul(marginRate)),
      buy: buyMarginRate,
      sell: sellMarginRate,
    };
  });

  const largerLeg = buyVolume.gt(sellVolume) ? TerminalDealType.Buy : TerminalDealType.Sell;

  const hedgedVolume = largerLeg === TerminalDealType.Buy ? sellVolume : buyVolume;
  const hedgedWeightedAverageOpenPrice = averagePriceInfo.overall.div(volumeTotal);
  const hedgedMarginRatio = new Big(marginBuyRate).plus(marginSellRate).div(2);
  const hedgedWeightedAverageMarginRate = averageMarginRateInfo.overall.div(volumeTotal);

  const nonHedgedVolume = buyVolume.minus(sellVolume).abs();
  const nonHedgedWeightedAverageOpenPrice =
    largerLeg === TerminalDealType.Buy ? averagePriceInfo.buy.div(buyVolume) : averagePriceInfo.sell.div(sellVolume);
  const nonHedgedMarginRatio = largerLeg === TerminalDealType.Buy ? marginBuyRate : marginSellRate;
  const nonHedgedWeightedAverageMarginRate =
    largerLeg === TerminalDealType.Buy
      ? averageMarginRateInfo.buy.div(buyVolume)
      : averageMarginRateInfo.sell.div(sellVolume);

  const hedgedMargin = calculateMarginWithRate({
    contractSize,
    leverage,
    volume: hedgedVolume.toNumber(),
    openPrice: hedgedWeightedAverageOpenPrice.toNumber(),
    instrumentType,
    marginRate: hedgedWeightedAverageMarginRate.toNumber(),
    marginCoeff: hedgedMarginRatio.toNumber(),
  });

  const nonHedgedMargin = calculateMarginWithRate({
    contractSize,
    leverage,
    volume: nonHedgedVolume.toNumber(),
    openPrice: nonHedgedWeightedAverageOpenPrice.toNumber(),
    instrumentType,
    marginRate: nonHedgedWeightedAverageMarginRate.toNumber(),
    marginCoeff: nonHedgedMarginRatio,
  });

  return new Big(hedgedMargin).plus(nonHedgedMargin).toNumber();
};

type MarginInfoOrder = {
  volume: number;
  type: TerminalDealType;
  marginRate: number;
  price: number;
};

type MarginInfoType = {
  contractSize: number;
  leverage: number;
  marginBuyRate: number;
  marginSellRate: number;
  instrumentType: TradingServerSymbolType;
  orders: MarginInfoOrder[];
};

type SummaryDataType = {
  pnl: number;
  swaps: number;
  margin: number;
  marginLevel: number;
};

type ContextProps = {
  equity: number;
  marginFree: number;
  balance: number;
  margin: number;
  credit: number;
  marginLevel: number;
  leverage: number;
  pnl: number;
  swaps: number;
  currency: string;
  currencyDecimalScale: number;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: ReactNode; account: TradingAccount }> = ({ children, account }) => {
  const queryClient = useQueryClient();

  const { equity, currency, marginFree, balance, leverage, credit, id, digits } = account;

  const { extendedOpenOrders } = useExtendedOrdersContext();
  const { ordersIsLoading } = useOrdersContext();
  const { symbols } = useSymbolsContext();

  const [summaryData, setSummaryData] = useState<SummaryDataType>({
    pnl: 0,
    margin: 0,
    marginLevel: 0,
    swaps: 0,
  });

  useEffect(() => {
    if (ordersIsLoading) return;
    try {
      let profitAndLossBig = new Big(0);
      let swapBig = new Big(0);
      let marginBig = new Big(0);

      const list: {
        [keys: string]: MarginInfoType;
      } = {};

      extendedOpenOrders.forEach(order => {
        const {
          pnl,
          swap,
          type,
          volume,
          symbol,
          price,
          marginRate,
          contractSize,
          marginRateMaintenanceMarketBuy,
          marginRateMaintenanceMarketSell,
          instrumentType,
        } = order;

        profitAndLossBig = profitAndLossBig.plus(pnl);

        swapBig = swapBig.plus(swap);

        list[symbol] = {
          contractSize,
          marginBuyRate: marginRateMaintenanceMarketBuy,
          marginSellRate: marginRateMaintenanceMarketSell,
          leverage: leverage!,
          instrumentType,
          orders: [...(list[symbol] ? list[symbol]!.orders : []), { type, volume, price, marginRate }],
        };
      });

      for (const symbol in list) {
        marginBig = marginBig.plus(calculateSymbolMargin(list[symbol]!));
      }

      const pnl = profitAndLossBig.plus(swapBig).toNumber();
      const equityBig = new Big(balance!).plus(credit!).plus(pnl);

      const marginFree = equityBig.minus(marginBig).toNumber();

      setSummaryData({
        pnl,
        margin: marginBig.toNumber(),
        marginLevel: marginBig.toNumber() ? new Big(equityBig).div(marginBig).mul(100).toNumber() : 0,
        swaps: swapBig.toNumber(),
      });
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(id!), oldData => {
        return produce(oldData, draft => {
          draft!.equity = equityBig.toNumber();
          draft!.marginFree = marginFree;
        })!;
      });
    } catch (error) {
      logError(error);
    }
  }, [extendedOpenOrders, symbols, balance, leverage, credit, ordersIsLoading]);

  const value: ContextProps = useMemo(
    () => ({
      balance: balance!,
      margin: summaryData.margin,
      marginLevel: summaryData.marginLevel,
      pnl: summaryData.pnl,
      swaps: summaryData.swaps,
      currency: currency!,
      credit: credit!,
      equity: equity!,
      leverage: leverage!,
      marginFree: marginFree!,
      currencyDecimalScale: digits!,
    }),
    [balance, summaryData, currency, credit, equity, leverage, marginFree, digits],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useTerminalAccountSummary = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalAccountSummary must be used within a TerminalAccountSummaryContextProvider");
  }

  return context;
};

export { Provider as TerminalAccountSummaryContextProvider, useTerminalAccountSummary };
