import { effect, type StopEffect, type WriteSignal } from "@maverick-js/signals";
import dayjs from "dayjs";
import {
  type Bar,
  type DatafeedConfiguration,
  type IBasicDataFeed,
  type ResolutionString,
} from "public/charting_library/charting_library";
import type { LibrarySymbolInfo } from "public/charting_library/datafeed-api";

import { type TerminalEvent } from "@/services/openapi";
import { getSymbolCharts } from "@/services/terminal";

import { getTickSizeFromDecimalScale } from "../../helpers/formatting";
import { $serverTime } from "../../helpers/server-time";
import type { SymbolsListType } from "../../helpers/symbols";
import { getNextBarTime } from "./helpers";
import { formatTimeResolution } from "./resolution";

// https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API

// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map<string, Bar>();

const subscribeChannels = new Map<string, StopEffect>();

const configurationData: DatafeedConfiguration = {
  supported_resolutions: ["1", "5", "15", "30", "60", "240", "1D", "1W", "1M"] as ResolutionString[],
  supports_time: true,
};

type Params = {
  $tick: WriteSignal<TerminalEvent>;
  symbolsList: SymbolsListType;
  tradingServerId: string;
};

const getDatafeed = ({ symbolsList, tradingServerId, $tick }: Params): IBasicDataFeed => ({
  onReady: callback => {
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (_, __, ___, onResult) => {
    onResult([]);
  },
  resolveSymbol: async (symbolName, onResolve, onError) => {
    const symbolItem = symbolsList[symbolName];
    if (!symbolItem) {
      // TODO: add translations
      onError("cannot resolve symbol");
      return;
    }

    const symbolInfo: LibrarySymbolInfo = {
      name: symbolItem.symbol!,
      ticker: symbolItem.symbol!,
      description: symbolItem.description!,
      type: symbolItem.group!,
      session: symbolItem.sessionsTradingView!,
      timezone: "Etc/UTC",
      format: "price",
      minmov: 1,
      minmove2: 0,
      fractional: false,
      pricescale: Math.pow(10, Math.abs(Math.log10(getTickSizeFromDecimalScale(symbolItem.digits!)))),
      has_intraday: true,
      supported_resolutions: configurationData.supported_resolutions,
      has_daily: true,
      has_weekly_and_monthly: true,
      visible_plots_set: "ohlc",
      data_status: "streaming",
      exchange: "",
      listed_exchange: "",
    };

    setTimeout(() => {
      onResolve(symbolInfo);
    }, 0);
  },
  // https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API#getbars
  getBars: async ({ name }, resolution, periodParams, onResult, onError) => {
    const { from, to, firstDataRequest, countBack } = periodParams;
    const timeframe = formatTimeResolution(resolution);
    const startTime = from * 1000;
    const endTime = to * 1000;

    const firstChartDate = symbolsList[name]!.firstChartDate;

    try {
      const noData = dayjs(firstChartDate).isAfter(startTime);

      const response = await getSymbolCharts({
        symbol: name,
        tradingServerId,
        from: new Date(startTime).toISOString(),
        to: firstDataRequest ? undefined : new Date(endTime).toISOString(),
        timeframe,
        countBack,
      });

      const bars: Bar[] = response.items!.map(({ close, date, high, low, open, volume }) => {
        const time = dayjs(date).unix() * 1000;
        return {
          time,
          low: low!,
          high: high!,
          open: open!,
          close: close!,
          volume: volume!,
        };
      });

      if (firstDataRequest && bars.length > 0) {
        lastBarsCache.set(name + resolution, { ...bars.at(-1)! });
      }

      onResult(bars, { noData });
    } catch (error: any) {
      onError("Error loading data");
    }
  },
  subscribeBars: ({ name }, resolution, onTick, listenerGuid) => {
    const stop = effect(() => {
      const data = $tick();
      const lastBar = lastBarsCache.get(name + resolution)!;
      if (data?.dt?.s === name && lastBar) {
        const { t: timestamp, b: bid } = data.dt;
        const timeframe = formatTimeResolution(resolution);
        const nextBarTime = getNextBarTime({
          timeframe,
          lastBarTime: lastBar.time,
        });
        let bar: Bar;
        if (timestamp! >= nextBarTime) {
          bar = {
            time: nextBarTime,
            open: bid!,
            high: bid!,
            low: bid!,
            close: bid!,
          };
        } else {
          bar = {
            ...lastBar,
            high: Math.max(lastBar.high, bid!),
            low: Math.min(lastBar.low, bid!),
            close: bid!,
          };
        }
        lastBarsCache.set(name + resolution, bar);
        // spread is required bc tv mutates the object
        onTick({ ...bar });
      }
    });

    subscribeChannels.set(listenerGuid, stop);
  },
  unsubscribeBars: listenerGuid => {
    subscribeChannels.get(listenerGuid)?.();
    subscribeChannels.delete(listenerGuid);
  },
  getServerTime: onResult => {
    onResult(dayjs($serverTime.get()).unix());
  },
});

export { getDatafeed };
