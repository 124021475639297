import Big from "big.js";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";
import { IconLock } from "@/domains/icons";
import { IconInfo } from "@/domains/icons";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { calculateBonus } from "@/features/payment/deposit/helpers/helpers";
import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import type {
  BonusUserPlatform,
  PaymentMethod,
  RateResult,
  SourceOfFundsLimits,
  TradingAccount,
} from "@/services/openapi";
import { PaymentMethodType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Alert, NewButton, Switch, Text } from "@/shared/ui";

type Props = {
  submitIsloading: boolean;
  currentAmount: number;
  currentPaymentMethod: PaymentMethod;
  currentAccount: TradingAccount;
  feeValue: number;
  bonus: BonusUserPlatform | null;
  rate: RateResult | undefined;
  isBonusAccepted: boolean;
  paymentLink: string | undefined;
  sourceOfFundsLimits: SourceOfFundsLimits[];
  setIsBonusAccepted: (value: boolean) => void;
  onSubmit: () => void;
};

const CheckStep: FC<Props> = ({
  submitIsloading,
  currentPaymentMethod,
  currentAccount,
  currentAmount,
  isBonusAccepted,
  feeValue,
  bonus,
  rate,
  paymentLink,
  sourceOfFundsLimits,
  setIsBonusAccepted,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { currency, digits: decimalScale } = currentAccount;

  const sourceOfFundsLimit = sourceOfFundsLimits?.find(item => item?.currency === currency);

  const isBankTransfer = currentPaymentMethod.type === PaymentMethodType.BankTransfer;
  const image = currentPaymentMethod.images!.mini!;

  const bonusPercent = useMemo(() => {
    if (!bonus || !rate) {
      return 0;
    }

    const value = new Big(currentAmount).div(rate.rate!).toNumber();
    return calculateBonus(value, bonus);
  }, [currentAmount, bonus, rate]);

  const bonusAmount = useMemo(
    () => new Big(bonusPercent).div(100).mul(currentAmount).toNumber(),
    [bonusPercent, currentAmount],
  );

  const totalAmount = useMemo(() => {
    const amount = new Big(currentAmount).minus(feeValue);

    if (bonus && isBonusAccepted) {
      return amount.add(bonusAmount).toNumber();
    }

    return amount.toNumber();
  }, [bonus, bonusAmount, currentAmount, isBonusAccepted, feeValue]);

  const isLimitBeforeDisclaimer =
    sourceOfFundsLimit?.current !== void 0 &&
    sourceOfFundsLimit?.max !== void 0 &&
    sourceOfFundsLimit.current + currentAmount >= sourceOfFundsLimit.max
      ? true
      : false;

  return (
    <>
      <Text variant="S / Regular" color="primary">
        {t("payments.check.title")}
      </Text>

      {isLimitBeforeDisclaimer && sourceOfFundsLimit && (
        <Alert
          icon={<IconInfo />}
          text={
            <Trans
              i18nKey="deposit.form.check-step.disclaimer"
              components={{
                amount: (
                  <NumberFormat value={sourceOfFundsLimit.max} currency={currency!} decimalScale={decimalScale} />
                ),
                underline: <span className="underline" />,
              }}
            />
          }
          className="mt-4"
        />
      )}
      <div className="mt-6 flex flex-col gap-4">
        <div className="rounded-[12px] bg-card-bg px-4 py-2">
          <Table>
            <Table.Row>
              <Table.Label>{t("payments.check.account-id")}</Table.Label>
              <Table.Value>{currentAccount.login}</Table.Value>
            </Table.Row>
            <Table.Row>
              <Table.Label>{t("payments.payment-method")}</Table.Label>
              <Table.Value>
                <div className="flex items-center justify-end gap-1">
                  <img src={image} className="size-4" /> {currentPaymentMethod.title}
                </div>
              </Table.Value>
            </Table.Row>
            <Table.Row>
              <Table.Label>{t("payments.check.amount.deposit")}</Table.Label>
              <Table.Value>
                <NumberFormat value={currentAmount} decimalScale={decimalScale} currency={currency!} />
              </Table.Value>
            </Table.Row>
            {bonus && (
              <Table.Row>
                <Table.Label>
                  <NumberFormat value={bonusPercent} suffix="%" decimalScale={decimalScale} />{" "}
                  {t("payments.check.bonus.deposit")}
                </Table.Label>
                <Table.Value>
                  <div className={cn(!isBonusAccepted && "text-contrast-secondary line-through")}>
                    <PnlFormat value={bonusAmount} decimalScale={decimalScale} currency={currency!} />
                  </div>
                </Table.Value>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Label>{t("payments.check.fee")}</Table.Label>
              <Table.Value>
                <NumberFormat value={feeValue} decimalScale={decimalScale} currency={currency!} />
              </Table.Value>
            </Table.Row>
            <Table.Row>
              <Table.Label accentText>{t("payments.check.total")}</Table.Label>
              <Table.Value accentText>
                <NumberFormat value={totalAmount} decimalScale={decimalScale} currency={currency!} />
              </Table.Value>
            </Table.Row>
          </Table>
        </div>

        {bonus && (
          <div className="rounded-[16px] bg-control-bg px-4 py-3">
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-0.5">
                <Text variant="S / Regular" color="primary">
                  {t("deposit.form.get-bonus")}
                </Text>
                <BonusTooltip
                  bonus={bonus}
                  actionText={t("deposit.bonus.button")}
                  content={t("deposit.bonus.description", {
                    percent: getBonusMainPercent(bonus),
                  })}
                />
              </div>
              <Switch checked={isBonusAccepted} onCheckedChange={setIsBonusAccepted} />
            </div>
          </div>
        )}
      </div>

      <PaymentWrapper.Footer offset="sm">
        <NewButton
          pending={submitIsloading}
          fullWidth
          startSection={isBankTransfer ? <img src={image} alt={currentPaymentMethod.title!} /> : void 0}
          onClick={onSubmit}
        >
          {isBankTransfer ? t("deposit.form.check-step.submit-bank-button") : t("button.go-to-payment")}
        </NewButton>

        {paymentLink && (
          <Text variant="M Compact / Medium" color="secondary" className="mt-6">
            <Trans
              i18nKey={"deposit.form.open-window"}
              components={{
                button: (
                  <button
                    type="button"
                    className="text-contrast-primary underline"
                    onClick={() => window.open(paymentLink, "_blank")}
                  />
                ),
              }}
            />
          </Text>
        )}

        <div className="mt-6 flex flex-col items-center gap-4 p-4 pt-6">
          <div className="grid place-items-center rounded-[12px] bg-contrast-quinary p-2 text-positive-text">
            <IconLock />
          </div>
          <Text align="center" variant="XS / Regular" color="secondary">
            {t("deposit.form.check-step.security")}
          </Text>
        </div>
      </PaymentWrapper.Footer>
    </>
  );
};

export { CheckStep };
