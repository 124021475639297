import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { toggleLiveChat } from "@/app/libs/liveChat";
import { DetailsTable as Table } from "@/components/details-table";
import { Dialog, Text } from "@/shared/ui";

const QuestionBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text as="h3" className="mb-6 text-center" color="primary" variant="XL / Medium">
        {t("withdrawal.how-to-dialog.questions.title")}
      </Text>

      <Table.Card>
        <Text variant="S Compact / Regular" color="primary">
          <Trans
            i18nKey="withdrawal.how-to-dialog.questions.text"
            components={{
              support: <Dialog.Close className="underline" onClick={toggleLiveChat} />,
            }}
          />
        </Text>
      </Table.Card>
    </>
  );
};

export { QuestionBlock };
