import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, NewButton } from "@/shared/ui";
import { WideDialogContent } from "@/shared/ui/dialog";

import Image from "../assets/how-to-dialog.png";
import { InfoBlock } from "./info-block";
import { QuestionBlock } from "./question-block";
import { RulesBlock } from "./rules-block";
import type { WithdrawalCardTableWrapperProps } from "./types";

const HowToDialog: FC<WithdrawalCardTableWrapperProps> = props => {
  const { t } = useTranslation();

  return (
    <WideDialogContent
      title={t("withdrawal.how-to-dialog.title")}
      description={t("withdrawal.how-to-dialog.description")}
      image={Image}
    >
      <RulesBlock />
      <Dialog.Separator />
      <InfoBlock {...props} />
      <Dialog.Separator />
      <QuestionBlock />

      <Dialog.Close asChild>
        <NewButton className="mt-6" fullWidth variant="primary">
          {t("withdrawal.how-to-dialog.button")}
        </NewButton>
      </Dialog.Close>
    </WideDialogContent>
  );
};

export { HowToDialog };
