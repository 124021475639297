// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    web?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    analyticsAction?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityFront {
    Fork = 'Fork',
    TourInterrupted = 'TourInterrupted',
    TourCompleted = 'TourCompleted'
}

/**
 * 
 * @export
 * @interface ActivityRequest
 */
export interface ActivityRequest {
    /**
     * 
     * @type {ActivityFront}
     * @memberof ActivityRequest
     */
    activity?: ActivityFront;
}
/**
 * 
 * @export
 * @interface AddressInfo
 */
export interface AddressInfo {
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressInfo
     */
    isNotUSAResident?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    manuallyPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    phoneCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    phoneCountry?: string | null;
}
/**
 * 
 * @export
 * @interface AppleAuthInfo
 */
export interface AppleAuthInfo {
    /**
     * 
     * @type {string}
     * @memberof AppleAuthInfo
     */
    redirectUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppleAuthInfo
     */
    nonce?: string | null;
}
/**
 * 
 * @export
 * @interface AppleSignInRequest
 */
export interface AppleSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AppleSignInRequest
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppleSignInRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppleSignInRequest
     */
    lastName?: string | null;
}
/**
 * 
 * @export
 * @interface AuthResult
 */
export interface AuthResult {
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    authToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResult
     */
    isFirstLogin?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AvailabilityStatus {
    All = 'All',
    Active = 'Active',
    Archived = 'Archived',
    AvailableToWithdraw = 'AvailableToWithdraw'
}

/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    bannerImgRef?: string | null;
    /**
     * 
     * @type {PlaceHold}
     * @memberof Banner
     */
    placeHold?: PlaceHold;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    clickText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    clickRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    mobileClickRef?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BannerState {
    Default = 'Default',
    Hided = 'Hided'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum BarTimeframe {
    Minute1 = 'Minute1',
    Minute5 = 'Minute5',
    Minute15 = 'Minute15',
    Minute30 = 'Minute30',
    Hour1 = 'Hour1',
    Hour4 = 'Hour4',
    Day1 = 'Day1',
    Week1 = 'Week1',
    Month1 = 'Month1'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum BonusAmountType {
    Fixed = 'Fixed',
    Percent = 'Percent'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum BonusPartialCancellationRuleType {
    Proportional = 'Proportional',
    Percent = 'Percent'
}

/**
 * 
 * @export
 * @interface BonusPlatformAmount
 */
export interface BonusPlatformAmount {
    /**
     * 
     * @type {BonusAmountType}
     * @memberof BonusPlatformAmount
     */
    type?: BonusAmountType;
    /**
     * 
     * @type {number}
     * @memberof BonusPlatformAmount
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof BonusPlatformAmount
     */
    limitMaxCurrent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BonusPlatformAmount
     */
    limitMaxOriginal?: number | null;
}
/**
 * 
 * @export
 * @interface BonusPlatformPartialCancellationRule
 */
export interface BonusPlatformPartialCancellationRule {
    /**
     * 
     * @type {BonusPartialCancellationRuleType}
     * @memberof BonusPlatformPartialCancellationRule
     */
    type?: BonusPartialCancellationRuleType;
    /**
     * 
     * @type {number}
     * @memberof BonusPlatformPartialCancellationRule
     */
    amount?: number | null;
}
/**
 * 
 * @export
 * @interface BonusUserPlatform
 */
export interface BonusUserPlatform {
    /**
     * 
     * @type {string}
     * @memberof BonusUserPlatform
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BonusUserPlatform
     */
    title?: string | null;
    /**
     * 
     * @type {BonusUserStatus}
     * @memberof BonusUserPlatform
     */
    status?: BonusUserStatus;
    /**
     * 
     * @type {string}
     * @memberof BonusUserPlatform
     */
    currency?: string | null;
    /**
     * 
     * @type {BonusUserPlatformOptions}
     * @memberof BonusUserPlatform
     */
    options?: BonusUserPlatformOptions;
    /**
     * 
     * @type {Array<BonusPlatformAmount>}
     * @memberof BonusUserPlatform
     */
    amountSet?: Array<BonusPlatformAmount> | null;
    /**
     * 
     * @type {number}
     * @memberof BonusUserPlatform
     */
    amountMax?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BonusUserPlatform
     */
    allowedTradingServerAccounts?: Array<string> | null;
    /**
     * 
     * @type {BonusPlatformPartialCancellationRule}
     * @memberof BonusUserPlatform
     */
    partialCancellationRule?: BonusPlatformPartialCancellationRule;
}
/**
 * 
 * @export
 * @interface BonusUserPlatformItemsContainer
 */
export interface BonusUserPlatformItemsContainer {
    /**
     * 
     * @type {Array<BonusUserPlatform>}
     * @memberof BonusUserPlatformItemsContainer
     */
    items?: Array<BonusUserPlatform> | null;
    /**
     * 
     * @type {number}
     * @memberof BonusUserPlatformItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface BonusUserPlatformOptions
 */
export interface BonusUserPlatformOptions {
    /**
     * 
     * @type {boolean}
     * @memberof BonusUserPlatformOptions
     */
    canCancel?: boolean;
    /**
     * 
     * @type {BannerState}
     * @memberof BonusUserPlatformOptions
     */
    bannerAccountsState?: BannerState;
    /**
     * 
     * @type {BannerState}
     * @memberof BonusUserPlatformOptions
     */
    bannerDepositState?: BannerState;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BonusUserStatus {
    NotApplied = 'NotApplied',
    InProcess = 'InProcess',
    Completed = 'Completed',
    Canceled = 'Canceled',
    Expired = 'Expired'
}

/**
 * 
 * @export
 * @interface BrokerInfo
 */
export interface BrokerInfo {
    /**
     * 
     * @type {PlatformGeo}
     * @memberof BrokerInfo
     */
    platformGeo?: PlatformGeo;
    /**
     * 
     * @type {string}
     * @memberof BrokerInfo
     */
    platformBrokerName?: string | null;
}
/**
 * 
 * @export
 * @interface CaptchaChallenge
 */
export interface CaptchaChallenge {
    /**
     * 
     * @type {CaptchaType}
     * @memberof CaptchaChallenge
     */
    type?: CaptchaType;
    /**
     * 
     * @type {CaptchaChallengeGoogleRecaptcha}
     * @memberof CaptchaChallenge
     */
    challengeGoogleRecaptchaV3?: CaptchaChallengeGoogleRecaptcha;
    /**
     * 
     * @type {CaptchaChallengeGoogleRecaptcha}
     * @memberof CaptchaChallenge
     */
    challengeGoogleRecaptchaV2?: CaptchaChallengeGoogleRecaptcha;
    /**
     * 
     * @type {CaptchaChallengeGoogleRecaptcha}
     * @memberof CaptchaChallenge
     */
    challengeGoogleRecaptchaV2Android?: CaptchaChallengeGoogleRecaptcha;
    /**
     * 
     * @type {string}
     * @memberof CaptchaChallenge
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface CaptchaChallengeGoogleRecaptcha
 */
export interface CaptchaChallengeGoogleRecaptcha {
    /**
     * 
     * @type {string}
     * @memberof CaptchaChallengeGoogleRecaptcha
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface CaptchaInfo
 */
export interface CaptchaInfo {
    /**
     * 
     * @type {Array<CaptchaType>}
     * @memberof CaptchaInfo
     */
    types?: Array<CaptchaType> | null;
    /**
     * 
     * @type {GoogleReCaptchaInfo}
     * @memberof CaptchaInfo
     */
    googleReCaptchaV3Info?: GoogleReCaptchaInfo;
    /**
     * 
     * @type {GoogleReCaptchaInfo}
     * @memberof CaptchaInfo
     */
    googleReCaptchaV2Info?: GoogleReCaptchaInfo;
    /**
     * 
     * @type {GoogleReCaptchaInfo}
     * @memberof CaptchaInfo
     */
    googleReCaptchaV2AndroidInfo?: GoogleReCaptchaInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaptchaType {
    GoogleReCaptchaV3 = 'GoogleReCaptchaV3',
    GoogleReCaptchaV2 = 'GoogleReCaptchaV2',
    GoogleReCaptchaV2Android = 'GoogleReCaptchaV2Android'
}

/**
 * Возможные платформы, с которых пользователь стучится в наш бекенд
 * @export
 * @enum {string}
 */
export enum ClientPlatform {
    None = 'None',
    Web = 'Web',
    Android = 'Android',
    Ios = 'IOS',
    Mobile = 'Mobile'
}

/**
 * 
 * @export
 * @interface CompanyInfo
 */
export interface CompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    supportEmail?: string | null;
}
/**
 * 
 * @export
 * @interface ConfirmSignUpWithResetPwdRequest
 */
export interface ConfirmSignUpWithResetPwdRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmSignUpWithResetPwdRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmSignUpWithResetPwdRequest
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfirmSignUpWithResetPwdRequest
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface ConfirmUpdateEmailRequest
 */
export interface ConfirmUpdateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmUpdateEmailRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmUpdateEmailRequest
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ConvertMode {
    Convert = 'Convert',
    PreConvert = 'PreConvert'
}

/**
 * 
 * @export
 * @interface CountriesInfo
 */
export interface CountriesInfo {
    /**
     * 
     * @type {string}
     * @memberof CountriesInfo
     */
    current?: string | null;
    /**
     * 
     * @type {Array<PlatformCountry>}
     * @memberof CountriesInfo
     */
    countries?: Array<PlatformCountry> | null;
    /**
     * 
     * @type {Array<PlatformCountry>}
     * @memberof CountriesInfo
     */
    unsupportedCountries?: Array<PlatformCountry> | null;
}
/**
 * 
 * @export
 * @interface CreateTradingAccountRequest
 */
export interface CreateTradingAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountRequest
     */
    tradingServerAccountId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountRequest
     */
    leverage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountRequest
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountRequest
     */
    pwd?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTradingAccountResult
 */
export interface CreateTradingAccountResult {
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    login?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    dateCreate?: string;
    /**
     * 
     * @type {TradingAccountStatus}
     * @memberof CreateTradingAccountResult
     */
    status?: TradingAccountStatus;
    /**
     * 
     * @type {TradingAccountTradeMode}
     * @memberof CreateTradingAccountResult
     */
    tradeMode?: TradingAccountTradeMode;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    leverage?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    maxLeverage?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTradingAccountResult
     */
    leveragesAvailable?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    leverageAvailableDefault?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    digits?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    equity?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    marginFree?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    credit?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    availableToWithdraw?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradingAccountResult
     */
    pnL?: number;
    /**
     * 
     * @type {TradingAccountType}
     * @memberof CreateTradingAccountResult
     */
    type?: TradingAccountType;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    typeTitle?: string | null;
    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof CreateTradingAccountResult
     */
    platform?: TradingServerPlatform;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    platformTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    server?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverMarketName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalWebUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalAndroidUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalIOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalMacOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalLinuxOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    serverTerminalPageUrl?: string | null;
    /**
     * 
     * @type {TradingAccountActions}
     * @memberof CreateTradingAccountResult
     */
    actions?: TradingAccountActions;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    pwd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTradingAccountResult
     */
    pwdInvestor?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

/**
 * 
 * @export
 * @interface DecimalContainer
 */
export interface DecimalContainer {
    /**
     * 
     * @type {number}
     * @memberof DecimalContainer
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface DepositInfo
 */
export interface DepositInfo {
    /**
     * 
     * @type {UiAmountsInfo}
     * @memberof DepositInfo
     */
    uiAmountsInfo?: UiAmountsInfo;
    /**
     * 
     * @type {Array<SourceOfFundsLimits>}
     * @memberof DepositInfo
     */
    sourceOfFundsLimits?: Array<SourceOfFundsLimits> | null;
}
/**
 * 
 * @export
 * @interface EmailRequest
 */
export interface EmailRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailRequest
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRequest
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailRequest
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    property?: string | null;
}
/**
 * 
 * @export
 * @interface ErrorResult
 */
export interface ErrorResult {
    /**
     * 
     * @type {Array<ErrorMessage>}
     * @memberof ErrorResult
     */
    errors?: Array<ErrorMessage> | null;
    /**
     * 
     * @type {number}
     * @memberof ErrorResult
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    codeMsg?: string | null;
}
/**
 * 
 * @export
 * @interface ExternalKycAccessToken
 */
export interface ExternalKycAccessToken {
    /**
     * 
     * @type {string}
     * @memberof ExternalKycAccessToken
     */
    baseAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalKycAccessToken
     */
    accessToken?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalKycStatus {
    NotVerified = 'NotVerified',
    Approved = 'Approved',
    Pending = 'Pending',
    Rejected = 'Rejected',
    DocumentsRequested = 'DocumentsRequested',
    ResubmissionRequested = 'ResubmissionRequested',
    RequiresAction = 'RequiresAction'
}

/**
 * Push notification container
 * @export
 * @interface FcmNotification
 */
export interface FcmNotification {
    /**
     * User id
     * @type {string}
     * @memberof FcmNotification
     */
    userId?: string;
    /**
     * 
     * @type {FcmNotificationContent}
     * @memberof FcmNotification
     */
    content?: FcmNotificationContent;
    /**
     * 
     * @type {FcmNotificationType}
     * @memberof FcmNotification
     */
    type?: FcmNotificationType;
    /**
     * 
     * @type {FcmNotificationShowMode}
     * @memberof FcmNotification
     */
    showMode?: FcmNotificationShowMode;
    /**
     * 
     * @type {FcmNotificationAction}
     * @memberof FcmNotification
     */
    action?: FcmNotificationAction;
    /**
     * UI buttons (optional)
     * @type {Array<FcmNotificationButton>}
     * @memberof FcmNotification
     */
    buttons?: Array<FcmNotificationButton> | null;
    /**
     * Refresh data when push received (optional)
     * @type {Array<FcmNotificationRefreshItem>}
     * @memberof FcmNotification
     */
    refreshItems?: Array<FcmNotificationRefreshItem> | null;
    /**
     * 
     * @type {FcmNotificationLocation}
     * @memberof FcmNotification
     */
    showLocation?: FcmNotificationLocation;
    /**
     * Named push message. Available names: demo-to-real (optional)
     * @type {string}
     * @memberof FcmNotification
     */
    msgName?: string | null;
}
/**
 * Reproduce action when user tap on push message
 * @export
 * @interface FcmNotificationAction
 */
export interface FcmNotificationAction {
    /**
     * Available actions: url, make-deposit, contact-support, terminal-open, terminal-closed, onboarding-sumsub
     * @type {string}
     * @memberof FcmNotificationAction
     */
    action?: string | null;
    /**
     * Redirect url (optional)
     * @type {string}
     * @memberof FcmNotificationAction
     */
    url?: string | null;
    /**
     * Item id (optional)
     * @type {string}
     * @memberof FcmNotificationAction
     */
    id?: string | null;
}
/**
 * UI buttons
 * @export
 * @interface FcmNotificationButton
 */
export interface FcmNotificationButton {
    /**
     * Button text
     * @type {string}
     * @memberof FcmNotificationButton
     */
    text?: string | null;
    /**
     * Available actions: url, make-deposit, contact-support, terminal-open, terminal-closed, onboarding-sumsub
     * @type {string}
     * @memberof FcmNotificationButton
     */
    action?: string | null;
    /**
     * Redirect url (optional)
     * @type {string}
     * @memberof FcmNotificationButton
     */
    url?: string | null;
    /**
     * Item id (optional)
     * @type {string}
     * @memberof FcmNotificationButton
     */
    id?: string | null;
    /**
     * 
     * @type {FcmNotificationColor}
     * @memberof FcmNotificationButton
     */
    color?: FcmNotificationColor;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FcmNotificationColor {
    Default = 'Default',
    Inverted = 'Inverted',
    Success = 'Success',
    Warning = 'Warning'
}

/**
 * Main content
 * @export
 * @interface FcmNotificationContent
 */
export interface FcmNotificationContent {
    /**
     * Notification title
     * @type {string}
     * @memberof FcmNotificationContent
     */
    title?: string | null;
    /**
     * Notification subtitle
     * @type {string}
     * @memberof FcmNotificationContent
     */
    subTitle?: string | null;
    /**
     * Notification text
     * @type {string}
     * @memberof FcmNotificationContent
     */
    text?: string | null;
    /**
     * Available icons: success, fail, waiting
     * @type {string}
     * @memberof FcmNotificationContent
     */
    icon?: string | null;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof FcmNotificationContent
     */
    imagesUrls?: Array<string> | null;
    /**
     * Videos urls
     * @type {Array<string>}
     * @memberof FcmNotificationContent
     */
    videosUrls?: Array<string> | null;
}
/**
 * Show only when user on selected page
 * @export
 * @interface FcmNotificationLocation
 */
export interface FcmNotificationLocation {
    /**
     * Available pages: terminal, trading
     * @type {string}
     * @memberof FcmNotificationLocation
     */
    pageName?: string | null;
}
/**
 * Refresh data when push received
 * @export
 * @interface FcmNotificationRefreshItem
 */
export interface FcmNotificationRefreshItem {
    /**
     * Available names: accounts
     * @type {string}
     * @memberof FcmNotificationRefreshItem
     */
    name?: string | null;
    /**
     * Item id (optional)
     * @type {string}
     * @memberof FcmNotificationRefreshItem
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FcmNotificationShowMode {
    Default = 'Default',
    PopUp = 'PopUp'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FcmNotificationType {
    Default = 'Default'
}

/**
 * 
 * @export
 * @interface FcmToken
 */
export interface FcmToken {
    /**
     * 
     * @type {string}
     * @memberof FcmToken
     */
    token?: string | null;
    /**
     * 
     * @type {FcmTokenType}
     * @memberof FcmToken
     */
    type?: FcmTokenType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FcmTokenType {
    Mobile = 'Mobile',
    Web = 'Web'
}

/**
 * 
 * @export
 * @interface FeatureTours
 */
export interface FeatureTours {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureTours
     */
    forkCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureTours
     */
    shouldShowFeatureTourFork?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureTours
     */
    shouldShowFeatureTour?: boolean;
}
/**
 * 
 * @export
 * @interface FeaturesInfo
 */
export interface FeaturesInfo {
    /**
     * 
     * @type {boolean}
     * @memberof FeaturesInfo
     */
    mandatorySurvey?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeaturesInfo
     */
    depositScreenNoKycAmountLimit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeaturesInfo
     */
    withdrawScreenNoKycAmountLimit?: boolean;
}
/**
 * 
 * @export
 * @interface FeeResponse
 */
export interface FeeResponse {
    /**
     * 
     * @type {number}
     * @memberof FeeResponse
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    title?: string | null;
    /**
     * 
     * @type {Validation}
     * @memberof Field
     */
    validation?: Validation;
    /**
     * 
     * @type {Array<Option>}
     * @memberof Field
     */
    options?: Array<Option> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldCondition {
    None = 'None',
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    More = 'More',
    Less = 'Less'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    Unknown = 'Unknown',
    String = 'String',
    Decimal = 'Decimal',
    Int = 'Int',
    Date = 'Date',
    File = 'File',
    Boolean = 'Boolean',
    PollSingle = 'PollSingle',
    PollMulti = 'PollMulti',
    CountryList = 'CountryList',
    PhoneNumber = 'PhoneNumber',
    Declaration = 'Declaration'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldView {
    Unknown = 'Unknown',
    Select = 'Select',
    Radio = 'Radio',
    Checkbox = 'Checkbox'
}

/**
 * 
 * @export
 * @interface FundsPreTransferRequest
 */
export interface FundsPreTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsPreTransferRequest
     */
    sourceCurrency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsPreTransferRequest
     */
    destinationCurrency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FundsPreTransferRequest
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface FundsPreTransferResult
 */
export interface FundsPreTransferResult {
    /**
     * 
     * @type {number}
     * @memberof FundsPreTransferResult
     */
    amount?: number;
    /**
     * 
     * @type {ConvertMode}
     * @memberof FundsPreTransferResult
     */
    mode?: ConvertMode;
    /**
     * 
     * @type {string}
     * @memberof FundsPreTransferResult
     */
    validTo?: string | null;
}
/**
 * 
 * @export
 * @interface FundsTransferRequest
 */
export interface FundsTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsTransferRequest
     */
    sourceId?: string;
    /**
     * 
     * @type {FundsTransferType}
     * @memberof FundsTransferRequest
     */
    sourceType?: FundsTransferType;
    /**
     * 
     * @type {string}
     * @memberof FundsTransferRequest
     */
    destinationId?: string;
    /**
     * 
     * @type {FundsTransferType}
     * @memberof FundsTransferRequest
     */
    destinationType?: FundsTransferType;
    /**
     * 
     * @type {number}
     * @memberof FundsTransferRequest
     */
    amount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FundsTransferRequest
     */
    transferAll?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundsTransferType {
    Undefined = 'Undefined',
    TradingAccount = 'TradingAccount',
    Card = 'Card'
}

/**
 * 
 * @export
 * @interface FundsTx
 */
export interface FundsTx {
    /**
     * 
     * @type {string}
     * @memberof FundsTx
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FundsTx
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof FundsTx
     */
    number?: number;
    /**
     * 
     * @type {FundsTxAmounts}
     * @memberof FundsTx
     */
    amounts?: FundsTxAmounts;
    /**
     * 
     * @type {string}
     * @memberof FundsTx
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsTx
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsTx
     */
    typeTitle?: string | null;
    /**
     * 
     * @type {Array<FundsTxDetailItem>}
     * @memberof FundsTx
     */
    details?: Array<FundsTxDetailItem> | null;
    /**
     * 
     * @type {FundsTxDetailStatus}
     * @memberof FundsTx
     */
    status?: FundsTxDetailStatus;
    /**
     * 
     * @type {FundsTxActions}
     * @memberof FundsTx
     */
    actions?: FundsTxActions;
}
/**
 * 
 * @export
 * @interface FundsTxActions
 */
export interface FundsTxActions {
    /**
     * 
     * @type {boolean}
     * @memberof FundsTxActions
     */
    canResendConfirmation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FundsTxActions
     */
    canCancel?: boolean;
}
/**
 * 
 * @export
 * @interface FundsTxAmount
 */
export interface FundsTxAmount {
    /**
     * 
     * @type {number}
     * @memberof FundsTxAmount
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof FundsTxAmount
     */
    currency?: string | null;
    /**
     * 
     * @type {FundsTxColor}
     * @memberof FundsTxAmount
     */
    color?: FundsTxColor;
}
/**
 * 
 * @export
 * @interface FundsTxAmounts
 */
export interface FundsTxAmounts {
    /**
     * 
     * @type {FundsTxAmount}
     * @memberof FundsTxAmounts
     */
    first?: FundsTxAmount;
    /**
     * 
     * @type {FundsTxAmount}
     * @memberof FundsTxAmounts
     */
    second?: FundsTxAmount;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundsTxColor {
    Default = 'Default',
    Inverted = 'Inverted',
    Red = 'Red',
    Green = 'Green',
    Orange = 'Orange'
}

/**
 * 
 * @export
 * @interface FundsTxDetailItem
 */
export interface FundsTxDetailItem {
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailItem
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailItem
     */
    description?: string | null;
    /**
     * 
     * @type {FundsTxAmount}
     * @memberof FundsTxDetailItem
     */
    amount?: FundsTxAmount;
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailItem
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailItem
     */
    logoUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FundsTxDetailItem
     */
    canCopy?: boolean;
}
/**
 * 
 * @export
 * @interface FundsTxDetailStatus
 */
export interface FundsTxDetailStatus {
    /**
     * 
     * @type {FundsTxStatus}
     * @memberof FundsTxDetailStatus
     */
    status?: FundsTxStatus;
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailStatus
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundsTxDetailStatus
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface FundsTxItemsContainer
 */
export interface FundsTxItemsContainer {
    /**
     * 
     * @type {Array<FundsTx>}
     * @memberof FundsTxItemsContainer
     */
    items?: Array<FundsTx> | null;
    /**
     * 
     * @type {number}
     * @memberof FundsTxItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundsTxStatus {
    Finished = 'Finished',
    Processing = 'Processing',
    Rejected = 'Rejected',
    Refunded = 'Refunded',
    Error = 'Error'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FundsTxType {
    All = 'All',
    Converting = 'Converting',
    TradingAccount = 'TradingAccount',
    Deposit = 'Deposit',
    Withdraw = 'Withdraw',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface GetDefaultResult
 */
export interface GetDefaultResult {
    /**
     * 
     * @type {string}
     * @memberof GetDefaultResult
     */
    accountId?: string | null;
}
/**
 * 
 * @export
 * @interface GoogleReCaptchaInfo
 */
export interface GoogleReCaptchaInfo {
    /**
     * 
     * @type {string}
     * @memberof GoogleReCaptchaInfo
     */
    key?: string | null;
}
/**
 * 
 * @export
 * @interface GroupKeyValue
 */
export interface GroupKeyValue {
    /**
     * 
     * @type {string}
     * @memberof GroupKeyValue
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupKeyValue
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IconType {
    Warning = 'Warning'
}

/**
 * 
 * @export
 * @interface IntegerContainer
 */
export interface IntegerContainer {
    /**
     * 
     * @type {number}
     * @memberof IntegerContainer
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface InterfaceItem
 */
export interface InterfaceItem {
    /**
     * 
     * @type {string}
     * @memberof InterfaceItem
     */
    type?: string | null;
    /**
     * 
     * @type {Action}
     * @memberof InterfaceItem
     */
    action?: Action;
    /**
     * 
     * @type {Array<any>}
     * @memberof InterfaceItem
     */
    children?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface KycInfo
 */
export interface KycInfo {
    /**
     * 
     * @type {ProfileInfo}
     * @memberof KycInfo
     */
    profile?: ProfileInfo;
    /**
     * 
     * @type {AddressInfo}
     * @memberof KycInfo
     */
    address?: AddressInfo;
    /**
     * 
     * @type {SurveyInfo}
     * @memberof KycInfo
     */
    survey?: SurveyInfo;
    /**
     * 
     * @type {SumSubInfo}
     * @memberof KycInfo
     */
    sumSub?: SumSubInfo;
}
/**
 * 
 * @export
 * @interface LegalDocumentItem
 */
export interface LegalDocumentItem {
    /**
     * 
     * @type {string}
     * @memberof LegalDocumentItem
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalDocumentItem
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface LegalDocumentItemItemsContainer
 */
export interface LegalDocumentItemItemsContainer {
    /**
     * 
     * @type {Array<LegalDocumentItem>}
     * @memberof LegalDocumentItemItemsContainer
     */
    items?: Array<LegalDocumentItem> | null;
    /**
     * 
     * @type {number}
     * @memberof LegalDocumentItemItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface LevelName
 */
export interface LevelName {
    /**
     * 
     * @type {string}
     * @memberof LevelName
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LevelName
     */
    sumSubName?: string | null;
}
/**
 * 
 * @export
 * @interface LimitAndWithdrawTimeItem
 */
export interface LimitAndWithdrawTimeItem {
    /**
     * 
     * @type {Array<LimitItem>}
     * @memberof LimitAndWithdrawTimeItem
     */
    limits?: Array<LimitItem> | null;
    /**
     * 
     * @type {PaymentProcessingTime}
     * @memberof LimitAndWithdrawTimeItem
     */
    processingTime?: PaymentProcessingTime;
    /**
     * 
     * @type {string}
     * @memberof LimitAndWithdrawTimeItem
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface LimitExtendedInfo
 */
export interface LimitExtendedInfo {
    /**
     * 
     * @type {number}
     * @memberof LimitExtendedInfo
     */
    withdrawMaximumAmountNoKyc?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitExtendedInfo
     */
    depositMaximumAmountNoKyc?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitExtendedInfo
     */
    withdrawCurrentLimitNoKyc?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitExtendedInfo
     */
    depositCurrentLimitNoKyc?: number;
}
/**
 * 
 * @export
 * @interface LimitItem
 */
export interface LimitItem {
    /**
     * 
     * @type {number}
     * @memberof LimitItem
     */
    minWithdraw?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LimitItem
     */
    maxWithdraw?: number | null;
    /**
     * 
     * @type {PaymentFee}
     * @memberof LimitItem
     */
    fee?: PaymentFee;
    /**
     * 
     * @type {string}
     * @memberof LimitItem
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @interface LimitsAndWithdrawTime
 */
export interface LimitsAndWithdrawTime {
    /**
     * 
     * @type {LimitAndWithdrawTimeItem}
     * @memberof LimitsAndWithdrawTime
     */
    bankCard?: LimitAndWithdrawTimeItem;
    /**
     * 
     * @type {LimitAndWithdrawTimeItem}
     * @memberof LimitsAndWithdrawTime
     */
    crypto?: LimitAndWithdrawTimeItem;
    /**
     * 
     * @type {LimitAndWithdrawTimeItem}
     * @memberof LimitsAndWithdrawTime
     */
    other?: LimitAndWithdrawTimeItem;
}
/**
 * 
 * @export
 * @interface LiveChatInfo
 */
export interface LiveChatInfo {
    /**
     * 
     * @type {Array<GroupKeyValue>}
     * @memberof LiveChatInfo
     */
    groupsIds?: Array<GroupKeyValue> | null;
}
/**
 * 
 * @export
 * @interface LocaleInfo
 */
export interface LocaleInfo {
    /**
     * 
     * @type {string}
     * @memberof LocaleInfo
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocaleInfo
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocaleInfo
     */
    icon?: string | null;
}
/**
 * 
 * @export
 * @interface LocaleInfoItemsContainer
 */
export interface LocaleInfoItemsContainer {
    /**
     * 
     * @type {Array<LocaleInfo>}
     * @memberof LocaleInfoItemsContainer
     */
    items?: Array<LocaleInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof LocaleInfoItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface LogInError
 */
export interface LogInError {
    /**
     * 
     * @type {string}
     * @memberof LogInError
     */
    captchaToken?: string | null;
}
/**
 * 
 * @export
 * @interface LogInErrorErrorDataResult
 */
export interface LogInErrorErrorDataResult {
    /**
     * 
     * @type {Array<ErrorMessage>}
     * @memberof LogInErrorErrorDataResult
     */
    errors?: Array<ErrorMessage> | null;
    /**
     * 
     * @type {number}
     * @memberof LogInErrorErrorDataResult
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof LogInErrorErrorDataResult
     */
    codeMsg?: string | null;
    /**
     * 
     * @type {LogInError}
     * @memberof LogInErrorErrorDataResult
     */
    data?: LogInError;
}
/**
 * 
 * @export
 * @interface LogInRequest
 */
export interface LogInRequest {
    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LogInRequest
     */
    rememberMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    twoFactorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    recoveryCode?: string | null;
    /**
     * 
     * @type {CaptchaChallenge}
     * @memberof LogInRequest
     */
    captcha?: CaptchaChallenge;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MT5PositionAction {
    Buy = 'Buy',
    Sell = 'Sell'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MT5PositionActivation {
    None = 'None',
    StopLoss = 'StopLoss',
    TakeProfit = 'TakeProfit',
    StopOut = 'StopOut'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MT5PositionReason {
    Client = 'Client',
    Expert = 'Expert',
    Dealer = 'Dealer',
    StopLoss = 'StopLoss',
    TakeProfit = 'TakeProfit',
    StopOut = 'StopOut',
    RollOver = 'RollOver',
    ExternalClient = 'ExternalClient',
    VMargin = 'VMargin',
    Gateway = 'Gateway',
    Signal = 'Signal',
    Settlement = 'Settlement',
    Transfer = 'Transfer',
    Sync = 'Sync',
    ExternalService = 'ExternalService',
    Migration = 'Migration',
    Mobile = 'Mobile',
    Web = 'Web',
    Split = 'Split',
    CorporateAction = 'CorporateAction'
}

/**
 * 
 * @export
 * @interface MarketingPaymentMethod
 */
export interface MarketingPaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof MarketingPaymentMethod
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingPaymentMethod
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface MaximumLimitDescription
 */
export interface MaximumLimitDescription {
    /**
     * 
     * @type {number}
     * @memberof MaximumLimitDescription
     */
    safeToWithdrawAmount?: number | null;
    /**
     * How much you can withdraw and why
     * @type {string}
     * @memberof MaximumLimitDescription
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MaximumLimitDescription
     */
    maxPaymentMethodAmount?: number;
    /**
     * 
     * @type {LimitExtendedInfo}
     * @memberof MaximumLimitDescription
     */
    extendedInfo?: LimitExtendedInfo;
}
/**
 * 
 * @export
 * @interface MetaTrader4Order
 */
export interface MetaTrader4Order {
    /**
     * 
     * @type {string}
     * @memberof MetaTrader4Order
     */
    tradingAccountId?: string;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    login?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader4Order
     */
    symbol?: string | null;
    /**
     * 
     * @type {TradingAccountMT4OrderType}
     * @memberof MetaTrader4Order
     */
    type?: TradingAccountMT4OrderType;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    volumeBaseClose?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    volumeUSDClose?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    volumeBaseOpen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    volumeUSDOpen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    rateCurrencyAccountToSymbolQuoteOpen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    rateCurrencyAccountToUsdOpen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    rateCurrencyAccountToSymbolQuoteClose?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    rateCurrencyAccountToUsdClose?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader4Order
     */
    dateOpen?: string;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    priceOpen?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader4Order
     */
    dateClose?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    priceClose?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    commission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    swap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    taxes?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    profit?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader4Order
     */
    comment?: string | null;
    /**
     * 
     * @type {TradingAccountMT4OrderReason}
     * @memberof MetaTrader4Order
     */
    reason?: TradingAccountMT4OrderReason;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    takeProfit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    accountBalance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    accountEquity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    accountCredit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4Order
     */
    ownShare?: number;
}
/**
 * 
 * @export
 * @interface MetaTrader4OrderItemsContainer
 */
export interface MetaTrader4OrderItemsContainer {
    /**
     * 
     * @type {Array<MetaTrader4Order>}
     * @memberof MetaTrader4OrderItemsContainer
     */
    items?: Array<MetaTrader4Order> | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader4OrderItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaTrader4OrderSorting {
    ByDateOpenDesc = 'ByDateOpenDesc',
    ByDateOpenAsc = 'ByDateOpenAsc',
    ByDateCloseDesc = 'ByDateCloseDesc',
    ByDateCloseAsc = 'ByDateCloseAsc',
    ByTypeDesc = 'ByTypeDesc',
    ByTypeAsc = 'ByTypeAsc',
    BySymbolDesc = 'BySymbolDesc',
    BySymbolAsc = 'BySymbolAsc',
    ByProfitDesc = 'ByProfitDesc',
    ByProfitAsc = 'ByProfitAsc',
    ByVolumeDesc = 'ByVolumeDesc',
    ByVolumeAsc = 'ByVolumeAsc'
}

/**
 * 
 * @export
 * @interface MetaTrader5Deal
 */
export interface MetaTrader5Deal {
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Deal
     */
    tradingAccountId?: string;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    login?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    deal?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Deal
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Deal
     */
    date?: string;
    /**
     * 
     * @type {TradingAccountMT5DealAction}
     * @memberof MetaTrader5Deal
     */
    action?: TradingAccountMT5DealAction;
    /**
     * 
     * @type {TradingAccountMT5DealEntry}
     * @memberof MetaTrader5Deal
     */
    entry?: TradingAccountMT5DealEntry;
    /**
     * 
     * @type {TradingAccountMT5DealInitiator}
     * @memberof MetaTrader5Deal
     */
    initiator?: TradingAccountMT5DealInitiator;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    commission?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    swap?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    volumeBase?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    volumeUSD?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    rateCurrencyAccountToSymbolQuote?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    rateCurrencyAccountToUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    volumeOpenOriginal?: number | null;
    /**
     * 
     * @type {TradingAccountMT5DealSignalType}
     * @memberof MetaTrader5Deal
     */
    signalType?: TradingAccountMT5DealSignalType;
    /**
     * 
     * @type {TradingAccountMT5DealVolumeOpenOriginalType}
     * @memberof MetaTrader5Deal
     */
    volumeOpenOriginalType?: TradingAccountMT5DealVolumeOpenOriginalType;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Deal
     */
    placeOrderIp?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    contractSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    takeProfit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Deal
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    accountBalance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    accountEquity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    accountCredit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Deal
     */
    ownShare?: number;
}
/**
 * 
 * @export
 * @interface MetaTrader5DealItemsContainer
 */
export interface MetaTrader5DealItemsContainer {
    /**
     * 
     * @type {Array<MetaTrader5Deal>}
     * @memberof MetaTrader5DealItemsContainer
     */
    items?: Array<MetaTrader5Deal> | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5DealItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaTrader5DealSorting {
    ByDateDesc = 'ByDateDesc',
    ByDateAsc = 'ByDateAsc',
    ByActionDesc = 'ByActionDesc',
    ByActionAsc = 'ByActionAsc',
    ByEntryDesc = 'ByEntryDesc',
    ByEntryAsc = 'ByEntryAsc',
    BySymbolDesc = 'BySymbolDesc',
    BySymbolAsc = 'BySymbolAsc',
    ByProfitDesc = 'ByProfitDesc',
    ByProfitAsc = 'ByProfitAsc',
    ByVolumeDesc = 'ByVolumeDesc',
    ByVolumeAsc = 'ByVolumeAsc'
}

/**
 * 
 * @export
 * @interface MetaTrader5Position
 */
export interface MetaTrader5Position {
    /**
     * 
     * @type {MT5PositionAction}
     * @memberof MetaTrader5Position
     */
    action?: MT5PositionAction;
    /**
     * 
     * @type {MT5PositionActivation}
     * @memberof MetaTrader5Position
     */
    activation?: MT5PositionActivation;
    /**
     * 
     * @type {MT5PositionReason}
     * @memberof MetaTrader5Position
     */
    reason?: MT5PositionReason;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    login?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Position
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    swap?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Position
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    contractSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    priceCurrent?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    priceOpen?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTrader5Position
     */
    dateCreate?: string;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5Position
     */
    takeProfit?: number | null;
}
/**
 * 
 * @export
 * @interface MetaTrader5PositionItemsContainer
 */
export interface MetaTrader5PositionItemsContainer {
    /**
     * 
     * @type {Array<MetaTrader5Position>}
     * @memberof MetaTrader5PositionItemsContainer
     */
    items?: Array<MetaTrader5Position> | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTrader5PositionItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaTrader5PositionSorting {
    ByDateDesc = 'ByDateDesc',
    ByDateAsc = 'ByDateAsc',
    ByActionDesc = 'ByActionDesc',
    ByActionAsc = 'ByActionAsc',
    BySymbolDesc = 'BySymbolDesc',
    BySymbolAsc = 'BySymbolAsc',
    ByProfitDesc = 'ByProfitDesc',
    ByProfitAsc = 'ByProfitAsc',
    ByVolumeDesc = 'ByVolumeDesc',
    ByVolumeAsc = 'ByVolumeAsc'
}

/**
 * 
 * @export
 * @interface MetaTraderBalanceOperation
 */
export interface MetaTraderBalanceOperation {
    /**
     * 
     * @type {number}
     * @memberof MetaTraderBalanceOperation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTraderBalanceOperation
     */
    date?: string;
    /**
     * 
     * @type {TradingAccountMT5DealAction}
     * @memberof MetaTraderBalanceOperation
     */
    action?: TradingAccountMT5DealAction;
    /**
     * 
     * @type {number}
     * @memberof MetaTraderBalanceOperation
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaTraderBalanceOperation
     */
    comment?: string | null;
}
/**
 * 
 * @export
 * @interface MetaTraderBalanceOperationItemsContainer
 */
export interface MetaTraderBalanceOperationItemsContainer {
    /**
     * 
     * @type {Array<MetaTraderBalanceOperation>}
     * @memberof MetaTraderBalanceOperationItemsContainer
     */
    items?: Array<MetaTraderBalanceOperation> | null;
    /**
     * 
     * @type {number}
     * @memberof MetaTraderBalanceOperationItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface ModelsContainer
 */
export interface ModelsContainer {
    /**
     * 
     * @type {TerminalModelsContainer}
     * @memberof ModelsContainer
     */
    terminal?: TerminalModelsContainer;
    /**
     * 
     * @type {ClientPlatform}
     * @memberof ModelsContainer
     */
    platform?: ClientPlatform;
    /**
     * 
     * @type {FcmNotification}
     * @memberof ModelsContainer
     */
    fcmNotification?: FcmNotification;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    typeTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationItemsContainer
 */
export interface NotificationItemsContainer {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationItemsContainer
     */
    items?: Array<Notification> | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    twoFactorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    recoveryCode?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordForgotRequest
 */
export interface PasswordForgotRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordForgotRequest
     */
    email?: string | null;
    /**
     * 
     * @type {CaptchaChallenge}
     * @memberof PasswordForgotRequest
     */
    captcha?: CaptchaChallenge;
}
/**
 * 
 * @export
 * @interface PasswordInfo
 */
export interface PasswordInfo {
    /**
     * 
     * @type {PasswordTypeInfo}
     * @memberof PasswordInfo
     */
    weak?: PasswordTypeInfo;
    /**
     * 
     * @type {PasswordTypeInfo}
     * @memberof PasswordInfo
     */
    medium?: PasswordTypeInfo;
    /**
     * 
     * @type {PasswordTypeInfo}
     * @memberof PasswordInfo
     */
    strong?: PasswordTypeInfo;
    /**
     * 
     * @type {number}
     * @memberof PasswordInfo
     */
    minLength?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordInfo
     */
    maxLength?: number;
}
/**
 * 
 * @export
 * @interface PasswordRequest
 */
export interface PasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordRequest
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    twoFactorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    recoveryCode?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordTypeInfo
 */
export interface PasswordTypeInfo {
    /**
     * 
     * @type {string}
     * @memberof PasswordTypeInfo
     */
    mask?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordTypeInfo
     */
    description?: string | null;
}
/**
 * Комиссия за операцию
 * @export
 * @interface PaymentFee
 */
export interface PaymentFee {
    /**
     * Фиксированная
     * @type {number}
     * @memberof PaymentFee
     */
    fixed?: number | null;
    /**
     * Процентная
     * @type {number}
     * @memberof PaymentFee
     */
    percent?: number | null;
    /**
     * Специальная комиссия - Network fee
     * @type {boolean}
     * @memberof PaymentFee
     */
    isNetworkFee?: boolean;
    /**
     * Динамическая, необходимо запросить комиссию отдельным запросом
     * @type {boolean}
     * @memberof PaymentFee
     */
    isDynamic?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentInfo
 */
export interface PaymentInfo {
    /**
     * 
     * @type {DepositInfo}
     * @memberof PaymentInfo
     */
    depositInfo?: DepositInfo;
    /**
     * 
     * @type {LimitsAndWithdrawTime}
     * @memberof PaymentInfo
     */
    limitsAndWithdrawTime?: LimitsAndWithdrawTime;
}
/**
 * Лимит платёжного метода за одну операцию
 * @export
 * @interface PaymentLimit
 */
export interface PaymentLimit {
    /**
     * Валюта
     * @type {string}
     * @memberof PaymentLimit
     */
    currency?: string | null;
    /**
     * Минимальный технический лимит платёжки  Тот лимит, что устанавливает нам биллинг
     * @type {number}
     * @memberof PaymentLimit
     */
    from?: number | null;
    /**
     * Максимальный технический лимит платёжки  Тот лимит, что устанавливает нам биллинг
     * @type {number}
     * @memberof PaymentLimit
     */
    to?: number | null;
    /**
     * Бесконечный лимит
     * @type {boolean}
     * @memberof PaymentLimit
     */
    isUnlimited?: boolean;
    /**
     * Минимальный лимит платформы, сумма депозитов и выводов, то, что пользователь видит у себя на выводах
     * @type {number}
     * @memberof PaymentLimit
     */
    platformMin?: number | null;
    /**
     * Масимальный лимит платформы, сумма депозитов и выводов, то, что пользователь видит у себя на выводах
     * @type {number}
     * @memberof PaymentLimit
     */
    platformMax?: number | null;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * Id of payment method
     * @type {number}
     * @memberof PaymentMethod
     */
    id?: number;
    /**
     * Будет false, если пользователю по каким-то причинам нельзя пользоваться этим методом
     * @type {boolean}
     * @memberof PaymentMethod
     */
    isAvailable?: boolean;
    /**
     * true, если на экране пользователя надо открывать платёжку в новом окне
     * @type {boolean}
     * @memberof PaymentMethod
     */
    isOpenInNewWindow?: boolean;
    /**
     * Id of saved payment info (deprecated)
     * @type {string}
     * @memberof PaymentMethod
     */
    reccuringId?: string | null;
    /**
     * Id of saved payment info
     * @type {string}
     * @memberof PaymentMethod
     */
    recurringId?: string | null;
    /**
     * Method name
     * @type {string}
     * @memberof PaymentMethod
     */
    title?: string | null;
    /**
     * Method currency
     * @type {string}
     * @memberof PaymentMethod
     */
    currency?: string | null;
    /**
     * 
     * @type {PaymentMethodType}
     * @memberof PaymentMethod
     */
    type?: PaymentMethodType;
    /**
     * 
     * @type {PaymentMethodDetails}
     * @memberof PaymentMethod
     */
    details?: PaymentMethodDetails;
    /**
     * 
     * @type {PaymentMethodImages}
     * @memberof PaymentMethod
     */
    images?: PaymentMethodImages;
    /**
     * Fields user needs to enter to start a withdraw  Empty for deposits
     * @type {Array<Field>}
     * @memberof PaymentMethod
     */
    fields?: Array<Field> | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodDetails
 */
export interface PaymentMethodDetails {
    /**
     * 
     * @type {PaymentProcessingTime}
     * @memberof PaymentMethodDetails
     */
    processingTime?: PaymentProcessingTime;
    /**
     * 
     * @type {PaymentProcessingTime}
     * @memberof PaymentMethodDetails
     */
    paymentProcessingTime?: PaymentProcessingTime;
    /**
     * Operation limit
     * @type {number}
     * @memberof PaymentMethodDetails
     */
    upperLimit?: number;
    /**
     * Есть неисчерпанные лимиты DepositOnly платёжек
     * @type {boolean}
     * @memberof PaymentMethodDetails
     */
    hasDepositOnlyLimit?: boolean;
    /**
     * 
     * @type {PaymentLimit}
     * @memberof PaymentMethodDetails
     */
    limit?: PaymentLimit;
    /**
     * Лимит на платёжный метод, но для всех валют. Такой же как и DoTo.CRM.Backend.Models.Funds.PaymentMethodDetails.Limit
     * @type {Array<PaymentLimit>}
     * @memberof PaymentMethodDetails
     */
    limits?: Array<PaymentLimit> | null;
    /**
     * 
     * @type {PaymentFee}
     * @memberof PaymentMethodDetails
     */
    fee?: PaymentFee;
    /**
     * 
     * @type {Widget}
     * @memberof PaymentMethodDetails
     */
    widget?: Widget;
}
/**
 * 
 * @export
 * @interface PaymentMethodImages
 */
export interface PaymentMethodImages {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodImages
     */
    normal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodImages
     */
    mini?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodItemsContainer
 */
export interface PaymentMethodItemsContainer {
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof PaymentMethodItemsContainer
     */
    items?: Array<PaymentMethod> | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentMethodType {
    Unknown = 'Unknown',
    BankCard = 'BankCard',
    BankTransfer = 'BankTransfer',
    Crypto = 'Crypto'
}

/**
 * Обёртка над платёжными методами
 * @export
 * @interface PaymentMethods
 */
export interface PaymentMethods {
    /**
     * 
     * @type {PaymentMethodsWithdrawOptions}
     * @memberof PaymentMethods
     */
    withdrawOptions?: PaymentMethodsWithdrawOptions;
    /**
     * 
     * @type {PaymentMethodsDepositOptions}
     * @memberof PaymentMethods
     */
    depositOptions?: PaymentMethodsDepositOptions;
    /**
     * Платёжные методы
     * @type {Array<PaymentMethod>}
     * @memberof PaymentMethods
     */
    items?: Array<PaymentMethod> | null;
}
/**
 * Опции экрана депозитов, либо других экранов, относящихся к депозитам и платёжным методам
 * @export
 * @enum {string}
 */
export enum PaymentMethodsDepositOptions {
    None = 'None'
}

/**
 * Опции экрана выводов, либо других экранов, относящихся к выводам и платёжным методам
 * @export
 * @enum {string}
 */
export enum PaymentMethodsWithdrawOptions {
    None = 'None',
    LowBalance = 'LowBalance'
}

/**
 * Типы времени процессинга платёжным методом
 * @export
 * @enum {string}
 */
export enum PaymentProcessingTime {
    None = 'None',
    Instant = 'Instant',
    FifteenMinutes = 'FifteenMinutes',
    ThreeHours = 'ThreeHours',
    OneDay = 'OneDay',
    ThreeDays = 'ThreeDays'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PlaceHold {
    Any = 'Any',
    MyAccounts = 'MyAccounts'
}

/**
 * 
 * @export
 * @interface PlatformCountry
 */
export interface PlatformCountry {
    /**
     * 
     * @type {string}
     * @memberof PlatformCountry
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformCountry
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformCountry
     */
    numberCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformCountry
     */
    mask?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlatformCountry
     */
    minLength?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformCountry
     */
    flag?: string | null;
}
/**
 * 
 * @export
 * @interface PlatformCurrencyInfo
 */
export interface PlatformCurrencyInfo {
    /**
     * 
     * @type {string}
     * @memberof PlatformCurrencyInfo
     */
    asset?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformCurrencyInfo
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlatformCurrencyInfo
     */
    digits?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformCurrencyInfo
     */
    minAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PlatformCurrencyInfo
     */
    symbol?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PlatformGeo {
    Develop = 'Develop',
    Global = 'Global',
    SouthAfrica = 'SouthAfrica',
    GlobalSouthAfrica = 'GlobalSouthAfrica'
}

/**
 * 
 * @export
 * @interface PlatformInfo
 */
export interface PlatformInfo {
    /**
     * 
     * @type {BrokerInfo}
     * @memberof PlatformInfo
     */
    brokerInfo?: BrokerInfo;
    /**
     * 
     * @type {CaptchaInfo}
     * @memberof PlatformInfo
     */
    captcha?: CaptchaInfo;
    /**
     * 
     * @type {CompanyInfo}
     * @memberof PlatformInfo
     */
    company?: CompanyInfo;
    /**
     * 
     * @type {ValidationInfo}
     * @memberof PlatformInfo
     */
    validation?: ValidationInfo;
    /**
     * 
     * @type {PasswordInfo}
     * @memberof PlatformInfo
     */
    password?: PasswordInfo;
    /**
     * 
     * @type {SignInExternalInfo}
     * @memberof PlatformInfo
     */
    signIn?: SignInExternalInfo;
    /**
     * 
     * @type {VersionInfo}
     * @memberof PlatformInfo
     */
    versionInfo?: VersionInfo;
    /**
     * 
     * @type {Array<PlatformCurrencyInfo>}
     * @memberof PlatformInfo
     */
    currencies?: Array<PlatformCurrencyInfo> | null;
    /**
     * 
     * @type {LiveChatInfo}
     * @memberof PlatformInfo
     */
    liveChatInfo?: LiveChatInfo;
    /**
     * 
     * @type {TradingCentralInfo}
     * @memberof PlatformInfo
     */
    tradingCentral?: TradingCentralInfo;
}
/**
 * 
 * @export
 * @interface PlatformUrlInfo
 */
export interface PlatformUrlInfo {
    /**
     * 
     * @type {string}
     * @memberof PlatformUrlInfo
     */
    apiUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformUrlInfo
     */
    wsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlatformUrlInfo
     */
    terminalWebUrl?: string | null;
    /**
     * 
     * @type {PlatformGeo}
     * @memberof PlatformUrlInfo
     */
    region?: PlatformGeo;
}
/**
 * 
 * @export
 * @interface Popup
 */
export interface Popup {
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    description?: string | null;
    /**
     * 
     * @type {PlaceHold}
     * @memberof Popup
     */
    popupPlaceHold?: PlaceHold;
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    clickText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    pictureRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    clickRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Popup
     */
    mobileClickRef?: string | null;
    /**
     * 
     * @type {Array<Step>}
     * @memberof Popup
     */
    steps?: Array<Step> | null;
}
/**
 * 
 * @export
 * @interface ProfileHeader
 */
export interface ProfileHeader {
    /**
     * 
     * @type {string}
     * @memberof ProfileHeader
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileHeader
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileHeader
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileHeader
     */
    notificationsCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileHeader
     */
    currencies?: Array<string> | null;
    /**
     * 
     * @type {ExternalKycStatus}
     * @memberof ProfileHeader
     */
    kycStatus?: ExternalKycStatus;
    /**
     * 
     * @type {ProfileOptions}
     * @memberof ProfileHeader
     */
    options?: ProfileOptions;
    /**
     * 
     * @type {ProfilePermissions}
     * @memberof ProfileHeader
     */
    permissions?: ProfilePermissions;
    /**
     * 
     * @type {FeatureTours}
     * @memberof ProfileHeader
     */
    featureTours?: FeatureTours;
    /**
     * 
     * @type {FeaturesInfo}
     * @memberof ProfileHeader
     */
    featuresInfo?: FeaturesInfo;
}
/**
 * 
 * @export
 * @interface ProfileInfo
 */
export interface ProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfo
     */
    dateOfBirth?: string | null;
}
/**
 * 
 * @export
 * @interface ProfileOptions
 */
export interface ProfileOptions {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isPasswordEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    is2FaEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isEmailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isPhoneConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isProfileFilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isAddressFilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isSurveyCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isKycCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isTerminalTargetPricesEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOptions
     */
    isTerminalTargetPricesDialogEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface ProfilePermissions
 */
export interface ProfilePermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ProfilePermissions
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilePermissions
     */
    isProfileEditingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilePermissions
     */
    isWithdrawalEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileSettings
 */
export interface ProfileSettings {
    /**
     * 
     * @type {LocaleInfo}
     * @memberof ProfileSettings
     */
    locale?: LocaleInfo;
    /**
     * 
     * @type {number}
     * @memberof ProfileSettings
     */
    timezone?: number | null;
}
/**
 * 
 * @export
 * @interface ProfileSettingsRequest
 */
export interface ProfileSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileSettingsRequest
     */
    languageCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileSettingsRequest
     */
    timezone?: number | null;
}
/**
 * 
 * @export
 * @interface ProfileUpdate
 */
export interface ProfileUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileUpdate
     */
    isNotUSAResident?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    phoneCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdate
     */
    phoneCode?: string | null;
}
/**
 * 
 * @export
 * @interface RaffleResponse
 */
export interface RaffleResponse {
    /**
     * 
     * @type {Array<Banner>}
     * @memberof RaffleResponse
     */
    banners?: Array<Banner> | null;
    /**
     * 
     * @type {Array<Popup>}
     * @memberof RaffleResponse
     */
    popups?: Array<Popup> | null;
    /**
     * 
     * @type {string}
     * @memberof RaffleResponse
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof RaffleResponse
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface RateResult
 */
export interface RateResult {
    /**
     * 
     * @type {number}
     * @memberof RateResult
     */
    rate?: number;
}
/**
 * 
 * @export
 * @interface RatesResult
 */
export interface RatesResult {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof RatesResult
     */
    rates?: { [key: string]: { [key: string]: number; }; } | null;
}
/**
 * 
 * @export
 * @interface Regexp
 */
export interface Regexp {
    /**
     * 
     * @type {Error}
     * @memberof Regexp
     */
    error?: Error;
    /**
     * 
     * @type {string}
     * @memberof Regexp
     */
    expression?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Regexp
     */
    flags?: string | null;
}
/**
 * 
 * @export
 * @interface SignInAppleInfo
 */
export interface SignInAppleInfo {
    /**
     * 
     * @type {string}
     * @memberof SignInAppleInfo
     */
    serviceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignInAppleInfo
     */
    isEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface SignInExternalInfo
 */
export interface SignInExternalInfo {
    /**
     * 
     * @type {SignInGoogleInfo}
     * @memberof SignInExternalInfo
     */
    google?: SignInGoogleInfo;
    /**
     * 
     * @type {SignInFacebookInfo}
     * @memberof SignInExternalInfo
     */
    facebook?: SignInFacebookInfo;
    /**
     * 
     * @type {SignInAppleInfo}
     * @memberof SignInExternalInfo
     */
    apple?: SignInAppleInfo;
}
/**
 * 
 * @export
 * @interface SignInFacebookInfo
 */
export interface SignInFacebookInfo {
    /**
     * 
     * @type {string}
     * @memberof SignInFacebookInfo
     */
    appId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignInFacebookInfo
     */
    isEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface SignInGoogleInfo
 */
export interface SignInGoogleInfo {
    /**
     * 
     * @type {string}
     * @memberof SignInGoogleInfo
     */
    clientId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignInGoogleInfo
     */
    isEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password?: string | null;
    /**
     * 
     * @type {CaptchaChallenge}
     * @memberof SignUpRequest
     */
    captcha?: CaptchaChallenge;
}
/**
 * 
 * @export
 * @interface SourceOfFundsLimits
 */
export interface SourceOfFundsLimits {
    /**
     * 
     * @type {number}
     * @memberof SourceOfFundsLimits
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceOfFundsLimits
     */
    current?: number;
    /**
     * 
     * @type {string}
     * @memberof SourceOfFundsLimits
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Step
     */
    isCompleted?: boolean;
}
/**
 * 
 * @export
 * @interface StringContainer
 */
export interface StringContainer {
    /**
     * 
     * @type {string}
     * @memberof StringContainer
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface SumSubInfo
 */
export interface SumSubInfo {
    /**
     * 
     * @type {Array<LevelName>}
     * @memberof SumSubInfo
     */
    levelNames?: Array<LevelName> | null;
}
/**
 * 
 * @export
 * @interface SurveyField
 */
export interface SurveyField {
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    placeholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    value?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyField
     */
    pollValues?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyField
     */
    isRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurveyField
     */
    orderIndex?: number;
    /**
     * 
     * @type {FieldType}
     * @memberof SurveyField
     */
    type?: FieldType;
    /**
     * 
     * @type {FieldView}
     * @memberof SurveyField
     */
    view?: FieldView;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    typeTitle?: string | null;
    /**
     * 
     * @type {Array<SurveyPollField>}
     * @memberof SurveyField
     */
    pollFields?: Array<SurveyPollField> | null;
    /**
     * 
     * @type {Array<SurveyField>}
     * @memberof SurveyField
     */
    fields?: Array<SurveyField> | null;
    /**
     * 
     * @type {FieldCondition}
     * @memberof SurveyField
     */
    condition?: FieldCondition;
    /**
     * 
     * @type {string}
     * @memberof SurveyField
     */
    conditionValue?: string | null;
}
/**
 * 
 * @export
 * @interface SurveyGroup
 */
export interface SurveyGroup {
    /**
     * 
     * @type {string}
     * @memberof SurveyGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyGroup
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyGroup
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyGroup
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyGroup
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyGroup
     */
    orderIndex?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyGroup
     */
    isFilled?: boolean;
    /**
     * 
     * @type {Array<SurveyField>}
     * @memberof SurveyGroup
     */
    fields?: Array<SurveyField> | null;
}
/**
 * 
 * @export
 * @interface SurveyInfo
 */
export interface SurveyInfo {
    /**
     * 
     * @type {Array<SurveyGroup>}
     * @memberof SurveyInfo
     */
    surveyGroups?: Array<SurveyGroup> | null;
}
/**
 * 
 * @export
 * @interface SurveyPollField
 */
export interface SurveyPollField {
    /**
     * 
     * @type {string}
     * @memberof SurveyPollField
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyPollField
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyPollField
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyPollField
     */
    placeholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyPollField
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyPollField
     */
    orderIndex?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyPollField
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Array<SurveyField>}
     * @memberof SurveyPollField
     */
    fields?: Array<SurveyField> | null;
}
/**
 * 
 * @export
 * @interface SurveyUpdate
 */
export interface SurveyUpdate {
    /**
     * 
     * @type {string}
     * @memberof SurveyUpdate
     */
    surveyGroupId?: string;
    /**
     * 
     * @type {Array<SurveyUpdateItem>}
     * @memberof SurveyUpdate
     */
    items?: Array<SurveyUpdateItem> | null;
}
/**
 * 
 * @export
 * @interface SurveyUpdateItem
 */
export interface SurveyUpdateItem {
    /**
     * 
     * @type {string}
     * @memberof SurveyUpdateItem
     */
    fieldId?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUpdateItem
     */
    value?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyUpdateItem
     */
    pollItems?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TerminalAccountSymbols
 */
export interface TerminalAccountSymbols {
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalAccountSymbols
     */
    favorites?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalAccountSymbols
     */
    popular?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalAccountSymbols
     */
    topActive?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalAccountSymbols
     */
    charts?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalAccountSymbols
     */
    excluded?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalAccountSymbols
     */
    chartsSelected?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalAccountSymbols
     */
    group?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalBalanceOperation
 */
export interface TerminalBalanceOperation {
    /**
     * 
     * @type {number}
     * @memberof TerminalBalanceOperation
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalBalanceOperation
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalBalanceOperation
     */
    comment?: string | null;
    /**
     * 
     * @type {TradingAccountMT5DealAction}
     * @memberof TerminalBalanceOperation
     */
    action?: TradingAccountMT5DealAction;
}
/**
 * 
 * @export
 * @interface TerminalBalanceOperationItemsContainer
 */
export interface TerminalBalanceOperationItemsContainer {
    /**
     * 
     * @type {Array<TerminalBalanceOperation>}
     * @memberof TerminalBalanceOperationItemsContainer
     */
    items?: Array<TerminalBalanceOperation> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalBalanceOperationItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TerminalBar
 */
export interface TerminalBar {
    /**
     * 
     * @type {string}
     * @memberof TerminalBar
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TerminalBar
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalBar
     */
    high?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalBar
     */
    low?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalBar
     */
    close?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalBar
     */
    volume?: number;
}
/**
 * 
 * @export
 * @interface TerminalBarItemsContainer
 */
export interface TerminalBarItemsContainer {
    /**
     * 
     * @type {Array<TerminalBar>}
     * @memberof TerminalBarItemsContainer
     */
    items?: Array<TerminalBar> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalBarItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalChannel {
    None = 'None',
    TraderPublic = 'TraderPublic',
    TraderPrivate = 'TraderPrivate'
}

/**
 * 
 * @export
 * @interface TerminalChartData
 */
export interface TerminalChartData {
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    resolution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartData
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface TerminalChartMetaInfo
 */
export interface TerminalChartMetaInfo {
    /**
     * 
     * @type {string}
     * @memberof TerminalChartMetaInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartMetaInfo
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartMetaInfo
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartMetaInfo
     */
    resolution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartMetaInfo
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface TerminalChartMetaInfoItemsContainer
 */
export interface TerminalChartMetaInfoItemsContainer {
    /**
     * 
     * @type {Array<TerminalChartMetaInfo>}
     * @memberof TerminalChartMetaInfoItemsContainer
     */
    items?: Array<TerminalChartMetaInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalChartMetaInfoItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TerminalChartUpdateData
 */
export interface TerminalChartUpdateData {
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateData
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateData
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateData
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateData
     */
    resolution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateData
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalChartUpdateResult
 */
export interface TerminalChartUpdateResult {
    /**
     * 
     * @type {string}
     * @memberof TerminalChartUpdateResult
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface TerminalCloseOrderRequest
 */
export interface TerminalCloseOrderRequest {
    /**
     * 
     * @type {TerminalCloseOrderType}
     * @memberof TerminalCloseOrderRequest
     */
    type?: TerminalCloseOrderType;
    /**
     * 
     * @type {number}
     * @memberof TerminalCloseOrderRequest
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalCloseOrderRequest
     */
    volume?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TerminalCloseOrderRequest
     */
    onlyProfitable?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalCloseOrderType {
    All = 'All',
    Market = 'Market',
    Limit = 'Limit'
}

/**
 * 
 * @export
 * @interface TerminalCommand
 */
export interface TerminalCommand {
    /**
     * 
     * @type {TerminalCommandType}
     * @memberof TerminalCommand
     */
    type?: TerminalCommandType;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalCommand
     */
    symbols?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalCommandType {
    Ping = 'Ping',
    TickSubscribe = 'TickSubscribe',
    TickUnsubscribe = 'TickUnsubscribe',
    MarketWatchSubscribe = 'MarketWatchSubscribe',
    MarketWatchUnsubscribe = 'MarketWatchUnsubscribe',
    TradingSubscribe = 'TradingSubscribe',
    TradingUnsubscribe = 'TradingUnsubscribe',
    TradingInitData = 'TradingInitData'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealEntryType {
    In = 'In',
    Out = 'Out',
    InOut = 'InOut',
    OutBy = 'OutBy'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealEventType {
    Update = 'Update',
    Delete = 'Delete'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealExpirationType {
    Gtc = 'GTC',
    Day = 'Day',
    Specified = 'Specified',
    SpecifiedDay = 'SpecifiedDay'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealFillPolicy {
    FillOrKill = 'FillOrKill',
    ImmediateOrCancel = 'ImmediateOrCancel',
    Return = 'Return'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealInitiator {
    Default = 'Default',
    TerminalDoto = 'TerminalDoto',
    TerminalMt = 'TerminalMT',
    Sl = 'SL',
    Tp = 'TP',
    So = 'SO'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealNotificationCloseType {
    Default = 'Default',
    Sl = 'SL',
    Tp = 'TP',
    So = 'SO',
    Partial = 'Partial'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealNotificationModifyType {
    Default = 'Default'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealNotificationOpenType {
    Default = 'Default',
    FromPending = 'FromPending'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealNotificationType {
    Opened = 'Opened',
    Closed = 'Closed',
    Modified = 'Modified'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealReason {
    Manual = 'Manual',
    StopLoss = 'StopLoss',
    TakeProfit = 'TakeProfit',
    StopOut = 'StopOut',
    Broker = 'Broker',
    PartialClose = 'PartialClose'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealType {
    Buy = 'Buy',
    Sell = 'Sell',
    BuyLimit = 'BuyLimit',
    SellLimit = 'SellLimit',
    BuyStop = 'BuyStop',
    SellStop = 'SellStop',
    BuyStopLimit = 'BuyStopLimit',
    SellStopLimit = 'SellStopLimit'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalDealUpdateType {
    Open = 'Open',
    Close = 'Close'
}

/**
 * 
 * @export
 * @interface TerminalEvent
 */
export interface TerminalEvent {
    /**
     * 
     * @type {TerminalEventType}
     * @memberof TerminalEvent
     */
    t?: TerminalEventType;
    /**
     * 
     * @type {number}
     * @memberof TerminalEvent
     */
    e?: number | null;
    /**
     * 
     * @type {TerminalEventTick}
     * @memberof TerminalEvent
     */
    dt?: TerminalEventTick;
    /**
     * 
     * @type {TerminalEventMarketWatch}
     * @memberof TerminalEvent
     */
    dm?: TerminalEventMarketWatch;
    /**
     * 
     * @type {TerminalEventLastPrices}
     * @memberof TerminalEvent
     */
    dlp?: TerminalEventLastPrices;
    /**
     * 
     * @type {TerminalEventSignals}
     * @memberof TerminalEvent
     */
    dss?: TerminalEventSignals;
    /**
     * 
     * @type {TerminalEventWidgets}
     * @memberof TerminalEvent
     */
    dsw?: TerminalEventWidgets;
    /**
     * 
     * @type {TerminalEventSymbolsSessionChanges}
     * @memberof TerminalEvent
     */
    dse?: TerminalEventSymbolsSessionChanges;
    /**
     * 
     * @type {TerminalEventDeal}
     * @memberof TerminalEvent
     */
    dd?: TerminalEventDeal;
    /**
     * 
     * @type {Array<TerminalEventDeal>}
     * @memberof TerminalEvent
     */
    dds?: Array<TerminalEventDeal> | null;
    /**
     * 
     * @type {Array<TerminalEventDealNotification>}
     * @memberof TerminalEvent
     */
    ddn?: Array<TerminalEventDealNotification> | null;
    /**
     * 
     * @type {TerminalEventAccountUpdate}
     * @memberof TerminalEvent
     */
    da?: TerminalEventAccountUpdate;
    /**
     * 
     * @type {TerminalEventSubscription}
     * @memberof TerminalEvent
     */
    ds?: TerminalEventSubscription;
    /**
     * 
     * @type {TerminalEventTradingNotification}
     * @memberof TerminalEvent
     */
    dtn?: TerminalEventTradingNotification;
}
/**
 * 
 * @export
 * @interface TerminalEventAccountUpdate
 */
export interface TerminalEventAccountUpdate {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventAccountUpdate
     */
    b?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventAccountUpdate
     */
    c?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventAccountUpdate
     */
    l?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventDeal
 */
export interface TerminalEventDeal {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    d?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventDeal
     */
    s?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventDeal
     */
    dt?: string;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalEventDeal
     */
    t?: TerminalDealType;
    /**
     * 
     * @type {TerminalDealInitiator}
     * @memberof TerminalEventDeal
     */
    i?: TerminalDealInitiator;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    sw?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    v?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    p?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    rm?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    rp?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    sl?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDeal
     */
    tp?: number | null;
    /**
     * 
     * @type {TerminalDealEventType}
     * @memberof TerminalEventDeal
     */
    e?: TerminalDealEventType;
    /**
     * 
     * @type {TerminalEventDealUpdate}
     * @memberof TerminalEventDeal
     */
    u?: TerminalEventDealUpdate;
}
/**
 * 
 * @export
 * @interface TerminalEventDealNotification
 */
export interface TerminalEventDealNotification {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealNotification
     */
    d?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventDealNotification
     */
    s?: string | null;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalEventDealNotification
     */
    t?: TerminalDealType;
    /**
     * 
     * @type {TerminalDealNotificationType}
     * @memberof TerminalEventDealNotification
     */
    e?: TerminalDealNotificationType;
    /**
     * 
     * @type {TerminalDealNotificationOpenType}
     * @memberof TerminalEventDealNotification
     */
    eo?: TerminalDealNotificationOpenType;
    /**
     * 
     * @type {TerminalDealNotificationCloseType}
     * @memberof TerminalEventDealNotification
     */
    ec?: TerminalDealNotificationCloseType;
    /**
     * 
     * @type {TerminalDealNotificationModifyType}
     * @memberof TerminalEventDealNotification
     */
    em?: TerminalDealNotificationModifyType;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealNotification
     */
    v?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealNotification
     */
    p?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealNotification
     */
    r?: number | null;
}
/**
 * 
 * @export
 * @interface TerminalEventDealUpdate
 */
export interface TerminalEventDealUpdate {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealUpdate
     */
    i?: number;
    /**
     * 
     * @type {TerminalDealUpdateType}
     * @memberof TerminalEventDealUpdate
     */
    u?: TerminalDealUpdateType;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealUpdate
     */
    v?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealUpdate
     */
    p?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventDealUpdate
     */
    r?: number | null;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalEventDealUpdate
     */
    t?: TerminalDealType;
}
/**
 * 
 * @export
 * @interface TerminalEventLastPrices
 */
export interface TerminalEventLastPrices {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventLastPrices
     */
    t?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventLastPrices
     */
    h?: number;
    /**
     * 
     * @type {Array<TerminalEventLastSymbolPrice>}
     * @memberof TerminalEventLastPrices
     */
    p?: Array<TerminalEventLastSymbolPrice> | null;
}
/**
 * 
 * @export
 * @interface TerminalEventLastSymbolPrice
 */
export interface TerminalEventLastSymbolPrice {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventLastSymbolPrice
     */
    s?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventLastSymbolPrice
     */
    p?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventLastSymbolPrice
     */
    h?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventLastSymbolPrice
     */
    l?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventMarketWatch
 */
export interface TerminalEventMarketWatch {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventMarketWatch
     */
    t?: number;
    /**
     * 
     * @type {Array<TerminalEventMarketWatchTick>}
     * @memberof TerminalEventMarketWatch
     */
    s?: Array<TerminalEventMarketWatchTick> | null;
}
/**
 * 
 * @export
 * @interface TerminalEventMarketWatchTick
 */
export interface TerminalEventMarketWatchTick {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventMarketWatchTick
     */
    s?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventMarketWatchTick
     */
    b?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventMarketWatchTick
     */
    a?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventSignals
 */
export interface TerminalEventSignals {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSignals
     */
    t?: number;
    /**
     * 
     * @type {Array<TerminalEventSymbolSignal>}
     * @memberof TerminalEventSignals
     */
    p?: Array<TerminalEventSymbolSignal> | null;
}
/**
 * 
 * @export
 * @interface TerminalEventSubscription
 */
export interface TerminalEventSubscription {
    /**
     * 
     * @type {Array<TerminalSubscription>}
     * @memberof TerminalEventSubscription
     */
    t?: Array<TerminalSubscription> | null;
    /**
     * 
     * @type {Array<TerminalChannel>}
     * @memberof TerminalEventSubscription
     */
    c?: Array<TerminalChannel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalEventSubscription
     */
    dt?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalEventSubscription
     */
    dm?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolSessionState
 */
export interface TerminalEventSymbolSessionState {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolSessionState
     */
    s?: string | null;
    /**
     * 
     * @type {TerminalEventSymbolSessionStateType}
     * @memberof TerminalEventSymbolSessionState
     */
    t?: TerminalEventSymbolSessionStateType;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolSessionState
     */
    e?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolSessionState
     */
    d?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalEventSymbolSessionStateType {
    Opened = 'Opened',
    Closed = 'Closed',
    InProcessOpened = 'InProcessOpened',
    InProcessClosed = 'InProcessClosed'
}

/**
 * 
 * @export
 * @interface TerminalEventSymbolSignal
 */
export interface TerminalEventSymbolSignal {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolSignal
     */
    s?: string | null;
    /**
     * 
     * @type {TerminalEventSymbolSignalType}
     * @memberof TerminalEventSymbolSignal
     */
    t?: TerminalEventSymbolSignalType;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolSignal
     */
    pt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolSignal
     */
    pr1?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolSignal
     */
    pr2?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolSignal
     */
    ps1?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolSignal
     */
    ps2?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalEventSymbolSignalBuzzingType {
    NoNews = 'NoNews',
    BelowAverage = 'BelowAverage',
    Average = 'Average',
    AboveAverage = 'AboveAverage',
    Buzzing = 'Buzzing'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalEventSymbolSignalTrend {
    None = 'None',
    Bearish = 'Bearish',
    Bullish = 'Bullish',
    Neutral = 'Neutral'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalEventSymbolSignalType {
    None = 'None',
    Up = 'Up',
    StrongUp = 'StrongUp',
    StrongDown = 'StrongDown',
    Down = 'Down'
}

/**
 * 
 * @export
 * @interface TerminalEventSymbolWidget
 */
export interface TerminalEventSymbolWidget {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolWidget
     */
    s?: string | null;
    /**
     * 
     * @type {TerminalEventSymbolWidgetTrend}
     * @memberof TerminalEventSymbolWidget
     */
    t?: TerminalEventSymbolWidgetTrend;
    /**
     * 
     * @type {TerminalEventSymbolWidgetSupportAndResistance}
     * @memberof TerminalEventSymbolWidget
     */
    sr?: TerminalEventSymbolWidgetSupportAndResistance;
    /**
     * 
     * @type {TerminalEventSymbolWidgetTechnicalSummaryScore}
     * @memberof TerminalEventSymbolWidget
     */
    ss?: TerminalEventSymbolWidgetTechnicalSummaryScore;
    /**
     * 
     * @type {TerminalEventSymbolWidgetBuzzing}
     * @memberof TerminalEventSymbolWidget
     */
    b?: TerminalEventSymbolWidgetBuzzing;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolWidgetBuzzing
 */
export interface TerminalEventSymbolWidgetBuzzing {
    /**
     * 
     * @type {TerminalEventSymbolSignalBuzzingType}
     * @memberof TerminalEventSymbolWidgetBuzzing
     */
    b?: TerminalEventSymbolSignalBuzzingType;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolWidgetBuzzing
     */
    m?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolWidgetSupportAndResistance
 */
export interface TerminalEventSymbolWidgetSupportAndResistance {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolWidgetSupportAndResistance
     */
    s?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolWidgetSupportAndResistance
     */
    r?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolWidgetTechnicalSummaryScore
 */
export interface TerminalEventSymbolWidgetTechnicalSummaryScore {
    /**
     * 
     * @type {TerminalEventSymbolWidgetTechnicalSummaryScoreItem}
     * @memberof TerminalEventSymbolWidgetTechnicalSummaryScore
     */
    l?: TerminalEventSymbolWidgetTechnicalSummaryScoreItem;
    /**
     * 
     * @type {TerminalEventSymbolWidgetTechnicalSummaryScoreItem}
     * @memberof TerminalEventSymbolWidgetTechnicalSummaryScore
     */
    s?: TerminalEventSymbolWidgetTechnicalSummaryScoreItem;
    /**
     * 
     * @type {TerminalEventSymbolWidgetTechnicalSummaryScoreItem}
     * @memberof TerminalEventSymbolWidgetTechnicalSummaryScore
     */
    i?: TerminalEventSymbolWidgetTechnicalSummaryScoreItem;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolWidgetTechnicalSummaryScoreItem
 */
export interface TerminalEventSymbolWidgetTechnicalSummaryScoreItem {
    /**
     * 
     * @type {TerminalEventSymbolSignalTrend}
     * @memberof TerminalEventSymbolWidgetTechnicalSummaryScoreItem
     */
    t?: TerminalEventSymbolSignalTrend;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolWidgetTechnicalSummaryScoreItem
     */
    s?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolWidgetTrend
 */
export interface TerminalEventSymbolWidgetTrend {
    /**
     * 
     * @type {TerminalEventSymbolSignalTrend}
     * @memberof TerminalEventSymbolWidgetTrend
     */
    t?: TerminalEventSymbolSignalTrend;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventSymbolWidgetTrend
     */
    c?: string;
}
/**
 * 
 * @export
 * @interface TerminalEventSymbolsSessionChanges
 */
export interface TerminalEventSymbolsSessionChanges {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventSymbolsSessionChanges
     */
    t?: number;
    /**
     * 
     * @type {Array<TerminalEventSymbolSessionState>}
     * @memberof TerminalEventSymbolsSessionChanges
     */
    c?: Array<TerminalEventSymbolSessionState> | null;
}
/**
 * 
 * @export
 * @interface TerminalEventTick
 */
export interface TerminalEventTick {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventTick
     */
    s?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventTick
     */
    b?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventTick
     */
    a?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalEventTick
     */
    t?: number;
}
/**
 * 
 * @export
 * @interface TerminalEventTradingNotification
 */
export interface TerminalEventTradingNotification {
    /**
     * 
     * @type {string}
     * @memberof TerminalEventTradingNotification
     */
    h?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalEventTradingNotification
     */
    m?: string | null;
    /**
     * 
     * @type {TerminalNotificationType}
     * @memberof TerminalEventTradingNotification
     */
    t?: TerminalNotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof TerminalEventTradingNotification
     */
    p?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalEventType {
    Heartbeat = 'Heartbeat',
    Subscription = 'Subscription',
    Tick = 'Tick',
    MarketWatch = 'MarketWatch',
    LastPrices = 'LastPrices',
    Signals = 'Signals',
    Widgets = 'Widgets',
    SymbolSessions = 'SymbolSessions',
    Deal = 'Deal',
    DealsNotifications = 'DealsNotifications',
    Account = 'Account',
    Init = 'Init',
    TradingNotification = 'TradingNotification'
}

/**
 * 
 * @export
 * @interface TerminalEventWidgets
 */
export interface TerminalEventWidgets {
    /**
     * 
     * @type {number}
     * @memberof TerminalEventWidgets
     */
    t?: number;
    /**
     * 
     * @type {Array<TerminalEventSymbolWidget>}
     * @memberof TerminalEventWidgets
     */
    w?: Array<TerminalEventSymbolWidget> | null;
}
/**
 * 
 * @export
 * @interface TerminalIndicator
 */
export interface TerminalIndicator {
    /**
     * 
     * @type {string}
     * @memberof TerminalIndicator
     */
    interval?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalIndicator
     */
    chartContent?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalIndicatorContainer
 */
export interface TerminalIndicatorContainer {
    /**
     * 
     * @type {TerminalIndicator}
     * @memberof TerminalIndicatorContainer
     */
    indicator?: TerminalIndicator;
}
/**
 * 
 * @export
 * @interface TerminalModelsContainer
 */
export interface TerminalModelsContainer {
    /**
     * 
     * @type {TerminalCommand}
     * @memberof TerminalModelsContainer
     */
    command?: TerminalCommand;
    /**
     * 
     * @type {TerminalEvent}
     * @memberof TerminalModelsContainer
     */
    event?: TerminalEvent;
}
/**
 * 
 * @export
 * @interface TerminalModifyOrderRequest
 */
export interface TerminalModifyOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof TerminalModifyOrderRequest
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalModifyOrderRequest
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalModifyOrderRequest
     */
    takeProfit?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalNotificationType {
    Default = 'Default',
    Info = 'Info',
    Waring = 'Waring',
    Danger = 'Danger'
}

/**
 * 
 * @export
 * @interface TerminalOpenOrderRequest
 */
export interface TerminalOpenOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalOpenOrderRequest
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOpenOrderRequest
     */
    volume?: number;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalOpenOrderRequest
     */
    type?: TerminalDealType;
    /**
     * 
     * @type {TerminalDealFillPolicy}
     * @memberof TerminalOpenOrderRequest
     */
    fillPolicy?: TerminalDealFillPolicy;
    /**
     * 
     * @type {TerminalDealExpirationType}
     * @memberof TerminalOpenOrderRequest
     */
    expirationType?: TerminalDealExpirationType;
    /**
     * 
     * @type {string}
     * @memberof TerminalOpenOrderRequest
     */
    expirationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOpenOrderRequest
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOpenOrderRequest
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOpenOrderRequest
     */
    takeProfit?: number | null;
    /**
     * 
     * @type {TerminalOpenOrderVolumeOriginal}
     * @memberof TerminalOpenOrderRequest
     */
    volumeOriginal?: TerminalOpenOrderVolumeOriginal;
    /**
     * 
     * @type {TradingCentralSignalType}
     * @memberof TerminalOpenOrderRequest
     */
    signalType?: TradingCentralSignalType;
}
/**
 * 
 * @export
 * @interface TerminalOpenOrderVolumeOriginal
 */
export interface TerminalOpenOrderVolumeOriginal {
    /**
     * 
     * @type {TerminalOpenOrderVolumeOriginalType}
     * @memberof TerminalOpenOrderVolumeOriginal
     */
    type?: TerminalOpenOrderVolumeOriginalType;
    /**
     * 
     * @type {number}
     * @memberof TerminalOpenOrderVolumeOriginal
     */
    amount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalOpenOrderVolumeOriginalType {
    Lots = 'Lots',
    Margin = 'Margin',
    Currency = 'Currency'
}

/**
 * 
 * @export
 * @interface TerminalOrder
 */
export interface TerminalOrder {
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    deal?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalOrder
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    symbolVolumeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    symbolDigits?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalOrder
     */
    date?: string;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalOrder
     */
    type?: TerminalDealType;
    /**
     * 
     * @type {TerminalDealEntryType}
     * @memberof TerminalOrder
     */
    entry?: TerminalDealEntryType;
    /**
     * 
     * @type {TerminalDealReason}
     * @memberof TerminalOrder
     */
    reason?: TerminalDealReason;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    commission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    swap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrder
     */
    takeProfit?: number | null;
}
/**
 * 
 * @export
 * @interface TerminalOrderItemsContainer
 */
export interface TerminalOrderItemsContainer {
    /**
     * 
     * @type {Array<TerminalOrder>}
     * @memberof TerminalOrderItemsContainer
     */
    items?: Array<TerminalOrder> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalOrderItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalOrderSorting {
    ByDateOpenAsc = 'ByDateOpenAsc',
    ByDateOpenDesc = 'ByDateOpenDesc',
    ByDateCloseAsc = 'ByDateCloseAsc',
    ByDateCloseDesc = 'ByDateCloseDesc',
    ByPositionAsc = 'ByPositionAsc',
    ByPositionDesc = 'ByPositionDesc'
}

/**
 * 
 * @export
 * @interface TerminalPosition
 */
export interface TerminalPosition {
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalPosition
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    symbolVolumeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    symbolDigits?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    symbolLeverage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalPosition
     */
    dateOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalPosition
     */
    dateClose?: string | null;
    /**
     * 
     * @type {TerminalDealType}
     * @memberof TerminalPosition
     */
    type?: TerminalDealType;
    /**
     * 
     * @type {TerminalDealReason}
     * @memberof TerminalPosition
     */
    reason?: TerminalDealReason;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    commission?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    swap?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    volumeBuy?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    volumeSell?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    priceOpen?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    priceClose?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    stopLoss?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    takeProfit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    margin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalPosition
     */
    tradeAmount?: number | null;
}
/**
 * 
 * @export
 * @interface TerminalPositionItemsContainer
 */
export interface TerminalPositionItemsContainer {
    /**
     * 
     * @type {Array<TerminalPosition>}
     * @memberof TerminalPositionItemsContainer
     */
    items?: Array<TerminalPosition> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalPositionItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TerminalSession
 */
export interface TerminalSession {
    /**
     * 
     * @type {DayOfWeek}
     * @memberof TerminalSession
     */
    dayOfWeek?: DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof TerminalSession
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalSession
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface TerminalStudyTemplateAddData
 */
export interface TerminalStudyTemplateAddData {
    /**
     * 
     * @type {string}
     * @memberof TerminalStudyTemplateAddData
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalStudyTemplateAddData
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalStudyTemplateData
 */
export interface TerminalStudyTemplateData {
    /**
     * 
     * @type {string}
     * @memberof TerminalStudyTemplateData
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalStudyTemplateData
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalStudyTemplateMetaInfo
 */
export interface TerminalStudyTemplateMetaInfo {
    /**
     * 
     * @type {string}
     * @memberof TerminalStudyTemplateMetaInfo
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalStudyTemplateMetaInfoItemsContainer
 */
export interface TerminalStudyTemplateMetaInfoItemsContainer {
    /**
     * 
     * @type {Array<TerminalStudyTemplateMetaInfo>}
     * @memberof TerminalStudyTemplateMetaInfoItemsContainer
     */
    items?: Array<TerminalStudyTemplateMetaInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalStudyTemplateMetaInfoItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TerminalSubscription {
    None = 'None',
    Ticks = 'Ticks',
    Trading = 'Trading',
    MarketWatch = 'MarketWatch'
}

/**
 * 
 * @export
 * @interface TerminalSymbol
 */
export interface TerminalSymbol {
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    symbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    baseCurrency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    quoteCurrency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    baseCurrencyExt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    quoteCurrencyExt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    contractSize?: number;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    nameTradingCentralWidget?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    nameTradingCentralSummary?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    volumeMin?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    volumeMax?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    volumeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    swapLong?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    swapShort?: number;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof TerminalSymbol
     */
    swapTriple?: DayOfWeek;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    digits?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    marginRateInitialMarketBuy?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    marginRateInitialMarketSell?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    marginRateMaintenanceMarketBuy?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    marginRateMaintenanceMarketSell?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    fullPip?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    priceLast24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    priceHigh24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    priceLow24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    priceBid?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbol
     */
    priceAsk?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    firstChartDate?: string;
    /**
     * 
     * @type {TerminalEventSymbolSessionStateType}
     * @memberof TerminalSymbol
     */
    state?: TerminalEventSymbolSessionStateType;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    periodDateEnd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    periodDuration?: string | null;
    /**
     * 
     * @type {TerminalSymbolSignal}
     * @memberof TerminalSymbol
     */
    signal?: TerminalSymbolSignal;
    /**
     * 
     * @type {TerminalSymbolWidget}
     * @memberof TerminalSymbol
     */
    widget?: TerminalSymbolWidget;
    /**
     * 
     * @type {TradingServerSymbolType}
     * @memberof TerminalSymbol
     */
    type?: TradingServerSymbolType;
    /**
     * 
     * @type {TradingServerSymbolTradeMode}
     * @memberof TerminalSymbol
     */
    tradeMode?: TradingServerSymbolTradeMode;
    /**
     * 
     * @type {TradingServerSymbolExecutionMode}
     * @memberof TerminalSymbol
     */
    executionMode?: TradingServerSymbolExecutionMode;
    /**
     * 
     * @type {TradingServerSymbolFillFlags}
     * @memberof TerminalSymbol
     */
    fillFlags?: TradingServerSymbolFillFlags;
    /**
     * 
     * @type {TradingServerSymbolExpirationFlags}
     * @memberof TerminalSymbol
     */
    expirationFlags?: TradingServerSymbolExpirationFlags;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbol
     */
    sessionsTradingView?: string | null;
    /**
     * 
     * @type {Array<TerminalSession>}
     * @memberof TerminalSymbol
     */
    sessions?: Array<TerminalSession> | null;
}
/**
 * 
 * @export
 * @interface TerminalSymbolSignal
 */
export interface TerminalSymbolSignal {
    /**
     * 
     * @type {TradingCentralSignalType}
     * @memberof TerminalSymbolSignal
     */
    type?: TradingCentralSignalType;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolSignal
     */
    priceTarget?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolSignal
     */
    priceResistance1?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolSignal
     */
    priceResistance2?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolSignal
     */
    priceSupport1?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolSignal
     */
    priceSupport2?: number;
}
/**
 * 
 * @export
 * @interface TerminalSymbolUpdate
 */
export interface TerminalSymbolUpdate {
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbolUpdate
     */
    symbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolUpdate
     */
    priceLast24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolUpdate
     */
    priceHigh24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolUpdate
     */
    priceLow24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolUpdate
     */
    priceBid?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolUpdate
     */
    priceAsk?: number | null;
    /**
     * 
     * @type {TerminalEventSymbolSessionStateType}
     * @memberof TerminalSymbolUpdate
     */
    state?: TerminalEventSymbolSessionStateType;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbolUpdate
     */
    periodDateEnd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbolUpdate
     */
    periodDuration?: string | null;
    /**
     * 
     * @type {TerminalSymbolSignal}
     * @memberof TerminalSymbolUpdate
     */
    signal?: TerminalSymbolSignal;
    /**
     * 
     * @type {TerminalSymbolWidget}
     * @memberof TerminalSymbolUpdate
     */
    widget?: TerminalSymbolWidget;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidget
 */
export interface TerminalSymbolWidget {
    /**
     * 
     * @type {TerminalSymbolWidgetTrend}
     * @memberof TerminalSymbolWidget
     */
    trend?: TerminalSymbolWidgetTrend;
    /**
     * 
     * @type {TerminalSymbolWidgetSupportAndResistance}
     * @memberof TerminalSymbolWidget
     */
    supportAndResistance?: TerminalSymbolWidgetSupportAndResistance;
    /**
     * 
     * @type {TerminalSymbolWidgetSummaryScore}
     * @memberof TerminalSymbolWidget
     */
    summaryScore?: TerminalSymbolWidgetSummaryScore;
    /**
     * 
     * @type {TerminalSymbolWidgetBuzzing}
     * @memberof TerminalSymbolWidget
     */
    buzzing?: TerminalSymbolWidgetBuzzing;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidgetBuzzing
 */
export interface TerminalSymbolWidgetBuzzing {
    /**
     * 
     * @type {TradingCentralBuzzingType}
     * @memberof TerminalSymbolWidgetBuzzing
     */
    trend?: TradingCentralBuzzingType;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolWidgetBuzzing
     */
    mentionsVolume24?: number;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidgetSummaryScore
 */
export interface TerminalSymbolWidgetSummaryScore {
    /**
     * 
     * @type {TerminalSymbolWidgetSummaryScoreItem}
     * @memberof TerminalSymbolWidgetSummaryScore
     */
    scoreLong?: TerminalSymbolWidgetSummaryScoreItem;
    /**
     * 
     * @type {TerminalSymbolWidgetSummaryScoreItem}
     * @memberof TerminalSymbolWidgetSummaryScore
     */
    scoreShort?: TerminalSymbolWidgetSummaryScoreItem;
    /**
     * 
     * @type {TerminalSymbolWidgetSummaryScoreItem}
     * @memberof TerminalSymbolWidgetSummaryScore
     */
    scoreIntermediate?: TerminalSymbolWidgetSummaryScoreItem;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidgetSummaryScoreItem
 */
export interface TerminalSymbolWidgetSummaryScoreItem {
    /**
     * 
     * @type {TradingCentralSignalTrend}
     * @memberof TerminalSymbolWidgetSummaryScoreItem
     */
    trend?: TradingCentralSignalTrend;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolWidgetSummaryScoreItem
     */
    score?: number;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidgetSupportAndResistance
 */
export interface TerminalSymbolWidgetSupportAndResistance {
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolWidgetSupportAndResistance
     */
    support?: number;
    /**
     * 
     * @type {number}
     * @memberof TerminalSymbolWidgetSupportAndResistance
     */
    resistance?: number;
}
/**
 * 
 * @export
 * @interface TerminalSymbolWidgetTrend
 */
export interface TerminalSymbolWidgetTrend {
    /**
     * 
     * @type {TradingCentralSignalTrend}
     * @memberof TerminalSymbolWidgetTrend
     */
    trend?: TradingCentralSignalTrend;
    /**
     * 
     * @type {string}
     * @memberof TerminalSymbolWidgetTrend
     */
    dateNextCalculation?: string;
}
/**
 * 
 * @export
 * @interface TerminalSymbols
 */
export interface TerminalSymbols {
    /**
     * 
     * @type {Array<TerminalSymbol>}
     * @memberof TerminalSymbols
     */
    symbols?: Array<TerminalSymbol> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalSymbols
     */
    groups?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TerminalSymbolsUpdates
 */
export interface TerminalSymbolsUpdates {
    /**
     * 
     * @type {Array<TerminalSymbolUpdate>}
     * @memberof TerminalSymbolsUpdates
     */
    symbols?: Array<TerminalSymbolUpdate> | null;
}
/**
 * 
 * @export
 * @interface TerminalTradingCentralArticle
 */
export interface TerminalTradingCentralArticle {
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    date?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalTradingCentralArticle
     */
    images?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    publisher?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralArticle
     */
    url?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TerminalTradingCentralArticle
     */
    topics?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TerminalTradingCentralArticleItemsContainer
 */
export interface TerminalTradingCentralArticleItemsContainer {
    /**
     * 
     * @type {Array<TerminalTradingCentralArticle>}
     * @memberof TerminalTradingCentralArticleItemsContainer
     */
    items?: Array<TerminalTradingCentralArticle> | null;
    /**
     * 
     * @type {number}
     * @memberof TerminalTradingCentralArticleItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TerminalTradingCentralHandShake
 */
export interface TerminalTradingCentralHandShake {
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralHandShake
     */
    page?: string | null;
    /**
     * 
     * @type {TradingCentralTheme}
     * @memberof TerminalTradingCentralHandShake
     */
    theme?: TradingCentralTheme;
}
/**
 * 
 * @export
 * @interface TerminalTradingCentralWidgetCredentials
 */
export interface TerminalTradingCentralWidgetCredentials {
    /**
     * 
     * @type {string}
     * @memberof TerminalTradingCentralWidgetCredentials
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    subtitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    tooltip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    paragraph?: string | null;
}
/**
 * 
 * @export
 * @interface TickerChartPoint
 */
export interface TickerChartPoint {
    /**
     * 
     * @type {number}
     * @memberof TickerChartPoint
     */
    timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerChartPoint
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface TradingAccount
 */
export interface TradingAccount {
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    login?: number;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    dateCreate?: string;
    /**
     * 
     * @type {TradingAccountStatus}
     * @memberof TradingAccount
     */
    status?: TradingAccountStatus;
    /**
     * 
     * @type {TradingAccountTradeMode}
     * @memberof TradingAccount
     */
    tradeMode?: TradingAccountTradeMode;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    leverage?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    maxLeverage?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TradingAccount
     */
    leveragesAvailable?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    leverageAvailableDefault?: number;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    digits?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    equity?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    marginFree?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    credit?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    availableToWithdraw?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingAccount
     */
    pnL?: number;
    /**
     * 
     * @type {TradingAccountType}
     * @memberof TradingAccount
     */
    type?: TradingAccountType;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    typeTitle?: string | null;
    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof TradingAccount
     */
    platform?: TradingServerPlatform;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    platformTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    server?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverId?: string;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverMarketName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalWebUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalAndroidUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalIOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalMacOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalLinuxOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccount
     */
    serverTerminalPageUrl?: string | null;
    /**
     * 
     * @type {TradingAccountActions}
     * @memberof TradingAccount
     */
    actions?: TradingAccountActions;
}
/**
 * 
 * @export
 * @interface TradingAccountActions
 */
export interface TradingAccountActions {
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canTransferMoney?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canMakeDemoDeposit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canChangePwd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canChangeLeverage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canCloseAccount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canUseWebTerminal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canUseTerminal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canArchive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canRestore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingAccountActions
     */
    canTrade?: boolean;
}
/**
 * 
 * @export
 * @interface TradingAccountItemsContainer
 */
export interface TradingAccountItemsContainer {
    /**
     * 
     * @type {Array<TradingAccount>}
     * @memberof TradingAccountItemsContainer
     */
    items?: Array<TradingAccount> | null;
    /**
     * 
     * @type {number}
     * @memberof TradingAccountItemsContainer
     */
    total?: number;
}
/**
 * Причина выставления ордера
 * @export
 * @enum {string}
 */
export enum TradingAccountMT4OrderReason {
    Client = 'Client',
    Expert = 'Expert',
    Dealer = 'Dealer',
    Signal = 'Signal',
    Gateway = 'Gateway',
    Mobile = 'Mobile',
    Web = 'Web',
    Api = 'API'
}

/**
 * Типа операции ордера
 * @export
 * @enum {string}
 */
export enum TradingAccountMT4OrderType {
    Buy = 'Buy',
    Sell = 'Sell',
    BuyLimit = 'BuyLimit',
    SellLimit = 'SellLimit',
    BuyStop = 'BuyStop',
    SellStop = 'SellStop',
    Balance = 'Balance',
    Credit = 'Credit'
}

/**
 * Deal action
 * @export
 * @enum {string}
 */
export enum TradingAccountMT5DealAction {
    Buy = 'Buy',
    Sell = 'Sell',
    Balance = 'Balance',
    Credit = 'Credit',
    Charge = 'Charge',
    Correction = 'Correction',
    Bonus = 'Bonus',
    Commission = 'Commission',
    CommissionDaily = 'CommissionDaily',
    CommissionMonthly = 'CommissionMonthly',
    AgentDaily = 'AgentDaily',
    AgentMonthly = 'AgentMonthly',
    InterestRate = 'InterestRate',
    BuyCancelled = 'BuyCancelled',
    SellCancelled = 'SellCancelled',
    Dividend = 'Dividend',
    DividendFranked = 'DividendFranked',
    Tax = 'Tax',
    Agent = 'Agent',
    SoCompensation = 'SOCompensation',
    SoCompensationCredit = 'SOCompensationCredit'
}

/**
 * Deal entry
 * @export
 * @enum {string}
 */
export enum TradingAccountMT5DealEntry {
    In = 'In',
    Out = 'Out',
    InOut = 'InOut',
    OutBy = 'OutBy'
}

/**
 * Deal initiator
 * @export
 * @enum {string}
 */
export enum TradingAccountMT5DealInitiator {
    Default = 'Default',
    TerminalDoto = 'TerminalDoto',
    TerminalMt = 'TerminalMT',
    StopLoss = 'StopLoss',
    TakeProfit = 'TakeProfit',
    StopOut = 'StopOut',
    PartialClose = 'PartialClose'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingAccountMT5DealSignalType {
    None = 'None',
    Up = 'Up',
    StrongUp = 'StrongUp',
    StrongDown = 'StrongDown',
    Down = 'Down'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingAccountMT5DealVolumeOpenOriginalType {
    Lots = 'Lots',
    Margin = 'Margin',
    Currency = 'Currency'
}

/**
 * 
 * @export
 * @interface TradingAccountPwdRequest
 */
export interface TradingAccountPwdRequest {
    /**
     * 
     * @type {string}
     * @memberof TradingAccountPwdRequest
     */
    pwd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccountPwdRequest
     */
    twoFactorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingAccountPwdRequest
     */
    recoveryCode?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingAccountSorting {
    Newest = 'Newest',
    Oldest = 'Oldest',
    FreeMargin = 'FreeMargin',
    Name = 'Name',
    Last = 'Last'
}

/**
 * Статус торгового аккаунта
 * @export
 * @enum {string}
 */
export enum TradingAccountStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingAccountTradeMode {
    Lots = 'Lots',
    Margin = 'Margin'
}

/**
 * Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
 * @export
 * @enum {string}
 */
export enum TradingAccountType {
    Real = 'Real',
    Demo = 'Demo'
}

/**
 * 
 * @export
 * @interface TradingAccountsTotalBalance
 */
export interface TradingAccountsTotalBalance {
    /**
     * 
     * @type {number}
     * @memberof TradingAccountsTotalBalance
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TradingAccountsTotalBalance
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingCentralBuzzingType {
    NoNews = 'NoNews',
    BelowAverage = 'BelowAverage',
    Average = 'Average',
    AboveAverage = 'AboveAverage',
    Buzzing = 'Buzzing'
}

/**
 * 
 * @export
 * @interface TradingCentralInfo
 */
export interface TradingCentralInfo {
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isOverallMentionsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isNewsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isCalendarEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isSentimentEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isTechSummaryScoreEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isSignalsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradingCentralInfo
     */
    isTargetPriceEnabled?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingCentralSignalTrend {
    None = 'None',
    Bearish = 'Bearish',
    Bullish = 'Bullish',
    Neutral = 'Neutral'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingCentralSignalType {
    None = 'None',
    Up = 'Up',
    StrongUp = 'StrongUp',
    StrongDown = 'StrongDown',
    Down = 'Down'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingCentralTheme {
    Light = 'Light',
    Dark = 'Dark'
}

/**
 * 
 * @export
 * @interface TradingCondition
 */
export interface TradingCondition {
    /**
     * 
     * @type {string}
     * @memberof TradingCondition
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingCondition
     */
    ticker?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingCondition
     */
    group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingCondition
     */
    leverage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TradingCondition
     */
    minSpread?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TradingCondition
     */
    priceBid?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TradingCondition
     */
    priceChange24H?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TradingCondition
     */
    priceChange7D?: number | null;
    /**
     * 
     * @type {Array<TickerChartPoint>}
     * @memberof TradingCondition
     */
    tickerChart?: Array<TickerChartPoint> | null;
}
/**
 * 
 * @export
 * @interface TradingConditionItemsContainer
 */
export interface TradingConditionItemsContainer {
    /**
     * 
     * @type {Array<TradingCondition>}
     * @memberof TradingConditionItemsContainer
     */
    items?: Array<TradingCondition> | null;
    /**
     * 
     * @type {number}
     * @memberof TradingConditionItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TradingServer
 */
export interface TradingServer {
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    marketName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalWebUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalMacUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalLinuxUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalAndroidUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalIOsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    terminalPageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServer
     */
    platform?: string | null;
    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof TradingServer
     */
    platformType?: TradingServerPlatform;
    /**
     * 
     * @type {boolean}
     * @memberof TradingServer
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Array<TradingServerAccount>}
     * @memberof TradingServer
     */
    accounts?: Array<TradingServerAccount> | null;
}
/**
 * 
 * @export
 * @interface TradingServerAccount
 */
export interface TradingServerAccount {
    /**
     * 
     * @type {string}
     * @memberof TradingServerAccount
     */
    id?: string;
    /**
     * 
     * @type {TradingAccountType}
     * @memberof TradingServerAccount
     */
    type?: TradingAccountType;
    /**
     * 
     * @type {string}
     * @memberof TradingServerAccount
     */
    title?: string | null;
    /**
     * 
     * @type {object}
     * @memberof TradingServerAccount
     */
    options?: object;
    /**
     * 
     * @type {number}
     * @memberof TradingServerAccount
     */
    defaultLeverage?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TradingServerAccount
     */
    leverages?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TradingServerAccount
     */
    defaultCurrency?: string | null;
    /**
     * 
     * @type {Array<TradingServerAccountCurrency>}
     * @memberof TradingServerAccount
     */
    currenciesInfo?: Array<TradingServerAccountCurrency> | null;
}
/**
 * 
 * @export
 * @interface TradingServerAccountCurrency
 */
export interface TradingServerAccountCurrency {
    /**
     * 
     * @type {string}
     * @memberof TradingServerAccountCurrency
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @interface TradingServerItemsContainer
 */
export interface TradingServerItemsContainer {
    /**
     * 
     * @type {Array<TradingServer>}
     * @memberof TradingServerItemsContainer
     */
    items?: Array<TradingServer> | null;
    /**
     * 
     * @type {number}
     * @memberof TradingServerItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerPlatform {
    MetaTrader4 = 'MetaTrader4',
    MetaTrader5 = 'MetaTrader5'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerSymbolExecutionMode {
    Request = 'Request',
    Instant = 'Instant',
    Market = 'Market',
    Exchange = 'Exchange'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerSymbolExpirationFlags {
    None = 'None',
    Gtc = 'GTC',
    Day = 'Day',
    Specified = 'Specified',
    SpecifiedDay = 'SpecifiedDay',
    All = 'All'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerSymbolFillFlags {
    None = 'None',
    FillOrKill = 'FillOrKill',
    ImmediateOrCancel = 'ImmediateOrCancel',
    All = 'All'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerSymbolTradeMode {
    Disabled = 'Disabled',
    LongOnly = 'LongOnly',
    ShortOnly = 'ShortOnly',
    CloseOnly = 'CloseOnly',
    Full = 'Full'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradingServerSymbolType {
    Forex = 'Forex',
    Futures = 'Futures',
    Cfd = 'CFD',
    CfdIndex = 'CFD_Index',
    CfdLeverage = 'CFD_Leverage',
    ForexNoLeverage = 'ForexNoLeverage',
    ExchangeStocks = 'ExchangeStocks',
    ExchangeFutures = 'ExchangeFutures',
    ExchangeForts = 'ExchangeForts',
    ExchangeOptions = 'ExchangeOptions',
    ExchangeOptionsMargin = 'ExchangeOptionsMargin',
    ExchangeBonds = 'ExchangeBonds',
    Collateral = 'Collateral'
}

/**
 * 
 * @export
 * @interface TradingTerminalServer
 */
export interface TradingTerminalServer {
    /**
     * 
     * @type {string}
     * @memberof TradingTerminalServer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TradingTerminalServer
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface TradingTerminalServerItemsContainer
 */
export interface TradingTerminalServerItemsContainer {
    /**
     * 
     * @type {Array<TradingTerminalServer>}
     * @memberof TradingTerminalServerItemsContainer
     */
    items?: Array<TradingTerminalServer> | null;
    /**
     * 
     * @type {number}
     * @memberof TradingTerminalServerItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TradingTournament
 */
export interface TradingTournament {
    /**
     * 
     * @type {string}
     * @memberof TradingTournament
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TradingTournament
     */
    utmSource?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TradingTournament
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<TradingTournamentItem>}
     * @memberof TradingTournament
     */
    items?: Array<TradingTournamentItem> | null;
}
/**
 * 
 * @export
 * @interface TradingTournamentItem
 */
export interface TradingTournamentItem {
    /**
     * 
     * @type {string}
     * @memberof TradingTournamentItem
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TradingTournamentItem
     */
    login?: number;
    /**
     * 
     * @type {string}
     * @memberof TradingTournamentItem
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TradingTournamentItem
     */
    floatingPnl?: number;
    /**
     * 
     * @type {number}
     * @memberof TradingTournamentItem
     */
    rank?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TradingTournamentItem
     */
    isColored?: boolean;
}
/**
 * 
 * @export
 * @interface TwoFactor
 */
export interface TwoFactor {
    /**
     * 
     * @type {string}
     * @memberof TwoFactor
     */
    sharedKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactor
     */
    authenticatorUri?: string | null;
}
/**
 * 
 * @export
 * @interface TwoFactorConfirm
 */
export interface TwoFactorConfirm {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorConfirm
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorConfirm
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorConfirm
     */
    sharedKey?: string | null;
}
/**
 * 
 * @export
 * @interface TwoFactorDisableRequest
 */
export interface TwoFactorDisableRequest {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorDisableRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorDisableRequest
     */
    twoFactorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorDisableRequest
     */
    recoveryCode?: string | null;
}
/**
 * 
 * @export
 * @interface TwoFactorRecoveryCode
 */
export interface TwoFactorRecoveryCode {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRecoveryCode
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TwoFactorRecoveryCode
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface TwoFactorRecoveryCodeItemsContainer
 */
export interface TwoFactorRecoveryCodeItemsContainer {
    /**
     * 
     * @type {Array<TwoFactorRecoveryCode>}
     * @memberof TwoFactorRecoveryCodeItemsContainer
     */
    items?: Array<TwoFactorRecoveryCode> | null;
    /**
     * 
     * @type {number}
     * @memberof TwoFactorRecoveryCodeItemsContainer
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TwoFactorResult
 */
export interface TwoFactorResult {
    /**
     * 
     * @type {Array<TwoFactorRecoveryCode>}
     * @memberof TwoFactorResult
     */
    codes?: Array<TwoFactorRecoveryCode> | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorResult
     */
    authToken?: string | null;
}
/**
 * 
 * @export
 * @interface TwoFactorStatus
 */
export interface TwoFactorStatus {
    /**
     * 
     * @type {boolean}
     * @memberof TwoFactorStatus
     */
    twoFactorEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface UiAmountsInfo
 */
export interface UiAmountsInfo {
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof UiAmountsInfo
     */
    amounts?: { [key: string]: Array<number>; } | null;
}
/**
 * 
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * 
     * @type {Regexp}
     * @memberof Validation
     */
    regexp?: Regexp;
}
/**
 * 
 * @export
 * @interface ValidationInfo
 */
export interface ValidationInfo {
    /**
     * 
     * @type {ValidationUserInfo}
     * @memberof ValidationInfo
     */
    user?: ValidationUserInfo;
}
/**
 * 
 * @export
 * @interface ValidationUserInfo
 */
export interface ValidationUserInfo {
    /**
     * 
     * @type {number}
     * @memberof ValidationUserInfo
     */
    ageMin?: number;
}
/**
 * 
 * @export
 * @interface VersionInfo
 */
export interface VersionInfo {
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    mobileMinVersion?: string | null;
}
/**
 * 
 * @export
 * @interface Widget
 */
export interface Widget {
    /**
     * 
     * @type {Action}
     * @memberof Widget
     */
    action?: Action;
    /**
     * 
     * @type {Array<any>}
     * @memberof Widget
     */
    children?: Array<any> | null;
    /**
     * Т.к. всё может быть виджетом, в том числе и кнопка, то в этом поле тип самого объекта: виджет, кнопка, текст
     * @type {string}
     * @memberof Widget
     */
    type?: string | null;
    /**
     * 
     * @type {IconType}
     * @memberof Widget
     */
    icon?: IconType;
    /**
     * 
     * @type {Text}
     * @memberof Widget
     */
    text?: Text;
}
/**
 * 
 * @export
 * @interface WithdrawField
 */
export interface WithdrawField {
    /**
     * Id of a field, for example \"purse\"
     * @type {string}
     * @memberof WithdrawField
     */
    id?: string | null;
    /**
     * Value of field
     * @type {string}
     * @memberof WithdrawField
     */
    value?: string | null;
}
/**
 * Additional withdraw data
 * @export
 * @interface WithdrawRequestPayload
 */
export interface WithdrawRequestPayload {
    /**
     * Fields that user has to enter to make withdraw
     * @type {Array<WithdrawField>}
     * @memberof WithdrawRequestPayload
     */
    fields?: Array<WithdrawField> | null;
}
/**
 * 
 * @export
 * @interface WithdrawResult
 */
export interface WithdrawResult {
    /**
     * 
     * @type {string}
     * @memberof WithdrawResult
     */
    transferTransactionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WithdrawResult
     */
    error?: string | null;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountInvestorPassword: async (id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountInvestorPassword', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/password/investor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradingAccountPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountLeverage: async (id: string, integerContainer?: IntegerContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountLeverage', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/leverage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integerContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountMainPassword: async (id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountMainPassword', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/password/main`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradingAccountPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountTradeMode: async (id: string, tradeMode?: TradingAccountTradeMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeAccountTradeMode', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/trade-mode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tradeMode !== undefined) {
                localVarQueryParameter['tradeMode'] = tradeMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradingAccount: async (createTradingAccountRequest?: CreateTradingAccountRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTradingAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultAccount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAccount: async (status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/last`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (platform !== undefined) {
                localVarQueryParameter['Platform'] = platform;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4OpenOrders: async (id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT4OpenOrders', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt4/orders/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (openFrom !== undefined) {
                localVarQueryParameter['OpenFrom'] = (openFrom as any instanceof Date) ?
                    (openFrom as any).toISOString() :
                    openFrom;
            }

            if (openTo !== undefined) {
                localVarQueryParameter['OpenTo'] = (openTo as any instanceof Date) ?
                    (openTo as any).toISOString() :
                    openTo;
            }

            if (closeFrom !== undefined) {
                localVarQueryParameter['CloseFrom'] = (closeFrom as any instanceof Date) ?
                    (closeFrom as any).toISOString() :
                    closeFrom;
            }

            if (closeTo !== undefined) {
                localVarQueryParameter['CloseTo'] = (closeTo as any instanceof Date) ?
                    (closeTo as any).toISOString() :
                    closeTo;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4Orders: async (id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT4Orders', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt4/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (openFrom !== undefined) {
                localVarQueryParameter['OpenFrom'] = (openFrom as any instanceof Date) ?
                    (openFrom as any).toISOString() :
                    openFrom;
            }

            if (openTo !== undefined) {
                localVarQueryParameter['OpenTo'] = (openTo as any instanceof Date) ?
                    (openTo as any).toISOString() :
                    openTo;
            }

            if (closeFrom !== undefined) {
                localVarQueryParameter['CloseFrom'] = (closeFrom as any instanceof Date) ?
                    (closeFrom as any).toISOString() :
                    closeFrom;
            }

            if (closeTo !== undefined) {
                localVarQueryParameter['CloseTo'] = (closeTo as any instanceof Date) ?
                    (closeTo as any).toISOString() :
                    closeTo;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Deals: async (id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT5Deals', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt5/deals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Positions: async (id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMT5Positions', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/mt5/positions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMTBalanceOperations: async (id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMTBalanceOperations', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/operations/balance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/servers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalServers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/servers/terminal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTradingAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccounts: async (status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (platform !== undefined) {
                localVarQueryParameter['Platform'] = platform;
            }

            if (isTerminalEnabledOnly !== undefined) {
                localVarQueryParameter['IsTerminalEnabledOnly'] = isTerminalEnabledOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccountsTotalBalance: async (currency?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/accounts/balance/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDemoDeposit: async (id: string, decimalContainer?: DecimalContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makeDemoDeposit', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/demo/deposit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decimalContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreAccount', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastAction: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLastAction', 'id', id)
            const localVarPath = `/v1.0/accounts/{id}/last`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountInvestorPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountInvestorPassword(id, tradingAccountPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountLeverage(id: string, integerContainer?: IntegerContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountLeverage(id, integerContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountMainPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountMainPassword(id, tradingAccountPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountTradeMode(id: string, tradeMode?: TradingAccountTradeMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountTradeMode(id, tradeMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTradingAccount(createTradingAccountRequest?: CreateTradingAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTradingAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTradingAccount(createTradingAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultAccount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDefaultResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastAccount(status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastAccount(status, type, platform, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT4OpenOrders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader4OrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT4OpenOrders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT4Orders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader4OrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT4Orders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT5Deals(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader5DealItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT5Deals(id, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMT5Positions(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTrader5PositionItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMT5Positions(id, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMTBalanceOperations(id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaTraderBalanceOperationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMTBalanceOperations(id, dateFrom, dateTo, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingServerItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalServers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingTerminalServerItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalServers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccounts(status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccounts(status, sorting, type, platform, isTerminalEnabledOnly, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingAccountsTotalBalance(currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingAccountsTotalBalance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingAccountsTotalBalance(currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDemoDeposit(id: string, decimalContainer?: DecimalContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDemoDeposit(id, decimalContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastAction(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastAction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account investor password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountInvestorPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountInvestorPassword(id, tradingAccountPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account leverage
         * @param {string} id 
         * @param {IntegerContainer} [integerContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountLeverage(id: string, integerContainer?: IntegerContainer, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountLeverage(id, integerContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account main password
         * @param {string} id 
         * @param {TradingAccountPwdRequest} [tradingAccountPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountMainPassword(id: string, tradingAccountPwdRequest?: TradingAccountPwdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountMainPassword(id, tradingAccountPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change account trade mode
         * @param {string} id 
         * @param {TradingAccountTradeMode} [tradeMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountTradeMode(id: string, tradeMode?: TradingAccountTradeMode, options?: any): AxiosPromise<void> {
            return localVarFp.changeAccountTradeMode(id, tradeMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create trading account
         * @param {CreateTradingAccountRequest} [createTradingAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradingAccount(createTradingAccountRequest?: CreateTradingAccountRequest, options?: any): AxiosPromise<CreateTradingAccountResult> {
            return localVarFp.createTradingAccount(createTradingAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Default account (use `last` instead)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultAccount(options?: any): AxiosPromise<GetDefaultResult> {
            return localVarFp.getDefaultAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Last account
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAccount(status?: AvailabilityStatus, type?: TradingAccountType, platform?: TradingServerPlatform, options?: any): AxiosPromise<TradingAccount> {
            return localVarFp.getLastAccount(status, type, platform, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT4 open orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4OpenOrders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader4OrderItemsContainer> {
            return localVarFp.getMT4OpenOrders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT4 orders
         * @param {string} id 
         * @param {string} [openFrom] 
         * @param {string} [openTo] 
         * @param {string} [closeFrom] 
         * @param {string} [closeTo] 
         * @param {string} [symbol] 
         * @param {MetaTrader4OrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT4Orders(id: string, openFrom?: string, openTo?: string, closeFrom?: string, closeTo?: string, symbol?: string, sorting?: MetaTrader4OrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader4OrderItemsContainer> {
            return localVarFp.getMT4Orders(id, openFrom, openTo, closeFrom, closeTo, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT5 deals
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5DealSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Deals(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5DealSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader5DealItemsContainer> {
            return localVarFp.getMT5Deals(id, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account MT5 positions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {MetaTrader5PositionSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMT5Positions(id: string, from?: string, to?: string, symbol?: string, sorting?: MetaTrader5PositionSorting, skip?: number, take?: number, options?: any): AxiosPromise<MetaTrader5PositionItemsContainer> {
            return localVarFp.getMT5Positions(id, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account balance operations
         * @param {string} id 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMTBalanceOperations(id: string, dateFrom?: string, dateTo?: string, skip?: number, take?: number, options?: any): AxiosPromise<MetaTraderBalanceOperationItemsContainer> {
            return localVarFp.getMTBalanceOperations(id, dateFrom, dateTo, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServers(options?: any): AxiosPromise<TradingServerItemsContainer> {
            return localVarFp.getServers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get terminal trading servers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalServers(options?: any): AxiosPromise<TradingTerminalServerItemsContainer> {
            return localVarFp.getTerminalServers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccount(id: string, options?: any): AxiosPromise<TradingAccount> {
            return localVarFp.getTradingAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading accounts
         * @param {AvailabilityStatus} [status] 
         * @param {TradingAccountSorting} [sorting] 
         * @param {TradingAccountType} [type] Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
         * @param {TradingServerPlatform} [platform] 
         * @param {boolean} [isTerminalEnabledOnly] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccounts(status?: AvailabilityStatus, sorting?: TradingAccountSorting, type?: TradingAccountType, platform?: TradingServerPlatform, isTerminalEnabledOnly?: boolean, skip?: number, take?: number, options?: any): AxiosPromise<TradingAccountItemsContainer> {
            return localVarFp.getTradingAccounts(status, sorting, type, platform, isTerminalEnabledOnly, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading accounts total balance in USD
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingAccountsTotalBalance(currency?: string, options?: any): AxiosPromise<TradingAccountsTotalBalance> {
            return localVarFp.getTradingAccountsTotalBalance(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make demo deposit
         * @param {string} id 
         * @param {DecimalContainer} [decimalContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDemoDeposit(id: string, decimalContainer?: DecimalContainer, options?: any): AxiosPromise<void> {
            return localVarFp.makeDemoDeposit(id, decimalContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.restoreAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account last action
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastAction(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateLastAction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for archiveAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiArchiveAccountRequest
 */
export interface AccountsApiArchiveAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiArchiveAccount
     */
    readonly id: string
}

/**
 * Request parameters for changeAccountInvestorPassword operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountInvestorPasswordRequest
 */
export interface AccountsApiChangeAccountInvestorPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountInvestorPassword
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountPwdRequest}
     * @memberof AccountsApiChangeAccountInvestorPassword
     */
    readonly tradingAccountPwdRequest?: TradingAccountPwdRequest
}

/**
 * Request parameters for changeAccountLeverage operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountLeverageRequest
 */
export interface AccountsApiChangeAccountLeverageRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountLeverage
     */
    readonly id: string

    /**
     * 
     * @type {IntegerContainer}
     * @memberof AccountsApiChangeAccountLeverage
     */
    readonly integerContainer?: IntegerContainer
}

/**
 * Request parameters for changeAccountMainPassword operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountMainPasswordRequest
 */
export interface AccountsApiChangeAccountMainPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountMainPassword
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountPwdRequest}
     * @memberof AccountsApiChangeAccountMainPassword
     */
    readonly tradingAccountPwdRequest?: TradingAccountPwdRequest
}

/**
 * Request parameters for changeAccountTradeMode operation in AccountsApi.
 * @export
 * @interface AccountsApiChangeAccountTradeModeRequest
 */
export interface AccountsApiChangeAccountTradeModeRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiChangeAccountTradeMode
     */
    readonly id: string

    /**
     * 
     * @type {TradingAccountTradeMode}
     * @memberof AccountsApiChangeAccountTradeMode
     */
    readonly tradeMode?: TradingAccountTradeMode
}

/**
 * Request parameters for createTradingAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiCreateTradingAccountRequest
 */
export interface AccountsApiCreateTradingAccountRequest {
    /**
     * 
     * @type {CreateTradingAccountRequest}
     * @memberof AccountsApiCreateTradingAccount
     */
    readonly createTradingAccountRequest?: CreateTradingAccountRequest
}

/**
 * Request parameters for getLastAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiGetLastAccountRequest
 */
export interface AccountsApiGetLastAccountRequest {
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof AccountsApiGetLastAccount
     */
    readonly status?: AvailabilityStatus

    /**
     * Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
     * @type {TradingAccountType}
     * @memberof AccountsApiGetLastAccount
     */
    readonly type?: TradingAccountType

    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof AccountsApiGetLastAccount
     */
    readonly platform?: TradingServerPlatform
}

/**
 * Request parameters for getMT4OpenOrders operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT4OpenOrdersRequest
 */
export interface AccountsApiGetMT4OpenOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly openFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly openTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly closeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly closeTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader4OrderSorting}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly sorting?: MetaTrader4OrderSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4OpenOrders
     */
    readonly take?: number
}

/**
 * Request parameters for getMT4Orders operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT4OrdersRequest
 */
export interface AccountsApiGetMT4OrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly openFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly openTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly closeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly closeTo?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader4OrderSorting}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly sorting?: MetaTrader4OrderSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT4Orders
     */
    readonly take?: number
}

/**
 * Request parameters for getMT5Deals operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT5DealsRequest
 */
export interface AccountsApiGetMT5DealsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader5DealSorting}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly sorting?: MetaTrader5DealSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Deals
     */
    readonly take?: number
}

/**
 * Request parameters for getMT5Positions operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMT5PositionsRequest
 */
export interface AccountsApiGetMT5PositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly symbol?: string

    /**
     * 
     * @type {MetaTrader5PositionSorting}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly sorting?: MetaTrader5PositionSorting

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMT5Positions
     */
    readonly take?: number
}

/**
 * Request parameters for getMTBalanceOperations operation in AccountsApi.
 * @export
 * @interface AccountsApiGetMTBalanceOperationsRequest
 */
export interface AccountsApiGetMTBalanceOperationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly dateTo?: string

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetMTBalanceOperations
     */
    readonly take?: number
}

/**
 * Request parameters for getTradingAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountRequest
 */
export interface AccountsApiGetTradingAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetTradingAccount
     */
    readonly id: string
}

/**
 * Request parameters for getTradingAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountsRequest
 */
export interface AccountsApiGetTradingAccountsRequest {
    /**
     * 
     * @type {AvailabilityStatus}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly status?: AvailabilityStatus

    /**
     * 
     * @type {TradingAccountSorting}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly sorting?: TradingAccountSorting

    /**
     * Тип аккаунта торговорго аккаунта (пользователя или торгового сервера)
     * @type {TradingAccountType}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly type?: TradingAccountType

    /**
     * 
     * @type {TradingServerPlatform}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly platform?: TradingServerPlatform

    /**
     * 
     * @type {boolean}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly isTerminalEnabledOnly?: boolean

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountsApiGetTradingAccounts
     */
    readonly take?: number
}

/**
 * Request parameters for getTradingAccountsTotalBalance operation in AccountsApi.
 * @export
 * @interface AccountsApiGetTradingAccountsTotalBalanceRequest
 */
export interface AccountsApiGetTradingAccountsTotalBalanceRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiGetTradingAccountsTotalBalance
     */
    readonly currency?: string
}

/**
 * Request parameters for makeDemoDeposit operation in AccountsApi.
 * @export
 * @interface AccountsApiMakeDemoDepositRequest
 */
export interface AccountsApiMakeDemoDepositRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiMakeDemoDeposit
     */
    readonly id: string

    /**
     * 
     * @type {DecimalContainer}
     * @memberof AccountsApiMakeDemoDeposit
     */
    readonly decimalContainer?: DecimalContainer
}

/**
 * Request parameters for restoreAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiRestoreAccountRequest
 */
export interface AccountsApiRestoreAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiRestoreAccount
     */
    readonly id: string
}

/**
 * Request parameters for updateLastAction operation in AccountsApi.
 * @export
 * @interface AccountsApiUpdateLastActionRequest
 */
export interface AccountsApiUpdateLastActionRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiUpdateLastAction
     */
    readonly id: string
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Archive account
     * @param {AccountsApiArchiveAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public archiveAccount(requestParameters: AccountsApiArchiveAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).archiveAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account investor password
     * @param {AccountsApiChangeAccountInvestorPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountInvestorPassword(requestParameters: AccountsApiChangeAccountInvestorPasswordRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountInvestorPassword(requestParameters.id, requestParameters.tradingAccountPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account leverage
     * @param {AccountsApiChangeAccountLeverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountLeverage(requestParameters: AccountsApiChangeAccountLeverageRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountLeverage(requestParameters.id, requestParameters.integerContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account main password
     * @param {AccountsApiChangeAccountMainPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountMainPassword(requestParameters: AccountsApiChangeAccountMainPasswordRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountMainPassword(requestParameters.id, requestParameters.tradingAccountPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change account trade mode
     * @param {AccountsApiChangeAccountTradeModeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changeAccountTradeMode(requestParameters: AccountsApiChangeAccountTradeModeRequest, options?: any) {
        return AccountsApiFp(this.configuration).changeAccountTradeMode(requestParameters.id, requestParameters.tradeMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create trading account
     * @param {AccountsApiCreateTradingAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createTradingAccount(requestParameters: AccountsApiCreateTradingAccountRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).createTradingAccount(requestParameters.createTradingAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Default account (use `last` instead)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getDefaultAccount(options?: any) {
        return AccountsApiFp(this.configuration).getDefaultAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Last account
     * @param {AccountsApiGetLastAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getLastAccount(requestParameters: AccountsApiGetLastAccountRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getLastAccount(requestParameters.status, requestParameters.type, requestParameters.platform, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT4 open orders
     * @param {AccountsApiGetMT4OpenOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT4OpenOrders(requestParameters: AccountsApiGetMT4OpenOrdersRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT4OpenOrders(requestParameters.id, requestParameters.openFrom, requestParameters.openTo, requestParameters.closeFrom, requestParameters.closeTo, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT4 orders
     * @param {AccountsApiGetMT4OrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT4Orders(requestParameters: AccountsApiGetMT4OrdersRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT4Orders(requestParameters.id, requestParameters.openFrom, requestParameters.openTo, requestParameters.closeFrom, requestParameters.closeTo, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT5 deals
     * @param {AccountsApiGetMT5DealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT5Deals(requestParameters: AccountsApiGetMT5DealsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT5Deals(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account MT5 positions
     * @param {AccountsApiGetMT5PositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMT5Positions(requestParameters: AccountsApiGetMT5PositionsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMT5Positions(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account balance operations
     * @param {AccountsApiGetMTBalanceOperationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getMTBalanceOperations(requestParameters: AccountsApiGetMTBalanceOperationsRequest, options?: any) {
        return AccountsApiFp(this.configuration).getMTBalanceOperations(requestParameters.id, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading servers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getServers(options?: any) {
        return AccountsApiFp(this.configuration).getServers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get terminal trading servers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTerminalServers(options?: any) {
        return AccountsApiFp(this.configuration).getTerminalServers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading account
     * @param {AccountsApiGetTradingAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccount(requestParameters: AccountsApiGetTradingAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading accounts
     * @param {AccountsApiGetTradingAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccounts(requestParameters: AccountsApiGetTradingAccountsRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccounts(requestParameters.status, requestParameters.sorting, requestParameters.type, requestParameters.platform, requestParameters.isTerminalEnabledOnly, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading accounts total balance in USD
     * @param {AccountsApiGetTradingAccountsTotalBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getTradingAccountsTotalBalance(requestParameters: AccountsApiGetTradingAccountsTotalBalanceRequest = {}, options?: any) {
        return AccountsApiFp(this.configuration).getTradingAccountsTotalBalance(requestParameters.currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make demo deposit
     * @param {AccountsApiMakeDemoDepositRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public makeDemoDeposit(requestParameters: AccountsApiMakeDemoDepositRequest, options?: any) {
        return AccountsApiFp(this.configuration).makeDemoDeposit(requestParameters.id, requestParameters.decimalContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore account
     * @param {AccountsApiRestoreAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public restoreAccount(requestParameters: AccountsApiRestoreAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).restoreAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account last action
     * @param {AccountsApiUpdateLastActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateLastAction(requestParameters: AccountsApiUpdateLastActionRequest, options?: any) {
        return AccountsApiFp(this.configuration).updateLastAction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (email?: string, logInRequest?: LogInRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {AppleSignInRequest} [appleSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingApple: async (appleSignInRequest?: AppleSignInRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appleSignInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingFacebook: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/facebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleCode: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleJwt: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/google/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (passwordChangeRequest?: PasswordChangeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (userId?: string, code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailWithResetPwd: async (confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/confirm/pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmSignUpWithResetPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTwoStepAuth: async (twoFactorConfirm?: TwoFactorConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorConfirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuthRecoveryCodes: async (passwordRequest?: PasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/recoverycodes/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTwoStepAuth: async (twoFactorDisableRequest?: TwoFactorDisableRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorDisableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeAppleInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthRecoveryCodes: async (passwordRequest?: PasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/recoverycodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFromAnotherDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (email?: string, signUpRequest?: SignUpRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationLink: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/link/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByLink: async (passwordResetRequest?: PasswordResetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecoveryPasswordLink: async (passwordForgotRequest?: PasswordForgotRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordForgotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(email?: string, logInRequest?: LogInRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(email, logInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {AppleSignInRequest} [appleSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingApple(appleSignInRequest?: AppleSignInRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingApple(appleSignInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingFacebook(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingFacebook(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingGoogleCode(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingGoogleCode(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingGoogleJwt(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingGoogleJwt(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(passwordChangeRequest?: PasswordChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(passwordChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(userId?: string, code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(userId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmTwoStepAuth(twoFactorConfirm?: TwoFactorConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmTwoStepAuth(twoFactorConfirm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTwoStepAuth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTwoStepAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorRecoveryCodeItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTwoStepAuthRecoveryCodes(passwordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableTwoStepAuth(twoFactorDisableRequest?: TwoFactorDisableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableTwoStepAuth(twoFactorDisableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizeAppleInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizeAppleInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorRecoveryCodeItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoStepAuthRecoveryCodes(passwordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoStepAuthStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoStepAuthStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutFromAnotherDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutFromAnotherDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(email?: string, signUpRequest?: SignUpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(email, signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendConfirmationLink(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendConfirmationLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordByLink(passwordResetRequest?: PasswordResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordByLink(passwordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRecoveryPasswordLink(passwordForgotRequest?: PasswordForgotRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRecoveryPasswordLink(passwordForgotRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(email?: string, logInRequest?: LogInRequest, options?: any): AxiosPromise<string> {
            return localVarFp.authorize(email, logInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {AppleSignInRequest} [appleSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingApple(appleSignInRequest?: AppleSignInRequest, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingApple(appleSignInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingFacebook(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingFacebook(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleCode(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingGoogleCode(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleJwt(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingGoogleJwt(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordChangeRequest?: PasswordChangeRequest, options?: any): AxiosPromise<string> {
            return localVarFp.changePassword(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(userId?: string, code?: string, options?: any): AxiosPromise<string> {
            return localVarFp.confirmEmail(userId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options?: any): AxiosPromise<string> {
            return localVarFp.confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTwoStepAuth(twoFactorConfirm?: TwoFactorConfirm, options?: any): AxiosPromise<TwoFactorResult> {
            return localVarFp.confirmTwoStepAuth(twoFactorConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuth(options?: any): AxiosPromise<TwoFactor> {
            return localVarFp.createTwoStepAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): AxiosPromise<TwoFactorRecoveryCodeItemsContainer> {
            return localVarFp.createTwoStepAuthRecoveryCodes(passwordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTwoStepAuth(twoFactorDisableRequest?: TwoFactorDisableRequest, options?: any): AxiosPromise<void> {
            return localVarFp.disableTwoStepAuth(twoFactorDisableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeAppleInfo(options?: any): AxiosPromise<AppleAuthInfo> {
            return localVarFp.getAuthorizeAppleInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): AxiosPromise<TwoFactorRecoveryCodeItemsContainer> {
            return localVarFp.getTwoStepAuthRecoveryCodes(passwordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthStatus(options?: any): AxiosPromise<TwoFactorStatus> {
            return localVarFp.getTwoStepAuthStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFromAnotherDevices(options?: any): AxiosPromise<string> {
            return localVarFp.logoutFromAnotherDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(email?: string, signUpRequest?: SignUpRequest, options?: any): AxiosPromise<string> {
            return localVarFp.register(email, signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationLink(options?: any): AxiosPromise<void> {
            return localVarFp.resendConfirmationLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByLink(passwordResetRequest?: PasswordResetRequest, options?: any): AxiosPromise<string> {
            return localVarFp.resetPasswordByLink(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecoveryPasswordLink(passwordForgotRequest?: PasswordForgotRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendRecoveryPasswordLink(passwordForgotRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorize operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeRequest
 */
export interface AuthApiAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthorize
     */
    readonly email?: string

    /**
     * 
     * @type {LogInRequest}
     * @memberof AuthApiAuthorize
     */
    readonly logInRequest?: LogInRequest
}

/**
 * Request parameters for authorizeUsingApple operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingAppleRequest
 */
export interface AuthApiAuthorizeUsingAppleRequest {
    /**
     * 
     * @type {AppleSignInRequest}
     * @memberof AuthApiAuthorizeUsingApple
     */
    readonly appleSignInRequest?: AppleSignInRequest
}

/**
 * Request parameters for authorizeUsingFacebook operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingFacebookRequest
 */
export interface AuthApiAuthorizeUsingFacebookRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingFacebook
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for authorizeUsingGoogleCode operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingGoogleCodeRequest
 */
export interface AuthApiAuthorizeUsingGoogleCodeRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingGoogleCode
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for authorizeUsingGoogleJwt operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingGoogleJwtRequest
 */
export interface AuthApiAuthorizeUsingGoogleJwtRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingGoogleJwt
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for changePassword operation in AuthApi.
 * @export
 * @interface AuthApiChangePasswordRequest
 */
export interface AuthApiChangePasswordRequest {
    /**
     * 
     * @type {PasswordChangeRequest}
     * @memberof AuthApiChangePassword
     */
    readonly passwordChangeRequest?: PasswordChangeRequest
}

/**
 * Request parameters for confirmEmail operation in AuthApi.
 * @export
 * @interface AuthApiConfirmEmailRequest
 */
export interface AuthApiConfirmEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiConfirmEmail
     */
    readonly userId?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiConfirmEmail
     */
    readonly code?: string
}

/**
 * Request parameters for confirmEmailWithResetPwd operation in AuthApi.
 * @export
 * @interface AuthApiConfirmEmailWithResetPwdRequest
 */
export interface AuthApiConfirmEmailWithResetPwdRequest {
    /**
     * 
     * @type {ConfirmSignUpWithResetPwdRequest}
     * @memberof AuthApiConfirmEmailWithResetPwd
     */
    readonly confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest
}

/**
 * Request parameters for confirmTwoStepAuth operation in AuthApi.
 * @export
 * @interface AuthApiConfirmTwoStepAuthRequest
 */
export interface AuthApiConfirmTwoStepAuthRequest {
    /**
     * 
     * @type {TwoFactorConfirm}
     * @memberof AuthApiConfirmTwoStepAuth
     */
    readonly twoFactorConfirm?: TwoFactorConfirm
}

/**
 * Request parameters for createTwoStepAuthRecoveryCodes operation in AuthApi.
 * @export
 * @interface AuthApiCreateTwoStepAuthRecoveryCodesRequest
 */
export interface AuthApiCreateTwoStepAuthRecoveryCodesRequest {
    /**
     * 
     * @type {PasswordRequest}
     * @memberof AuthApiCreateTwoStepAuthRecoveryCodes
     */
    readonly passwordRequest?: PasswordRequest
}

/**
 * Request parameters for disableTwoStepAuth operation in AuthApi.
 * @export
 * @interface AuthApiDisableTwoStepAuthRequest
 */
export interface AuthApiDisableTwoStepAuthRequest {
    /**
     * 
     * @type {TwoFactorDisableRequest}
     * @memberof AuthApiDisableTwoStepAuth
     */
    readonly twoFactorDisableRequest?: TwoFactorDisableRequest
}

/**
 * Request parameters for getTwoStepAuthRecoveryCodes operation in AuthApi.
 * @export
 * @interface AuthApiGetTwoStepAuthRecoveryCodesRequest
 */
export interface AuthApiGetTwoStepAuthRecoveryCodesRequest {
    /**
     * 
     * @type {PasswordRequest}
     * @memberof AuthApiGetTwoStepAuthRecoveryCodes
     */
    readonly passwordRequest?: PasswordRequest
}

/**
 * Request parameters for register operation in AuthApi.
 * @export
 * @interface AuthApiRegisterRequest
 */
export interface AuthApiRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiRegister
     */
    readonly email?: string

    /**
     * 
     * @type {SignUpRequest}
     * @memberof AuthApiRegister
     */
    readonly signUpRequest?: SignUpRequest
}

/**
 * Request parameters for resetPasswordByLink operation in AuthApi.
 * @export
 * @interface AuthApiResetPasswordByLinkRequest
 */
export interface AuthApiResetPasswordByLinkRequest {
    /**
     * 
     * @type {PasswordResetRequest}
     * @memberof AuthApiResetPasswordByLink
     */
    readonly passwordResetRequest?: PasswordResetRequest
}

/**
 * Request parameters for sendRecoveryPasswordLink operation in AuthApi.
 * @export
 * @interface AuthApiSendRecoveryPasswordLinkRequest
 */
export interface AuthApiSendRecoveryPasswordLinkRequest {
    /**
     * 
     * @type {PasswordForgotRequest}
     * @memberof AuthApiSendRecoveryPasswordLink
     */
    readonly passwordForgotRequest?: PasswordForgotRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Authorize
     * @param {AuthApiAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorize(requestParameters: AuthApiAuthorizeRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorize(requestParameters.email, requestParameters.logInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Apple
     * @param {AuthApiAuthorizeUsingAppleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingApple(requestParameters: AuthApiAuthorizeUsingAppleRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingApple(requestParameters.appleSignInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Facebook
     * @param {AuthApiAuthorizeUsingFacebookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingFacebook(requestParameters: AuthApiAuthorizeUsingFacebookRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingFacebook(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Google
     * @param {AuthApiAuthorizeUsingGoogleCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingGoogleCode(requestParameters: AuthApiAuthorizeUsingGoogleCodeRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingGoogleCode(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Google JWT
     * @param {AuthApiAuthorizeUsingGoogleJwtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingGoogleJwt(requestParameters: AuthApiAuthorizeUsingGoogleJwtRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingGoogleJwt(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change password
     * @param {AuthApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(requestParameters: AuthApiChangePasswordRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).changePassword(requestParameters.passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm email after registration
     * @param {AuthApiConfirmEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmEmail(requestParameters: AuthApiConfirmEmailRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmEmail(requestParameters.userId, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm email after registration with reset password
     * @param {AuthApiConfirmEmailWithResetPwdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmEmailWithResetPwd(requestParameters: AuthApiConfirmEmailWithResetPwdRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmEmailWithResetPwd(requestParameters.confirmSignUpWithResetPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA confirm
     * @param {AuthApiConfirmTwoStepAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmTwoStepAuth(requestParameters: AuthApiConfirmTwoStepAuthRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmTwoStepAuth(requestParameters.twoFactorConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createTwoStepAuth(options?: any) {
        return AuthApiFp(this.configuration).createTwoStepAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA generate new recovery codes
     * @param {AuthApiCreateTwoStepAuthRecoveryCodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createTwoStepAuthRecoveryCodes(requestParameters: AuthApiCreateTwoStepAuthRecoveryCodesRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).createTwoStepAuthRecoveryCodes(requestParameters.passwordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA disable
     * @param {AuthApiDisableTwoStepAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public disableTwoStepAuth(requestParameters: AuthApiDisableTwoStepAuthRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).disableTwoStepAuth(requestParameters.twoFactorDisableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Apple info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthorizeAppleInfo(options?: any) {
        return AuthApiFp(this.configuration).getAuthorizeAppleInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA recovery codes
     * @param {AuthApiGetTwoStepAuthRecoveryCodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTwoStepAuthRecoveryCodes(requestParameters: AuthApiGetTwoStepAuthRecoveryCodesRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).getTwoStepAuthRecoveryCodes(requestParameters.passwordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTwoStepAuthStatus(options?: any) {
        return AuthApiFp(this.configuration).getTwoStepAuthStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout from another devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutFromAnotherDevices(options?: any) {
        return AuthApiFp(this.configuration).logoutFromAnotherDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary New registration
     * @param {AuthApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(requestParameters: AuthApiRegisterRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).register(requestParameters.email, requestParameters.signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend confirmation link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendConfirmationLink(options?: any) {
        return AuthApiFp(this.configuration).resendConfirmationLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password by link
     * @param {AuthApiResetPasswordByLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPasswordByLink(requestParameters: AuthApiResetPasswordByLinkRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).resetPasswordByLink(requestParameters.passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send reset password link
     * @param {AuthApiSendRecoveryPasswordLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendRecoveryPasswordLink(requestParameters: AuthApiSendRecoveryPasswordLinkRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).sendRecoveryPasswordLink(requestParameters.passwordForgotRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannersApi - axios parameter creator
 * @export
 */
export const BannersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hide banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideBanner: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hideBanner', 'id', id)
            const localVarPath = `/v1.0/banners/{id}/hide`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannersApi - functional programming interface
 * @export
 */
export const BannersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hide banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideBanner(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideBanner(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannersApi - factory interface
 * @export
 */
export const BannersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannersApiFp(configuration)
    return {
        /**
         * 
         * @summary Hide banner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideBanner(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.hideBanner(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hideBanner operation in BannersApi.
 * @export
 * @interface BannersApiHideBannerRequest
 */
export interface BannersApiHideBannerRequest {
    /**
     * 
     * @type {string}
     * @memberof BannersApiHideBanner
     */
    readonly id: string
}

/**
 * BannersApi - object-oriented interface
 * @export
 * @class BannersApi
 * @extends {BaseAPI}
 */
export class BannersApi extends BaseAPI {
    /**
     * 
     * @summary Hide banner
     * @param {BannersApiHideBannerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public hideBanner(requestParameters: BannersApiHideBannerRequest, options?: any) {
        return BannersApiFp(this.configuration).hideBanner(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BonusesApi - axios parameter creator
 * @export
 */
export const BonusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get bonuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBonuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/bonuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BonusesApi - functional programming interface
 * @export
 */
export const BonusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BonusesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get bonuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBonuses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BonusUserPlatformItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBonuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BonusesApi - factory interface
 * @export
 */
export const BonusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BonusesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get bonuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBonuses(options?: any): AxiosPromise<BonusUserPlatformItemsContainer> {
            return localVarFp.getUserBonuses(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BonusesApi - object-oriented interface
 * @export
 * @class BonusesApi
 * @extends {BaseAPI}
 */
export class BonusesApi extends BaseAPI {
    /**
     * 
     * @summary Get bonuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusesApi
     */
    public getUserBonuses(options?: any) {
        return BonusesApiFp(this.configuration).getUserBonuses(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FundsApi - axios parameter creator
 * @export
 */
export const FundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction: async (txId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('cancelTransaction', 'txId', txId)
            const localVarPath = `/v1.0/funds/transactions/{txId}/cancel`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['AssetId'] = assetId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preTransfer: async (fundsPreTransferRequest?: FundsPreTransferRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transfer/evaluate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundsPreTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTransactionConfirmation: async (txId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('resendTransactionConfirmation', 'txId', txId)
            const localVarPath = `/v1.0/funds/transactions/{txId}/resend`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer: async (fundsTransferRequest?: FundsTransferRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundsTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundsApi - functional programming interface
 * @export
 */
export const FundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransaction(txId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTransaction(txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundsTxItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactions(dateFrom, dateTo, currency, type, assetId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preTransfer(fundsPreTransferRequest?: FundsPreTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundsPreTransferResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preTransfer(fundsPreTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendTransactionConfirmation(txId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendTransactionConfirmation(txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transfer(fundsTransferRequest?: FundsTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transfer(fundsTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FundsApi - factory interface
 * @export
 */
export const FundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(txId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelTransaction(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options?: any): AxiosPromise<FundsTxItemsContainer> {
            return localVarFp.getTransactions(dateFrom, dateTo, currency, type, assetId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preTransfer(fundsPreTransferRequest?: FundsPreTransferRequest, options?: any): AxiosPromise<FundsPreTransferResult> {
            return localVarFp.preTransfer(fundsPreTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTransactionConfirmation(txId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendTransactionConfirmation(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer(fundsTransferRequest?: FundsTransferRequest, options?: any): AxiosPromise<void> {
            return localVarFp.transfer(fundsTransferRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelTransaction operation in FundsApi.
 * @export
 * @interface FundsApiCancelTransactionRequest
 */
export interface FundsApiCancelTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiCancelTransaction
     */
    readonly txId: string
}

/**
 * Request parameters for getTransactions operation in FundsApi.
 * @export
 * @interface FundsApiGetTransactionsRequest
 */
export interface FundsApiGetTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly dateTo?: string

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly currency?: string

    /**
     * 
     * @type {FundsTxType}
     * @memberof FundsApiGetTransactions
     */
    readonly type?: FundsTxType

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly assetId?: string

    /**
     * 
     * @type {number}
     * @memberof FundsApiGetTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof FundsApiGetTransactions
     */
    readonly take?: number
}

/**
 * Request parameters for preTransfer operation in FundsApi.
 * @export
 * @interface FundsApiPreTransferRequest
 */
export interface FundsApiPreTransferRequest {
    /**
     * 
     * @type {FundsPreTransferRequest}
     * @memberof FundsApiPreTransfer
     */
    readonly fundsPreTransferRequest?: FundsPreTransferRequest
}

/**
 * Request parameters for resendTransactionConfirmation operation in FundsApi.
 * @export
 * @interface FundsApiResendTransactionConfirmationRequest
 */
export interface FundsApiResendTransactionConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiResendTransactionConfirmation
     */
    readonly txId: string
}

/**
 * Request parameters for transfer operation in FundsApi.
 * @export
 * @interface FundsApiTransferRequest
 */
export interface FundsApiTransferRequest {
    /**
     * 
     * @type {FundsTransferRequest}
     * @memberof FundsApiTransfer
     */
    readonly fundsTransferRequest?: FundsTransferRequest
}

/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
export class FundsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel transaction
     * @param {FundsApiCancelTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public cancelTransaction(requestParameters: FundsApiCancelTransactionRequest, options?: any) {
        return FundsApiFp(this.configuration).cancelTransaction(requestParameters.txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get transfer transactions
     * @param {FundsApiGetTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public getTransactions(requestParameters: FundsApiGetTransactionsRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).getTransactions(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.currency, requestParameters.type, requestParameters.assetId, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get converted amount for transferring funds
     * @param {FundsApiPreTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public preTransfer(requestParameters: FundsApiPreTransferRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).preTransfer(requestParameters.fundsPreTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend transaction confirmation
     * @param {FundsApiResendTransactionConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public resendTransactionConfirmation(requestParameters: FundsApiResendTransactionConfirmationRequest, options?: any) {
        return FundsApiFp(this.configuration).resendTransactionConfirmation(requestParameters.txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer funds
     * @param {FundsApiTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public transfer(requestParameters: FundsApiTransferRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).transfer(requestParameters.fundsTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KycApi - axios parameter creator
 * @export
 */
export const KycApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenToken: async (levelName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/sumsub/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (levelName !== undefined) {
                localVarQueryParameter['levelName'] = levelName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (profileUpdate?: ProfileUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey: async (surveyUpdate?: SurveyUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycApi - functional programming interface
 * @export
 */
export const KycApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KycApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSumSubAccessTokenToken(levelName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalKycAccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSumSubAccessTokenToken(levelName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(profileUpdate?: ProfileUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(profileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSurvey(surveyUpdate?: SurveyUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSurvey(surveyUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KycApi - factory interface
 * @export
 */
export const KycApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycApiFp(configuration)
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycInfo(options?: any): AxiosPromise<KycInfo> {
            return localVarFp.getKycInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenToken(levelName?: string, options?: any): AxiosPromise<ExternalKycAccessToken> {
            return localVarFp.getSumSubAccessTokenToken(levelName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(profileUpdate?: ProfileUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfile(profileUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey(surveyUpdate?: SurveyUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSurvey(surveyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSumSubAccessTokenToken operation in KycApi.
 * @export
 * @interface KycApiGetSumSubAccessTokenTokenRequest
 */
export interface KycApiGetSumSubAccessTokenTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof KycApiGetSumSubAccessTokenToken
     */
    readonly levelName?: string
}

/**
 * Request parameters for updateProfile operation in KycApi.
 * @export
 * @interface KycApiUpdateProfileRequest
 */
export interface KycApiUpdateProfileRequest {
    /**
     * 
     * @type {ProfileUpdate}
     * @memberof KycApiUpdateProfile
     */
    readonly profileUpdate?: ProfileUpdate
}

/**
 * Request parameters for updateSurvey operation in KycApi.
 * @export
 * @interface KycApiUpdateSurveyRequest
 */
export interface KycApiUpdateSurveyRequest {
    /**
     * 
     * @type {SurveyUpdate}
     * @memberof KycApiUpdateSurvey
     */
    readonly surveyUpdate?: SurveyUpdate
}

/**
 * KycApi - object-oriented interface
 * @export
 * @class KycApi
 * @extends {BaseAPI}
 */
export class KycApi extends BaseAPI {
    /**
     * 
     * @summary Get kyc info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public getKycInfo(options?: any) {
        return KycApiFp(this.configuration).getKycInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SumSub access token
     * @param {KycApiGetSumSubAccessTokenTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public getSumSubAccessTokenToken(requestParameters: KycApiGetSumSubAccessTokenTokenRequest = {}, options?: any) {
        return KycApiFp(this.configuration).getSumSubAccessTokenToken(requestParameters.levelName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update profile
     * @param {KycApiUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public updateProfile(requestParameters: KycApiUpdateProfileRequest = {}, options?: any) {
        return KycApiFp(this.configuration).updateProfile(requestParameters.profileUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update survey
     * @param {KycApiUpdateSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public updateSurvey(requestParameters: KycApiUpdateSurveyRequest = {}, options?: any) {
        return KycApiFp(this.configuration).updateSurvey(requestParameters.surveyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketingApi - axios parameter creator
 * @export
 */
export const MarketingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get transfer transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMethods: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/marketing/payment/methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trading conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingConditions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/marketing/trading/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingApi - functional programming interface
 * @export
 */
export const MarketingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get transfer transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMethods(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketingPaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMethods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trading conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingConditions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingConditionItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingConditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingApi - factory interface
 * @export
 */
export const MarketingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get transfer transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMethods(options?: any): AxiosPromise<Array<MarketingPaymentMethod>> {
            return localVarFp.getMethods(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trading conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingConditions(options?: any): AxiosPromise<TradingConditionItemsContainer> {
            return localVarFp.getTradingConditions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingApi - object-oriented interface
 * @export
 * @class MarketingApi
 * @extends {BaseAPI}
 */
export class MarketingApi extends BaseAPI {
    /**
     * 
     * @summary Get transfer transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public getMethods(options?: any) {
        return MarketingApiFp(this.configuration).getMethods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trading conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public getTradingConditions(options?: any) {
        return MarketingApiFp(this.configuration).getTradingConditions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (mask?: string, from?: string, to?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mask !== undefined) {
                localVarQueryParameter['Mask'] = mask;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/notifications/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(mask?: string, from?: string, to?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(mask, from, to, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotification(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(mask?: string, from?: string, to?: string, skip?: number, take?: number, options?: any): AxiosPromise<NotificationItemsContainer> {
            return localVarFp.getNotifications(mask, from, to, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.readNotification(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationsRequest
 */
export interface NotificationsApiGetNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly mask?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiGetNotifications
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiGetNotifications
     */
    readonly take?: number
}

/**
 * Request parameters for readNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiReadNotificationRequest
 */
export interface NotificationsApiReadNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiReadNotification
     */
    readonly id?: string
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get notifications
     * @param {NotificationsApiGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(requestParameters: NotificationsApiGetNotificationsRequest = {}, options?: any) {
        return NotificationsApiFp(this.configuration).getNotifications(requestParameters.mask, requestParameters.from, requestParameters.to, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read notification(s)
     * @param {NotificationsApiReadNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public readNotification(requestParameters: NotificationsApiReadNotificationRequest = {}, options?: any) {
        return NotificationsApiFp(this.configuration).readNotification(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositFee: async (paymentMethodId?: number, accountId?: string, amount?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/fee/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositWidgets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumDepositAmount: async (accountId?: string, paymentMethodId?: number, recurringId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit/limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['paymentMethodId'] = paymentMethodId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['recurringId'] = recurringId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumWithdrawAmount: async (accountId?: string, paymentMethodId?: number, recurringId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw/limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['paymentMethodId'] = paymentMethodId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['recurringId'] = recurringId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (accountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/method/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawFee: async (paymentMethodId?: number, accountId?: string, amount?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/fee/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawMethods: async (accountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/payments/method/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawWidgets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDeposit: async (paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['RecurringId'] = recurringId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['DeviceType'] = deviceType;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (isBonusesRejected !== undefined) {
                localVarQueryParameter['IsBonusesRejected'] = isBonusesRejected;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeWithdraw: async (paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['RecurringId'] = recurringId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['DeviceType'] = deviceType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositFee(paymentMethodId, accountId, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositWidgets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaximumDepositAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaximumLimitDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaximumDepositAmount(accountId, paymentMethodId, recurringId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaximumWithdrawAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaximumLimitDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaximumWithdrawAmount(accountId, paymentMethodId, recurringId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(accountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawFee(paymentMethodId, accountId, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawMethods(accountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethods>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawMethods(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawWidgets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDeposit(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDeposit(paymentMethodId, accountId, recurringId, amount, deviceType, id, isBonusesRejected, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeWithdraw(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeWithdraw(paymentMethodId, accountId, recurringId, amount, deviceType, withdrawRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): AxiosPromise<FeeResponse> {
            return localVarFp.getDepositFee(paymentMethodId, accountId, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositWidgets(options?: any): AxiosPromise<InterfaceItem> {
            return localVarFp.getDepositWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumDepositAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): AxiosPromise<MaximumLimitDescription> {
            return localVarFp.getMaximumDepositAmount(accountId, paymentMethodId, recurringId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumWithdrawAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): AxiosPromise<MaximumLimitDescription> {
            return localVarFp.getMaximumWithdrawAmount(accountId, paymentMethodId, recurringId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfo(options?: any): AxiosPromise<PaymentInfo> {
            return localVarFp.getPaymentInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(accountId?: string, options?: any): AxiosPromise<PaymentMethodItemsContainer> {
            return localVarFp.getPaymentMethods(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): AxiosPromise<FeeResponse> {
            return localVarFp.getWithdrawFee(paymentMethodId, accountId, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawMethods(accountId?: string, options?: any): AxiosPromise<PaymentMethods> {
            return localVarFp.getWithdrawMethods(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawWidgets(options?: any): AxiosPromise<InterfaceItem> {
            return localVarFp.getWithdrawWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDeposit(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.makeDeposit(paymentMethodId, accountId, recurringId, amount, deviceType, id, isBonusesRejected, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeWithdraw(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options?: any): AxiosPromise<WithdrawResult> {
            return localVarFp.makeWithdraw(paymentMethodId, accountId, recurringId, amount, deviceType, withdrawRequestPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDepositFee operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetDepositFeeRequest
 */
export interface PaymentsApiGetDepositFeeRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly accountId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly amount?: string
}

/**
 * Request parameters for getMaximumDepositAmount operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetMaximumDepositAmountRequest
 */
export interface PaymentsApiGetMaximumDepositAmountRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly accountId?: string

    /**
     * 
     * @type {number}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly paymentMethodId?: number

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly recurringId?: string
}

/**
 * Request parameters for getMaximumWithdrawAmount operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetMaximumWithdrawAmountRequest
 */
export interface PaymentsApiGetMaximumWithdrawAmountRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly accountId?: string

    /**
     * 
     * @type {number}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly paymentMethodId?: number

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly recurringId?: string
}

/**
 * Request parameters for getPaymentMethods operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetPaymentMethodsRequest
 */
export interface PaymentsApiGetPaymentMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetPaymentMethods
     */
    readonly accountId?: string
}

/**
 * Request parameters for getWithdrawFee operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetWithdrawFeeRequest
 */
export interface PaymentsApiGetWithdrawFeeRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly accountId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly amount?: string
}

/**
 * Request parameters for getWithdrawMethods operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetWithdrawMethodsRequest
 */
export interface PaymentsApiGetWithdrawMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetWithdrawMethods
     */
    readonly accountId?: string
}

/**
 * Request parameters for makeDeposit operation in PaymentsApi.
 * @export
 * @interface PaymentsApiMakeDepositRequest
 */
export interface PaymentsApiMakeDepositRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly accountId?: string

    /**
     * Id of previously used and saved kind of payment info
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly recurringId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly amount?: string

    /**
     * Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly deviceType?: string

    /**
     * Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly id?: string

    /**
     * 
     * @type {boolean}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly isBonusesRejected?: boolean
}

/**
 * Request parameters for makeWithdraw operation in PaymentsApi.
 * @export
 * @interface PaymentsApiMakeWithdrawRequest
 */
export interface PaymentsApiMakeWithdrawRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly accountId?: string

    /**
     * Id of previously used/saved payment info (for example, bank card number)
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly recurringId?: string

    /**
     * Amount to withdraw
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly amount?: string

    /**
     * Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly deviceType?: string

    /**
     * 
     * @type {WithdrawRequestPayload}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly withdrawRequestPayload?: WithdrawRequestPayload
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @summary Calculate fee for deposit
     * @param {PaymentsApiGetDepositFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getDepositFee(requestParameters: PaymentsApiGetDepositFeeRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getDepositFee(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getDepositWidgets(options?: any) {
        return PaymentsApiFp(this.configuration).getDepositWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deposit settings
     * @param {PaymentsApiGetMaximumDepositAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getMaximumDepositAmount(requestParameters: PaymentsApiGetMaximumDepositAmountRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getMaximumDepositAmount(requestParameters.accountId, requestParameters.paymentMethodId, requestParameters.recurringId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get withdraw settings
     * @param {PaymentsApiGetMaximumWithdrawAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getMaximumWithdrawAmount(requestParameters: PaymentsApiGetMaximumWithdrawAmountRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getMaximumWithdrawAmount(requestParameters.accountId, requestParameters.paymentMethodId, requestParameters.recurringId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get common payment info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentInfo(options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get awailable deposit methods
     * @param {PaymentsApiGetPaymentMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentMethods(requestParameters: PaymentsApiGetPaymentMethodsRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentMethods(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate fee for withdraw
     * @param {PaymentsApiGetWithdrawFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawFee(requestParameters: PaymentsApiGetWithdrawFeeRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawFee(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get awailable withdraw methods
     * @param {PaymentsApiGetWithdrawMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawMethods(requestParameters: PaymentsApiGetWithdrawMethodsRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawMethods(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawWidgets(options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make deposit on real account
     * @param {PaymentsApiMakeDepositRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public makeDeposit(requestParameters: PaymentsApiMakeDepositRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).makeDeposit(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.recurringId, requestParameters.amount, requestParameters.deviceType, requestParameters.id, requestParameters.isBonusesRejected, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make withdraw from real account
     * @param {PaymentsApiMakeWithdrawRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public makeWithdraw(requestParameters: PaymentsApiMakeWithdrawRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).makeWithdraw(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.recurringId, requestParameters.amount, requestParameters.deviceType, requestParameters.withdrawRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlatformApi - axios parameter creator
 * @export
 */
export const PlatformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] Возможные платформы, с которых пользователь стучится в наш бекенд
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDocuments: async (platform?: ClientPlatform, locale?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/legal/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/platform/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformUrlInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/info/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (keys?: Array<string>, locale?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformApi - functional programming interface
 * @export
 */
export const PlatformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountriesInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] Возможные платформы, с которых пользователь стучится в наш бекенд
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalDocuments(platform?: ClientPlatform, locale?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDocumentItemItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalDocuments(platform, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocales(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocales(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlatformInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlatformInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlatformUrlInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformUrlInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlatformUrlInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(keys?: Array<string>, locale?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslations(keys, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlatformApi - factory interface
 * @export
 */
export const PlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlatformApiFp(configuration)
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<CountriesInfo> {
            return localVarFp.getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDate(options?: any): AxiosPromise<ModelsContainer> {
            return localVarFp.getDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] Возможные платформы, с которых пользователь стучится в наш бекенд
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDocuments(platform?: ClientPlatform, locale?: string, options?: any): AxiosPromise<LegalDocumentItemItemsContainer> {
            return localVarFp.getLegalDocuments(platform, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales(options?: any): AxiosPromise<LocaleInfoItemsContainer> {
            return localVarFp.getLocales(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformInfo(options?: any): AxiosPromise<PlatformInfo> {
            return localVarFp.getPlatformInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformUrlInfo(options?: any): AxiosPromise<PlatformUrlInfo> {
            return localVarFp.getPlatformUrlInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(keys?: Array<string>, locale?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getTranslations(keys, locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLegalDocuments operation in PlatformApi.
 * @export
 * @interface PlatformApiGetLegalDocumentsRequest
 */
export interface PlatformApiGetLegalDocumentsRequest {
    /**
     * Возможные платформы, с которых пользователь стучится в наш бекенд
     * @type {ClientPlatform}
     * @memberof PlatformApiGetLegalDocuments
     */
    readonly platform?: ClientPlatform

    /**
     * 
     * @type {string}
     * @memberof PlatformApiGetLegalDocuments
     */
    readonly locale?: string
}

/**
 * Request parameters for getTranslations operation in PlatformApi.
 * @export
 * @interface PlatformApiGetTranslationsRequest
 */
export interface PlatformApiGetTranslationsRequest {
    /**
     * Translation keys that look like \&#39;Area::Context:KeyName\&#39;
     * @type {Array<string>}
     * @memberof PlatformApiGetTranslations
     */
    readonly keys?: Array<string>

    /**
     * One of supported languages: en, vi, etc
     * @type {string}
     * @memberof PlatformApiGetTranslations
     */
    readonly locale?: string
}

/**
 * PlatformApi - object-oriented interface
 * @export
 * @class PlatformApi
 * @extends {BaseAPI}
 */
export class PlatformApi extends BaseAPI {
    /**
     * 
     * @summary Countries info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getCountries(options?: any) {
        return PlatformApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getDate(options?: any) {
        return PlatformApiFp(this.configuration).getDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available legal documents
     * @param {PlatformApiGetLegalDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getLegalDocuments(requestParameters: PlatformApiGetLegalDocumentsRequest = {}, options?: any) {
        return PlatformApiFp(this.configuration).getLegalDocuments(requestParameters.platform, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available localizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getLocales(options?: any) {
        return PlatformApiFp(this.configuration).getLocales(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getPlatformInfo(options?: any) {
        return PlatformApiFp(this.configuration).getPlatformInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform url info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getPlatformUrlInfo(options?: any) {
        return PlatformApiFp(this.configuration).getPlatformUrlInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available localizations
     * @param {PlatformApiGetTranslationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getTranslations(requestParameters: PlatformApiGetTranslationsRequest = {}, options?: any) {
        return PlatformApiFp(this.configuration).getTranslations(requestParameters.keys, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserEmail: async (confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/update/email/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmUpdateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/profile/header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFcmToken: async (fcmToken?: FcmToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/push/token/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/requests/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvent: async (activityRequest?: ActivityRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogDisable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/dialog/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogEnable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/dialog/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDisable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceEnable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken: async (fcmToken?: FcmToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/push/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileSettings: async (profileSettingsRequest?: ProfileSettingsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail: async (emailRequest?: EmailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/update/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserEmail(confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserEmail(confirmUpdateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFcmToken(fcmToken?: FcmToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFcmToken(fcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEvent(activityRequest?: ActivityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEvent(activityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDialogDisable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDialogDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDialogEnable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDialogEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDisable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceEnable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFcmToken(fcmToken?: FcmToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFcmToken(fcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfileSettings(profileSettingsRequest?: ProfileSettingsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfileSettings(profileSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEmail(emailRequest?: EmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEmail(emailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserEmail(confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options?: any): AxiosPromise<string> {
            return localVarFp.confirmUserEmail(confirmUpdateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserProfile(options?: any): AxiosPromise<void> {
            return localVarFp.confirmUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<ProfileHeader> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSettings(options?: any): AxiosPromise<ProfileSettings> {
            return localVarFp.getProfileSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFcmToken(fcmToken?: FcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.removeFcmToken(fcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDelete(options?: any): AxiosPromise<void> {
            return localVarFp.requestDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvent(activityRequest?: ActivityRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveEvent(activityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogDisable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDialogDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogEnable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDialogEnable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDisable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceEnable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceEnable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken(fcmToken?: FcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.updateFcmToken(fcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileSettings(profileSettingsRequest?: ProfileSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfileSettings(profileSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail(emailRequest?: EmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserEmail(emailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmUserEmail operation in ProfileApi.
 * @export
 * @interface ProfileApiConfirmUserEmailRequest
 */
export interface ProfileApiConfirmUserEmailRequest {
    /**
     * 
     * @type {ConfirmUpdateEmailRequest}
     * @memberof ProfileApiConfirmUserEmail
     */
    readonly confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest
}

/**
 * Request parameters for removeFcmToken operation in ProfileApi.
 * @export
 * @interface ProfileApiRemoveFcmTokenRequest
 */
export interface ProfileApiRemoveFcmTokenRequest {
    /**
     * 
     * @type {FcmToken}
     * @memberof ProfileApiRemoveFcmToken
     */
    readonly fcmToken?: FcmToken
}

/**
 * Request parameters for saveEvent operation in ProfileApi.
 * @export
 * @interface ProfileApiSaveEventRequest
 */
export interface ProfileApiSaveEventRequest {
    /**
     * 
     * @type {ActivityRequest}
     * @memberof ProfileApiSaveEvent
     */
    readonly activityRequest?: ActivityRequest
}

/**
 * Request parameters for updateFcmToken operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateFcmTokenRequest
 */
export interface ProfileApiUpdateFcmTokenRequest {
    /**
     * 
     * @type {FcmToken}
     * @memberof ProfileApiUpdateFcmToken
     */
    readonly fcmToken?: FcmToken
}

/**
 * Request parameters for updateProfileSettings operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateProfileSettingsRequest
 */
export interface ProfileApiUpdateProfileSettingsRequest {
    /**
     * 
     * @type {ProfileSettingsRequest}
     * @memberof ProfileApiUpdateProfileSettings
     */
    readonly profileSettingsRequest?: ProfileSettingsRequest
}

/**
 * Request parameters for updateUserEmail operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateUserEmailRequest
 */
export interface ProfileApiUpdateUserEmailRequest {
    /**
     * 
     * @type {EmailRequest}
     * @memberof ProfileApiUpdateUserEmail
     */
    readonly emailRequest?: EmailRequest
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Confirm user email
     * @param {ProfileApiConfirmUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public confirmUserEmail(requestParameters: ProfileApiConfirmUserEmailRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).confirmUserEmail(requestParameters.confirmUpdateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public confirmUserProfile(options?: any) {
        return ProfileApiFp(this.configuration).confirmUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileSettings(options?: any) {
        return ProfileApiFp(this.configuration).getProfileSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove FCM token
     * @param {ProfileApiRemoveFcmTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public removeFcmToken(requestParameters: ProfileApiRemoveFcmTokenRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).removeFcmToken(requestParameters.fcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request to delete a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public requestDelete(options?: any) {
        return ProfileApiFp(this.configuration).requestDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiSaveEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public saveEvent(requestParameters: ProfileApiSaveEventRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).saveEvent(requestParameters.activityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable terminal target price dialog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDialogDisable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDialogDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable terminal target price dialog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDialogEnable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDialogEnable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable terminal target price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDisable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable terminal target price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceEnable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceEnable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update FCM token
     * @param {ProfileApiUpdateFcmTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateFcmToken(requestParameters: ProfileApiUpdateFcmTokenRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateFcmToken(requestParameters.fcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set profile settings
     * @param {ProfileApiUpdateProfileSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfileSettings(requestParameters: ProfileApiUpdateProfileSettingsRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateProfileSettings(requestParameters.profileSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email
     * @param {ProfileApiUpdateUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateUserEmail(requestParameters: ProfileApiUpdateUserEmailRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateUserEmail(requestParameters.emailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushTestApi - axios parameter creator
 * @export
 */
export const PushTestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send custom push message
         * @param {FcmNotification} [fcmNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositCustom: async (fcmNotification?: FcmNotification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/push-test/send/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send \'deposit fail\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositFailed: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/push-test/send/deposit-fail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send \'deposit success\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositSuccess: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/push-test/send/deposit-success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushTestApi - functional programming interface
 * @export
 */
export const PushTestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushTestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send custom push message
         * @param {FcmNotification} [fcmNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDepositCustom(fcmNotification?: FcmNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDepositCustom(fcmNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send \'deposit fail\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDepositFailed(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDepositFailed(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send \'deposit success\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDepositSuccess(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDepositSuccess(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushTestApi - factory interface
 * @export
 */
export const PushTestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushTestApiFp(configuration)
    return {
        /**
         * 
         * @summary Send custom push message
         * @param {FcmNotification} [fcmNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositCustom(fcmNotification?: FcmNotification, options?: any): AxiosPromise<void> {
            return localVarFp.sendDepositCustom(fcmNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send \'deposit fail\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositFailed(options?: any): AxiosPromise<void> {
            return localVarFp.sendDepositFailed(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send \'deposit success\' push message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDepositSuccess(options?: any): AxiosPromise<void> {
            return localVarFp.sendDepositSuccess(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sendDepositCustom operation in PushTestApi.
 * @export
 * @interface PushTestApiSendDepositCustomRequest
 */
export interface PushTestApiSendDepositCustomRequest {
    /**
     * 
     * @type {FcmNotification}
     * @memberof PushTestApiSendDepositCustom
     */
    readonly fcmNotification?: FcmNotification
}

/**
 * PushTestApi - object-oriented interface
 * @export
 * @class PushTestApi
 * @extends {BaseAPI}
 */
export class PushTestApi extends BaseAPI {
    /**
     * 
     * @summary Send custom push message
     * @param {PushTestApiSendDepositCustomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushTestApi
     */
    public sendDepositCustom(requestParameters: PushTestApiSendDepositCustomRequest = {}, options?: any) {
        return PushTestApiFp(this.configuration).sendDepositCustom(requestParameters.fcmNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send \'deposit fail\' push message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushTestApi
     */
    public sendDepositFailed(options?: any) {
        return PushTestApiFp(this.configuration).sendDepositFailed(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send \'deposit success\' push message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushTestApi
     */
    public sendDepositSuccess(options?: any) {
        return PushTestApiFp(this.configuration).sendDepositSuccess(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RaffleApi - axios parameter creator
 * @export
 */
export const RaffleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRaffle: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/raffle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RaffleApi - functional programming interface
 * @export
 */
export const RaffleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RaffleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRaffle(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RaffleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRaffle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RaffleApi - factory interface
 * @export
 */
export const RaffleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RaffleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRaffle(options?: any): AxiosPromise<RaffleResponse> {
            return localVarFp.getUserRaffle(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RaffleApi - object-oriented interface
 * @export
 * @class RaffleApi
 * @extends {BaseAPI}
 */
export class RaffleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RaffleApi
     */
    public getUserRaffle(options?: any) {
        return RaffleApiFp(this.configuration).getUserRaffle(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RateApi - axios parameter creator
 * @export
 */
export const RateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRate: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates: async (from?: Array<string>, to?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/rate/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from) {
                localVarQueryParameter['from'] = from;
            }

            if (to) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RateApi - functional programming interface
 * @export
 */
export const RateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRate(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRate(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRates(from?: Array<string>, to?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RatesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRates(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RateApi - factory interface
 * @export
 */
export const RateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RateApiFp(configuration)
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRate(from?: string, to?: string, options?: any): AxiosPromise<RateResult> {
            return localVarFp.getRate(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates(from?: Array<string>, to?: Array<string>, options?: any): AxiosPromise<RatesResult> {
            return localVarFp.getRates(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRate operation in RateApi.
 * @export
 * @interface RateApiGetRateRequest
 */
export interface RateApiGetRateRequest {
    /**
     * 
     * @type {string}
     * @memberof RateApiGetRate
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof RateApiGetRate
     */
    readonly to?: string
}

/**
 * Request parameters for getRates operation in RateApi.
 * @export
 * @interface RateApiGetRatesRequest
 */
export interface RateApiGetRatesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RateApiGetRates
     */
    readonly from?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RateApiGetRates
     */
    readonly to?: Array<string>
}

/**
 * RateApi - object-oriented interface
 * @export
 * @class RateApi
 * @extends {BaseAPI}
 */
export class RateApi extends BaseAPI {
    /**
     * 
     * @summary Get rate
     * @param {RateApiGetRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RateApi
     */
    public getRate(requestParameters: RateApiGetRateRequest = {}, options?: any) {
        return RateApiFp(this.configuration).getRate(requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rates
     * @param {RateApiGetRatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RateApi
     */
    public getRates(requestParameters: RateApiGetRatesRequest = {}, options?: any) {
        return RateApiFp(this.configuration).getRates(requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TerminalApi - axios parameter creator
 * @export
 */
export const TerminalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLastIndicator: async (tradingAccountId: string, terminalIndicator?: TerminalIndicator, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addLastIndicator', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/indicators/last/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalIndicator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateChartData: async (tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addOrUpdateChartData', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/update`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalChartUpdateData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyTemplateData: async (tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addStudyTemplateData', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalStudyTemplateAddData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToCharts: async (tradingAccountId: string, symbol: string, isReplace?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addSymbolToCharts', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('addSymbolToCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/charts/{symbol}/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isReplace !== undefined) {
                localVarQueryParameter['isReplace'] = isReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToFavorites: async (tradingAccountId: string, symbol: string, isReplace?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addSymbolToFavorites', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('addSymbolToFavorites', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites/{symbol}/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isReplace !== undefined) {
                localVarQueryParameter['isReplace'] = isReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceOperationsHistory: async (tradingAccountId: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('balanceOperationsHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/balance`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeOrders: async (tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('closeOrders', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/close`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalCloseOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSymbols: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getAccountSymbols', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData: async (tradingAccountId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getChartData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChartData', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/{id}`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsMetaInfo: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getChartsMetaInfo', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastIndicator: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getLastIndicator', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/indicators/last`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplateData: async (tradingAccountId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getStudyTemplateData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getStudyTemplateData', 'name', name)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/{name}`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplatesMetaInfo: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getStudyTemplatesMetaInfo', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolCharts: async (tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbolCharts', 'tradingServerId', tradingServerId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('getSymbolCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/{tradingServerId}/symbols/{symbol}/chart`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (countBack !== undefined) {
                localVarQueryParameter['countBack'] = countBack;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbols: async (tradingServerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbols', 'tradingServerId', tradingServerId)
            const localVarPath = `/v1.1/terminal/{tradingServerId}/symbols`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolsUpdates: async (tradingServerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbolsUpdates', 'tradingServerId', tradingServerId)
            const localVarPath = `/v1.0/terminal/{tradingServerId}/symbols/updates`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAccountToken: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getTerminalAccountToken', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/token`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralCalendarCredentials: async (terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/terminal/widget/trading-central/calendar/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalTradingCentralHandShake, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralNews: async (symbol: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('getTradingCentralNews', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/widget/trading-central/news/{symbol}`
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralWidgetCredentials: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/terminal/widget/trading-central/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOrder: async (tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('modifyOrder', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyOrder', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/{id}/modify`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalModifyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOrder: async (tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('openOrder', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/open`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalOpenOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersHistory: async (tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('ordersHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionsHistory: async (tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('positionsHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/positions`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChartData: async (tradingAccountId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeChartData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeChartData', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/{id}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStudyTemplateData: async (tradingAccountId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeStudyTemplateData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('removeStudyTemplateData', 'name', name)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/{name}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromCharts: async (tradingAccountId: string, symbol: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeSymbolFromCharts', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('removeSymbolFromCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/charts/{symbol}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromFavorites: async (tradingAccountId: string, symbol: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeSymbolFromFavorites', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('removeSymbolFromFavorites', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites/{symbol}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteSymbolsGroup: async (tradingAccountId: string, group: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('updateFavoriteSymbolsGroup', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('updateFavoriteSymbolsGroup', 'group', group)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/groups/{group}/update`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"group"}}`, encodeURIComponent(String(group)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalApi - functional programming interface
 * @export
 */
export const TerminalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLastIndicator(tradingAccountId: string, terminalIndicator?: TerminalIndicator, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLastIndicator(tradingAccountId, terminalIndicator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateChartData(tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartUpdateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateChartData(tradingAccountId, terminalChartUpdateData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStudyTemplateData(tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStudyTemplateData(tradingAccountId, terminalStudyTemplateAddData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSymbolToCharts(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSymbolToCharts(tradingAccountId, symbol, isReplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSymbolToFavorites(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSymbolToFavorites(tradingAccountId, symbol, isReplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceOperationsHistory(tradingAccountId: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalBalanceOperationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balanceOperationsHistory(tradingAccountId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeOrders(tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeOrders(tradingAccountId, terminalCloseOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountSymbols(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalAccountSymbols>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountSymbols(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartData(tradingAccountId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartData(tradingAccountId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartsMetaInfo(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartMetaInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartsMetaInfo(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastIndicator(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalIndicatorContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastIndicator(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyTemplateData(tradingAccountId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalStudyTemplateData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyTemplateData(tradingAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyTemplatesMetaInfo(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalStudyTemplateMetaInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyTemplatesMetaInfo(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbolCharts(tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalBarItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbolCharts(tradingServerId, symbol, timeframe, from, to, countBack, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbols(tradingServerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalSymbols>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbols(tradingServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbolsUpdates(tradingServerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalSymbolsUpdates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbolsUpdates(tradingServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalAccountToken(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalAccountToken(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralCalendarCredentials(terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralWidgetCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralCalendarCredentials(terminalTradingCentralHandShake, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralNews(symbol: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralArticleItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralNews(symbol, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralWidgetCredentials(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralWidgetCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralWidgetCredentials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyOrder(tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyOrder(tradingAccountId, id, terminalModifyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openOrder(tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openOrder(tradingAccountId, terminalOpenOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalOrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionsHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalPositionItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionsHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeChartData(tradingAccountId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeChartData(tradingAccountId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeStudyTemplateData(tradingAccountId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeStudyTemplateData(tradingAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSymbolFromCharts(tradingAccountId: string, symbol: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSymbolFromCharts(tradingAccountId, symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSymbolFromFavorites(tradingAccountId: string, symbol: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSymbolFromFavorites(tradingAccountId, symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteSymbolsGroup(tradingAccountId: string, group: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteSymbolsGroup(tradingAccountId, group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TerminalApi - factory interface
 * @export
 */
export const TerminalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalApiFp(configuration)
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLastIndicator(tradingAccountId: string, terminalIndicator?: TerminalIndicator, options?: any): AxiosPromise<void> {
            return localVarFp.addLastIndicator(tradingAccountId, terminalIndicator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateChartData(tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options?: any): AxiosPromise<TerminalChartUpdateResult> {
            return localVarFp.addOrUpdateChartData(tradingAccountId, terminalChartUpdateData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyTemplateData(tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options?: any): AxiosPromise<void> {
            return localVarFp.addStudyTemplateData(tradingAccountId, terminalStudyTemplateAddData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToCharts(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.addSymbolToCharts(tradingAccountId, symbol, isReplace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToFavorites(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.addSymbolToFavorites(tradingAccountId, symbol, isReplace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceOperationsHistory(tradingAccountId: string, skip?: number, take?: number, options?: any): AxiosPromise<TerminalBalanceOperationItemsContainer> {
            return localVarFp.balanceOperationsHistory(tradingAccountId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeOrders(tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.closeOrders(tradingAccountId, terminalCloseOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSymbols(tradingAccountId: string, options?: any): AxiosPromise<TerminalAccountSymbols> {
            return localVarFp.getAccountSymbols(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData(tradingAccountId: string, id: string, options?: any): AxiosPromise<TerminalChartData> {
            return localVarFp.getChartData(tradingAccountId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsMetaInfo(tradingAccountId: string, options?: any): AxiosPromise<TerminalChartMetaInfoItemsContainer> {
            return localVarFp.getChartsMetaInfo(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastIndicator(tradingAccountId: string, options?: any): AxiosPromise<TerminalIndicatorContainer> {
            return localVarFp.getLastIndicator(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplateData(tradingAccountId: string, name: string, options?: any): AxiosPromise<TerminalStudyTemplateData> {
            return localVarFp.getStudyTemplateData(tradingAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplatesMetaInfo(tradingAccountId: string, options?: any): AxiosPromise<TerminalStudyTemplateMetaInfoItemsContainer> {
            return localVarFp.getStudyTemplatesMetaInfo(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolCharts(tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options?: any): AxiosPromise<TerminalBarItemsContainer> {
            return localVarFp.getSymbolCharts(tradingServerId, symbol, timeframe, from, to, countBack, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbols(tradingServerId: string, options?: any): AxiosPromise<TerminalSymbols> {
            return localVarFp.getSymbols(tradingServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolsUpdates(tradingServerId: string, options?: any): AxiosPromise<TerminalSymbolsUpdates> {
            return localVarFp.getSymbolsUpdates(tradingServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAccountToken(tradingAccountId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getTerminalAccountToken(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralCalendarCredentials(terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options?: any): AxiosPromise<TerminalTradingCentralWidgetCredentials> {
            return localVarFp.getTradingCentralCalendarCredentials(terminalTradingCentralHandShake, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralNews(symbol: string, skip?: number, take?: number, options?: any): AxiosPromise<TerminalTradingCentralArticleItemsContainer> {
            return localVarFp.getTradingCentralNews(symbol, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralWidgetCredentials(options?: any): AxiosPromise<TerminalTradingCentralWidgetCredentials> {
            return localVarFp.getTradingCentralWidgetCredentials(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOrder(tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.modifyOrder(tradingAccountId, id, terminalModifyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOrder(tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.openOrder(tradingAccountId, terminalOpenOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<TerminalOrderItemsContainer> {
            return localVarFp.ordersHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionsHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<TerminalPositionItemsContainer> {
            return localVarFp.positionsHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChartData(tradingAccountId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeChartData(tradingAccountId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStudyTemplateData(tradingAccountId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeStudyTemplateData(tradingAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromCharts(tradingAccountId: string, symbol: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeSymbolFromCharts(tradingAccountId, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromFavorites(tradingAccountId: string, symbol: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeSymbolFromFavorites(tradingAccountId, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteSymbolsGroup(tradingAccountId: string, group: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateFavoriteSymbolsGroup(tradingAccountId, group, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addLastIndicator operation in TerminalApi.
 * @export
 * @interface TerminalApiAddLastIndicatorRequest
 */
export interface TerminalApiAddLastIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddLastIndicator
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalIndicator}
     * @memberof TerminalApiAddLastIndicator
     */
    readonly terminalIndicator?: TerminalIndicator
}

/**
 * Request parameters for addOrUpdateChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiAddOrUpdateChartDataRequest
 */
export interface TerminalApiAddOrUpdateChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddOrUpdateChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalChartUpdateData}
     * @memberof TerminalApiAddOrUpdateChartData
     */
    readonly terminalChartUpdateData?: TerminalChartUpdateData
}

/**
 * Request parameters for addStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiAddStudyTemplateDataRequest
 */
export interface TerminalApiAddStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalStudyTemplateAddData}
     * @memberof TerminalApiAddStudyTemplateData
     */
    readonly terminalStudyTemplateAddData?: TerminalStudyTemplateAddData
}

/**
 * Request parameters for addSymbolToCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiAddSymbolToChartsRequest
 */
export interface TerminalApiAddSymbolToChartsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly symbol: string

    /**
     * 
     * @type {boolean}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly isReplace?: boolean
}

/**
 * Request parameters for addSymbolToFavorites operation in TerminalApi.
 * @export
 * @interface TerminalApiAddSymbolToFavoritesRequest
 */
export interface TerminalApiAddSymbolToFavoritesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly symbol: string

    /**
     * 
     * @type {boolean}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly isReplace?: boolean
}

/**
 * Request parameters for balanceOperationsHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiBalanceOperationsHistoryRequest
 */
export interface TerminalApiBalanceOperationsHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly take?: number
}

/**
 * Request parameters for closeOrders operation in TerminalApi.
 * @export
 * @interface TerminalApiCloseOrdersRequest
 */
export interface TerminalApiCloseOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiCloseOrders
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalCloseOrderRequest}
     * @memberof TerminalApiCloseOrders
     */
    readonly terminalCloseOrderRequest?: TerminalCloseOrderRequest
}

/**
 * Request parameters for getAccountSymbols operation in TerminalApi.
 * @export
 * @interface TerminalApiGetAccountSymbolsRequest
 */
export interface TerminalApiGetAccountSymbolsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetAccountSymbols
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiGetChartDataRequest
 */
export interface TerminalApiGetChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartData
     */
    readonly id: string
}

/**
 * Request parameters for getChartsMetaInfo operation in TerminalApi.
 * @export
 * @interface TerminalApiGetChartsMetaInfoRequest
 */
export interface TerminalApiGetChartsMetaInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartsMetaInfo
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getLastIndicator operation in TerminalApi.
 * @export
 * @interface TerminalApiGetLastIndicatorRequest
 */
export interface TerminalApiGetLastIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetLastIndicator
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiGetStudyTemplateDataRequest
 */
export interface TerminalApiGetStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplateData
     */
    readonly name: string
}

/**
 * Request parameters for getStudyTemplatesMetaInfo operation in TerminalApi.
 * @export
 * @interface TerminalApiGetStudyTemplatesMetaInfoRequest
 */
export interface TerminalApiGetStudyTemplatesMetaInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplatesMetaInfo
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getSymbolCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolChartsRequest
 */
export interface TerminalApiGetSymbolChartsRequest {
    /**
     * Tradings server id
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly tradingServerId: string

    /**
     * Symbol name
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly symbol: string

    /**
     * Timeframe
     * @type {BarTimeframe}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly timeframe?: BarTimeframe

    /**
     * Date from
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly from?: string

    /**
     * Date to
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly to?: string

    /**
     * The exact amount of bars to load
     * @type {number}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly countBack?: number
}

/**
 * Request parameters for getSymbols operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolsRequest
 */
export interface TerminalApiGetSymbolsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetSymbols
     */
    readonly tradingServerId: string
}

/**
 * Request parameters for getSymbolsUpdates operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolsUpdatesRequest
 */
export interface TerminalApiGetSymbolsUpdatesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetSymbolsUpdates
     */
    readonly tradingServerId: string
}

/**
 * Request parameters for getTerminalAccountToken operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTerminalAccountTokenRequest
 */
export interface TerminalApiGetTerminalAccountTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetTerminalAccountToken
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getTradingCentralCalendarCredentials operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTradingCentralCalendarCredentialsRequest
 */
export interface TerminalApiGetTradingCentralCalendarCredentialsRequest {
    /**
     * 
     * @type {TerminalTradingCentralHandShake}
     * @memberof TerminalApiGetTradingCentralCalendarCredentials
     */
    readonly terminalTradingCentralHandShake?: TerminalTradingCentralHandShake
}

/**
 * Request parameters for getTradingCentralNews operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTradingCentralNewsRequest
 */
export interface TerminalApiGetTradingCentralNewsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly symbol: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly take?: number
}

/**
 * Request parameters for modifyOrder operation in TerminalApi.
 * @export
 * @interface TerminalApiModifyOrderRequest
 */
export interface TerminalApiModifyOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiModifyOrder
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiModifyOrder
     */
    readonly id: number

    /**
     * 
     * @type {TerminalModifyOrderRequest}
     * @memberof TerminalApiModifyOrder
     */
    readonly terminalModifyOrderRequest?: TerminalModifyOrderRequest
}

/**
 * Request parameters for openOrder operation in TerminalApi.
 * @export
 * @interface TerminalApiOpenOrderRequest
 */
export interface TerminalApiOpenOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiOpenOrder
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalOpenOrderRequest}
     * @memberof TerminalApiOpenOrder
     */
    readonly terminalOpenOrderRequest?: TerminalOpenOrderRequest
}

/**
 * Request parameters for ordersHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiOrdersHistoryRequest
 */
export interface TerminalApiOrdersHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly symbol?: string

    /**
     * 
     * @type {TerminalOrderSorting}
     * @memberof TerminalApiOrdersHistory
     */
    readonly sorting?: TerminalOrderSorting

    /**
     * 
     * @type {number}
     * @memberof TerminalApiOrdersHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiOrdersHistory
     */
    readonly take?: number
}

/**
 * Request parameters for positionsHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiPositionsHistoryRequest
 */
export interface TerminalApiPositionsHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly symbol?: string

    /**
     * 
     * @type {TerminalOrderSorting}
     * @memberof TerminalApiPositionsHistory
     */
    readonly sorting?: TerminalOrderSorting

    /**
     * 
     * @type {number}
     * @memberof TerminalApiPositionsHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiPositionsHistory
     */
    readonly take?: number
}

/**
 * Request parameters for removeChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveChartDataRequest
 */
export interface TerminalApiRemoveChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveChartData
     */
    readonly id: string
}

/**
 * Request parameters for removeStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveStudyTemplateDataRequest
 */
export interface TerminalApiRemoveStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveStudyTemplateData
     */
    readonly name: string
}

/**
 * Request parameters for removeSymbolFromCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveSymbolFromChartsRequest
 */
export interface TerminalApiRemoveSymbolFromChartsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromCharts
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromCharts
     */
    readonly symbol: string
}

/**
 * Request parameters for removeSymbolFromFavorites operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveSymbolFromFavoritesRequest
 */
export interface TerminalApiRemoveSymbolFromFavoritesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromFavorites
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromFavorites
     */
    readonly symbol: string
}

/**
 * Request parameters for updateFavoriteSymbolsGroup operation in TerminalApi.
 * @export
 * @interface TerminalApiUpdateFavoriteSymbolsGroupRequest
 */
export interface TerminalApiUpdateFavoriteSymbolsGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiUpdateFavoriteSymbolsGroup
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiUpdateFavoriteSymbolsGroup
     */
    readonly group: string
}

/**
 * TerminalApi - object-oriented interface
 * @export
 * @class TerminalApi
 * @extends {BaseAPI}
 */
export class TerminalApi extends BaseAPI {
    /**
     * 
     * @summary Add last indicator
     * @param {TerminalApiAddLastIndicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addLastIndicator(requestParameters: TerminalApiAddLastIndicatorRequest, options?: any) {
        return TerminalApiFp(this.configuration).addLastIndicator(requestParameters.tradingAccountId, requestParameters.terminalIndicator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update chart data
     * @param {TerminalApiAddOrUpdateChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addOrUpdateChartData(requestParameters: TerminalApiAddOrUpdateChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).addOrUpdateChartData(requestParameters.tradingAccountId, requestParameters.terminalChartUpdateData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add study template data
     * @param {TerminalApiAddStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addStudyTemplateData(requestParameters: TerminalApiAddStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).addStudyTemplateData(requestParameters.tradingAccountId, requestParameters.terminalStudyTemplateAddData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add symbol to charts
     * @param {TerminalApiAddSymbolToChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addSymbolToCharts(requestParameters: TerminalApiAddSymbolToChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).addSymbolToCharts(requestParameters.tradingAccountId, requestParameters.symbol, requestParameters.isReplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add symbol to favorites
     * @param {TerminalApiAddSymbolToFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addSymbolToFavorites(requestParameters: TerminalApiAddSymbolToFavoritesRequest, options?: any) {
        return TerminalApiFp(this.configuration).addSymbolToFavorites(requestParameters.tradingAccountId, requestParameters.symbol, requestParameters.isReplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Balance operations history
     * @param {TerminalApiBalanceOperationsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public balanceOperationsHistory(requestParameters: TerminalApiBalanceOperationsHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).balanceOperationsHistory(requestParameters.tradingAccountId, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close orders
     * @param {TerminalApiCloseOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public closeOrders(requestParameters: TerminalApiCloseOrdersRequest, options?: any) {
        return TerminalApiFp(this.configuration).closeOrders(requestParameters.tradingAccountId, requestParameters.terminalCloseOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User server symbols
     * @param {TerminalApiGetAccountSymbolsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getAccountSymbols(requestParameters: TerminalApiGetAccountSymbolsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getAccountSymbols(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chart data
     * @param {TerminalApiGetChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getChartData(requestParameters: TerminalApiGetChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).getChartData(requestParameters.tradingAccountId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all charts meta info
     * @param {TerminalApiGetChartsMetaInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getChartsMetaInfo(requestParameters: TerminalApiGetChartsMetaInfoRequest, options?: any) {
        return TerminalApiFp(this.configuration).getChartsMetaInfo(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last indicator
     * @param {TerminalApiGetLastIndicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getLastIndicator(requestParameters: TerminalApiGetLastIndicatorRequest, options?: any) {
        return TerminalApiFp(this.configuration).getLastIndicator(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get study template data
     * @param {TerminalApiGetStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getStudyTemplateData(requestParameters: TerminalApiGetStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).getStudyTemplateData(requestParameters.tradingAccountId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all study templates meta info
     * @param {TerminalApiGetStudyTemplatesMetaInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getStudyTemplatesMetaInfo(requestParameters: TerminalApiGetStudyTemplatesMetaInfoRequest, options?: any) {
        return TerminalApiFp(this.configuration).getStudyTemplatesMetaInfo(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Symbol charts
     * @param {TerminalApiGetSymbolChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbolCharts(requestParameters: TerminalApiGetSymbolChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbolCharts(requestParameters.tradingServerId, requestParameters.symbol, requestParameters.timeframe, requestParameters.from, requestParameters.to, requestParameters.countBack, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Server symbols
     * @param {TerminalApiGetSymbolsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbols(requestParameters: TerminalApiGetSymbolsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbols(requestParameters.tradingServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Server symbols updates
     * @param {TerminalApiGetSymbolsUpdatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbolsUpdates(requestParameters: TerminalApiGetSymbolsUpdatesRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbolsUpdates(requestParameters.tradingServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create account WS token
     * @param {TerminalApiGetTerminalAccountTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTerminalAccountToken(requestParameters: TerminalApiGetTerminalAccountTokenRequest, options?: any) {
        return TerminalApiFp(this.configuration).getTerminalAccountToken(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral Calendar credentials
     * @param {TerminalApiGetTradingCentralCalendarCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralCalendarCredentials(requestParameters: TerminalApiGetTradingCentralCalendarCredentialsRequest = {}, options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralCalendarCredentials(requestParameters.terminalTradingCentralHandShake, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral News
     * @param {TerminalApiGetTradingCentralNewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralNews(requestParameters: TerminalApiGetTradingCentralNewsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralNews(requestParameters.symbol, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralWidgetCredentials(options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralWidgetCredentials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify order/position
     * @param {TerminalApiModifyOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public modifyOrder(requestParameters: TerminalApiModifyOrderRequest, options?: any) {
        return TerminalApiFp(this.configuration).modifyOrder(requestParameters.tradingAccountId, requestParameters.id, requestParameters.terminalModifyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open order
     * @param {TerminalApiOpenOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public openOrder(requestParameters: TerminalApiOpenOrderRequest, options?: any) {
        return TerminalApiFp(this.configuration).openOrder(requestParameters.tradingAccountId, requestParameters.terminalOpenOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Orders history
     * @param {TerminalApiOrdersHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public ordersHistory(requestParameters: TerminalApiOrdersHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).ordersHistory(requestParameters.tradingAccountId, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Positions history
     * @param {TerminalApiPositionsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public positionsHistory(requestParameters: TerminalApiPositionsHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).positionsHistory(requestParameters.tradingAccountId, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove chart data
     * @param {TerminalApiRemoveChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeChartData(requestParameters: TerminalApiRemoveChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeChartData(requestParameters.tradingAccountId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove study template data
     * @param {TerminalApiRemoveStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeStudyTemplateData(requestParameters: TerminalApiRemoveStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeStudyTemplateData(requestParameters.tradingAccountId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove symbol from charts
     * @param {TerminalApiRemoveSymbolFromChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeSymbolFromCharts(requestParameters: TerminalApiRemoveSymbolFromChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeSymbolFromCharts(requestParameters.tradingAccountId, requestParameters.symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove symbol from favorites
     * @param {TerminalApiRemoveSymbolFromFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeSymbolFromFavorites(requestParameters: TerminalApiRemoveSymbolFromFavoritesRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeSymbolFromFavorites(requestParameters.tradingAccountId, requestParameters.symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update favorite symbols group
     * @param {TerminalApiUpdateFavoriteSymbolsGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public updateFavoriteSymbolsGroup(requestParameters: TerminalApiUpdateFavoriteSymbolsGroupRequest, options?: any) {
        return TerminalApiFp(this.configuration).updateFavoriteSymbolsGroup(requestParameters.tradingAccountId, requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TournamentsApi - axios parameter creator
 * @export
 */
export const TournamentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get trading tournament
         * @param {string} id 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingTournaments: async (id: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTradingTournaments', 'id', id)
            const localVarPath = `/v1.0/tournaments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TournamentsApi - functional programming interface
 * @export
 */
export const TournamentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TournamentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get trading tournament
         * @param {string} id 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingTournaments(id: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingTournament>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingTournaments(id, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TournamentsApi - factory interface
 * @export
 */
export const TournamentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TournamentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get trading tournament
         * @param {string} id 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingTournaments(id: string, skip?: number, take?: number, options?: any): AxiosPromise<TradingTournament> {
            return localVarFp.getTradingTournaments(id, skip, take, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTradingTournaments operation in TournamentsApi.
 * @export
 * @interface TournamentsApiGetTradingTournamentsRequest
 */
export interface TournamentsApiGetTradingTournamentsRequest {
    /**
     * 
     * @type {string}
     * @memberof TournamentsApiGetTradingTournaments
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof TournamentsApiGetTradingTournaments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TournamentsApiGetTradingTournaments
     */
    readonly take?: number
}

/**
 * TournamentsApi - object-oriented interface
 * @export
 * @class TournamentsApi
 * @extends {BaseAPI}
 */
export class TournamentsApi extends BaseAPI {
    /**
     * 
     * @summary Get trading tournament
     * @param {TournamentsApiGetTradingTournamentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public getTradingTournaments(requestParameters: TournamentsApiGetTradingTournamentsRequest, options?: any) {
        return TournamentsApiFp(this.configuration).getTradingTournaments(requestParameters.id, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }
}


