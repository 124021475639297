import { useState } from "react";
import {
  MutationCache,
  type MutationFunction,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  type QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";

import { getPagingRequestParams, type ISkipTake, type RequestParamsWithPaging } from "@/components/pagination";
import { useTranslation } from "@/hooks/translator.hook";
import type { ResponseError } from "@/services/types";
import { toast, ToastIcons } from "@/shared/ui";

import type {
  InfiniteQueryOptionsType,
  MutationOptionsType,
  QueryBaseOptionsType,
  QueryFunctionWithParams,
  RequestInfiniteParams,
  RQMutationMetaType,
  RQQueryMetaType,
} from "./types";

export const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: import.meta.env.MODE === "production",
            retry: false,
            cacheTime: 600000, // 10 min
            // https://tkdodo.eu/blog/react-query-render-optimizations#tracked-queries
            notifyOnChangeProps: "tracked",
          },
        },
        queryCache: new QueryCache({
          onError: (serverError, query) => {
            const error = serverError as ResponseError;
            const meta: RQQueryMetaType | undefined = query.meta;
            if (
              error.errorMessage &&
              meta?.showErrorMessage &&
              error.errorMessage !== "Confirmed email required" &&
              error.status !== 401
            ) {
              toast({ text: error.errorMessage, icon: ToastIcons.ERROR });
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (serverError, _, __, mutation) => {
            const error = serverError as ResponseError;
            const meta: RQMutationMetaType | undefined = mutation.meta;
            if (error.errorMessage && meta?.showErrorMessage) {
              toast({ text: error.errorMessage, icon: ToastIcons.ERROR });
            }
          },
          onSuccess: (_, __, ___, mutation) => {
            const meta: RQMutationMetaType | undefined = mutation.meta;
            if (meta?.successMessage) {
              toast({ text: meta.successMessage, icon: ToastIcons.SUCCESS });
            }
          },
        }),
      }),
  );
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export function useBaseQuery<TQueryFnData = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: QueryBaseOptionsType<TQueryFnData, TData, TQueryKey>,
) {
  const { authSensitive = true, showErrorMessage = true, ...restOptions } = options;
  return useQuery({ ...restOptions, meta: { ...restOptions.meta, showErrorMessage, authSensitive } });
}

export function usePagingQuery<
  TQueryFnData,
  TRequestParams = ISkipTake,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  filters: RequestParamsWithPaging<TRequestParams>,
  options: Omit<QueryBaseOptionsType<TQueryFnData, TData, TQueryKey>, "queryFn"> & {
    queryFn: QueryFunctionWithParams<TQueryFnData, TRequestParams, TQueryKey>;
  },
) {
  const requestParams = getPagingRequestParams<TRequestParams>(filters) as unknown as TRequestParams;
  return useBaseQuery<TQueryFnData, TData, TQueryKey>({
    staleTime: 5000,
    keepPreviousData: true,
    ...options,
    queryFn: ctx => options.queryFn(requestParams, ctx),
  });
}

export function useBaseInfiniteQuery<
  TQueryFnData extends { total?: number },
  TRequestParams = ISkipTake,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunctionWithParams<TQueryFnData, TRequestParams, TQueryKey>,
  filters: RequestInfiniteParams<TRequestParams>,
  options?: InfiniteQueryOptionsType<TQueryFnData, TData, TQueryKey>,
) {
  const { showErrorMessage = true, authSensitive = true, ...libOptions } = options || {};
  return useInfiniteQuery(
    queryKey,
    context => {
      const { pageParam = 1 } = context;
      const requestParams = getPagingRequestParams({
        ...filters,
        page: pageParam,
      }) as unknown as TRequestParams;
      return queryFn(requestParams, context);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const loadedItemsLength = allPages.length * filters.pageSize;
        const total = lastPage?.total || 0;
        if (loadedItemsLength >= total) {
          return undefined;
        }
        return allPages.length + 1;
      },
      ...libOptions,
      meta: {
        ...libOptions.meta,
        showErrorMessage,
        authSensitive,
      },
    },
  );
}

export function useBaseMutation<TData = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: MutationOptionsType<TData, TVariables, TContext>,
) {
  const { t } = useTranslation();
  const { showErrorMessage = true, successMessage, ...libOptions } = options || {};
  return useMutation(mutationFn, {
    ...libOptions,
    meta: {
      ...libOptions.meta,
      showErrorMessage,
      successMessage: successMessage ? t(`success-message.${successMessage}`) : undefined,
    },
  });
}
